import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MoneyTimeIcon = createSvgIcon(
  <>
    <title>money time</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points="14,8 14,14 8,14 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M19,20v4c0,1.657,2.686,3,6,3 s6-1.343,6-3v-4"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M19,24v4c0,1.657,2.686,3,6,3 s6-1.343,6-3v-4"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <ellipse
        cx="25"
        cy="20"
        data-color="color-2"
        fill="none"
        rx="6"
        ry="3"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M15,26.962 C14.67,26.987,14.336,27,14,27C6.82,27,1,21.18,1,14S6.82,1,14,1c6.843,0,12.452,5.288,12.962,12"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
