import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SpaceshipIcon = createSvgIcon(
  <>
    <title>spaceship</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="20.456"
        x2="28.246"
        y1="3.723"
        y2="11.513"
      />{' '}
      <path
        d="M17.3,5.371C12.75,3.723,8,4.708,4,10l4.164,4.174"
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M17.826,23.836,22,28c5.292-4,6.277-8.75,4.629-13.3"
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M14,26,6,18S12.493,2.292,30.656,1.344C29.629,19.429,14,26,14,26Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="18.5"
        cy="13.5"
        data-color="color-2"
        fill="none"
        r="2.5"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M2.879,24.879a3,3,0,0,1,4.242,4.242c-1.171,1.172-5.949,1.707-5.949,1.707S1.707,26.05,2.879,24.879Z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
