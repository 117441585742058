import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const TranslationIcon = createSvgIcon(
  <>
    <title>translation</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M17,8V3a2,2,0,0,0-2-2H3A2,2,0,0,0,1,3V14a2,2,0,0,0,2,2H4v2.667L8,16h3"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
      <path
        d="M15,25V14a2,2,0,0,1,2-2H29a2,2,0,0,1,2,2V25a2,2,0,0,1-2,2H28v2.667L24,27H17A2,2,0,0,1,15,25Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <path
        d="M24.825,24,24.4,22.4H21.62L21.187,24H18.644l2.794-8.6h3.088l2.83,8.6Zm-.9-3.5-.369-1.406q-.129-.469-.314-1.213T23,16.811q-.053.3-.208.984T22.1,20.5Z"
        data-cap="butt"
        data-stroke="none"
        fill="#222222"
        stroke="none"
      />
      <path
        d="M13.685,6.453c-.44-.04-.83-.06-1.419-.06a7.85,7.85,0,0,1-.68,1.929,7.4,7.4,0,0,1-1.109,1.619,9.6,9.6,0,0,0,3.428,1.209,6.426,6.426,0,0,0-.99,1.839A12.35,12.35,0,0,1,9.107,11.17a12.776,12.776,0,0,1-3.978,1.839A5.793,5.793,0,0,0,4.17,11.24,10.869,10.869,0,0,0,7.718,9.931,8.7,8.7,0,0,1,6.429,8.1a8.77,8.77,0,0,1-.69-1.709c-.59,0-.929.02-1.429.06V4.644a12.321,12.321,0,0,0,1.449.07H7.968v-.6a3.017,3.017,0,0,0-.06-.619H9.917a3.068,3.068,0,0,0-.06.609v.61h2.378a12.226,12.226,0,0,0,1.45-.07Zm-6.027-.06a5.7,5.7,0,0,0,1.4,2.419,5.645,5.645,0,0,0,1.25-2.419Z"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
      />
    </g>
  </>
)
