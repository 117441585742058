import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PrivacyIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        data-color="color-2"
        fill="none"
        height="7"
        strokeMiterlimit="10"
        width="10"
        x="11"
        y="16"
      />
      <path
        d="M13,16V13a3,3,0,0,1,3-3h0a3,3,0,0,1,3,3v3"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
      <path
        d="M28,19A12,12,0,0,1,4,19V5L16,2,28,5Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
