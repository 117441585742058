import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductPowerIcon = createSvgIcon(
  <>
    <title>product power</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="power"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <path
          d="M21.922 2.48L8.223 15a.2.2 0 0 0 .052.33l4.722 2.16a.2.2 0 0 1 .109.24L9.654 29.32a.2.2 0 0 0 .325.206l13.801-12.37a.2.2 0 0 0-.044-.327l-4.742-2.357a.2.2 0 0 1-.103-.235l3.358-11.554a.2.2 0 0 0-.327-.204z"
          id="Path"
          stroke="#222222"
        />
      </g>
    </g>
  </>
)
