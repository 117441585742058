import { all, put } from 'redux-saga/effects'
import { call, takeLatest } from 'typed-redux-saga'

import {
  PortalAPIName,
  PortalMeterReadingAPIName
} from '../../common/constants'
import { amplifyGet, amplifyPost } from '../../common/utils'
import { TypedAction } from '../types'

import { getMetersHistory } from './actions'
import { ShoppingActionTypes } from './types'

function* loadOrdersFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const orders = yield* call(amplifyGet, {
      path: `/customers/${action.payload.customerId}/opportunities`,
      apiName: PortalAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_ORDERS_SUCCESS,
      payload: orders
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_ORDERS_FAILURE,
      payload: error.message
    })
  }
}

function* loadRecentOrdersFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const recentOrders = yield* call(amplifyGet, {
      path: `/customers/${action.payload.customerId}/opportunities`,
      apiName: PortalAPIName,
      params: {
        limit: 2
      }
    })

    yield put({
      type: ShoppingActionTypes.LOAD_RECENT_ORDERS_SUCCESS,
      payload: recentOrders
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_RECENT_ORDERS_FAILURE,
      payload: error.message
    })
  }
}

function* loadOrderDetailFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    yield put({
      type: ShoppingActionTypes.LOAD_ORDER_DETAIL_SUCCESS,
      payload: action.payload
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_ORDER_DETAIL_FAILURE,
      payload: error.message
    })
  }
}

function* loadOrderIdSelectionFlow(
  action: TypedAction<Record<string, unknown>>
) {
  try {
    yield put({
      type: ShoppingActionTypes.UPDATE_CURRENT_ID_SUCCESS,
      payload: action.payload
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.UPDATE_CURRENT_ID_FAILURE,
      payload: error.message
    })
  }
}

function* loadPricingDetailFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const statuses = yield* call(amplifyGet, {
      path: `/opportunities/${action.payload}/prices`,
      apiName: PortalAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_PRICING_DETAIL_SUCCESS,
      payload: statuses
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_PRICING_DETAIL_FAILURE,
      payload: error.message
    })
  }
}

function* loadStatusFlow(action: TypedAction<number>) {
  try {
    const statuses = yield* call(amplifyGet, {
      path: `/opportunities/${action.payload}/statuses`,
      apiName: PortalAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_STATUS_SUCCESS,
      payload: statuses
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_STATUS_FAILURE,
      payload: error.message
    })
  }
}

function* loadMetersFlow(action: TypedAction<number>) {
  try {
    const metersTmp = yield* call(amplifyGet, {
      path: `/customers/${action.payload}/meters`,
      apiName: PortalMeterReadingAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_METERS_SUCCESS,
      payload: metersTmp
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_METERS_FAILURE,
      payload: error.message
    })
  }
}

function* loadMetersHistoryFlow(action: TypedAction<number>) {
  try {
    const meterHistories = yield* call(amplifyGet, {
      path: `/meters/${action.payload}/meter-readings`,
      apiName: PortalMeterReadingAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_METER_HISTORY_SUCCESS,
      payload: {
        [action.payload]: meterHistories
      }
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_METER_HISTORY_FAILURE,
      payload: error.message
    })
  }
}

function* transmitMetersReadingFlow(
  action: TypedAction<Record<string, unknown>>
) {
  try {
    const transmitMetersReading = yield* call(amplifyPost, {
      path: `/meters/${action.payload.meterId}/meter-readings`,
      apiName: PortalMeterReadingAPIName,
      params: {
        value: action.payload.value,
        unit_id: action.payload.unit_id,
        author: action.payload.author
      }
    })

    yield put({
      type: ShoppingActionTypes.TRANSMIT_METERS_READING_SUCCESS,
      payload: transmitMetersReading
    })
    yield put(getMetersHistory(action.payload.meterId as number))
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.TRANSMIT_METERS_READING_FAILURE,
      payload: error.error.message
    })
  }
}

function* loadAddressFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const orderAddresses = yield call(amplifyGet, {
      path: `/opportunities/${action.payload}/addresses`,
      apiName: PortalAPIName
    })

    yield put({
      type: ShoppingActionTypes.LOAD_ORDER_ADDRESSES_SUCCESS,
      payload: orderAddresses
    })
  } catch (error) {
    yield put({
      type: ShoppingActionTypes.LOAD_ORDER_ADDRESSES_FAILURE,
      payload: error.message
    })
  }
}

function* shoppingSaga() {
  yield all([
    takeLatest(ShoppingActionTypes.LOAD_ORDERS_REQUEST, loadOrdersFlow),
    takeLatest(
      ShoppingActionTypes.LOAD_RECENT_ORDERS_REQUEST,
      loadRecentOrdersFlow
    ),
    takeLatest(
      ShoppingActionTypes.LOAD_ORDER_DETAIL_REQUEST,
      loadOrderDetailFlow
    ),
    takeLatest(
      ShoppingActionTypes.UPDATE_CURRENT_ID_REQUEST,
      loadOrderIdSelectionFlow
    ),
    takeLatest(
      ShoppingActionTypes.LOAD_PRICING_DETAIL_REQUEST,
      loadPricingDetailFlow
    ),

    takeLatest(ShoppingActionTypes.LOAD_STATUS_REQUEST, loadStatusFlow),
    takeLatest(ShoppingActionTypes.LOAD_METERS_REQUEST, loadMetersFlow),
    takeLatest(
      ShoppingActionTypes.LOAD_METER_HISTORY_REQUEST,
      loadMetersHistoryFlow
    ),
    takeLatest(
      ShoppingActionTypes.TRANSMIT_METERS_READING_REQUEST,
      transmitMetersReadingFlow
    ),
    takeLatest(
      ShoppingActionTypes.LOAD_ORDER_ADDRESSES_REQUEST,
      loadAddressFlow
    )
  ])
}

export default shoppingSaga
