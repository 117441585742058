import MuiFormControl from '@material-ui/core/FormControl'
import MuiHidden from '@material-ui/core/Hidden'
import MuiIconButton from '@material-ui/core/IconButton'
import { InputProps as MuiInputProps } from '@material-ui/core/Input'
import MuiInputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import MuiTextField, {
  BaseTextFieldProps as MuiBaseTextFieldProps
} from '@material-ui/core/TextField'
import React, { useState } from 'react'

import { PenIcon, PreviewHideIcon, PreviewIcon } from '../icons'

import { InlineEditButtons } from './inline-edit-buttons'
//we use any for the variant because there is an issue with the Textfield type declaration, see: https://stackoverflow.com/questions/55664421/how-do-i-pass-in-the-variant-property-of-the-material-ui-textfield-from-a-wrappi?rq=1
export interface InputProps extends MuiBaseTextFieldProps {
  className?: string
  children?: React.ReactElement
  leftAdornment?: React.ReactElement
  rightAdornment?: React.ReactElement
  error?: boolean
  errors?: string[]
  description?: string
  variant?: 'standard' | 'filled'
  onChange?: (value: unknown) => void
  fullWidth?: boolean
  color?: 'primary' | 'secondary'
  InputProps?: Record<string, unknown>
  isLabelInside?: boolean
  inlineEdit?: boolean
  onCancelEditClick?: () => void
  onEditClick?: React.MouseEventHandler
  type?:
    | 'text'
    | 'numeric'
    | 'decimal'
    | 'email'
    | 'password'
    | 'tel'
    | 'search'
  id: string
  showPasswordIcon?: boolean
  showNormalLabelWeight?: boolean
  onBlur?: (event: unknown) => void
}
interface InnerInputProps extends MuiInputProps {
  leftAdornment?: React.ReactElement
  rightAdornment?: React.ReactElement
}

/**
 * Use `Input` to handle user input
 *
 * There are different inputs that can be used by passing the `variant`=(outlined|filled|standard) and `color`=(primary|secondary) props
 *
 * Inputs can have icons inside, you can add them by passing
 * `leftAdornment`=(icon) or `rightAdornment`=(icon) to the input component as prop
 *
 * If the `error` prop is set to `true` the `helperText` prop text will show up under the input
 */
export const Input = (props: InputProps): React.ReactElement => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [initialValue, setInitialValue] = useState('')
  const [isEmpty, setIsEmpty] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const {
    id,
    children,
    leftAdornment,
    rightAdornment,
    error,
    errors,
    className,
    hidden,
    variant,
    color,
    description,
    fullWidth,
    InputProps,
    isLabelInside,
    inlineEdit,
    onCancelEditClick,
    onEditClick,
    type,
    onChange,
    showPasswordIcon,
    showNormalLabelWeight,
    onBlur,
    ...restProps
  } = props
  const htmlId = id ? id.replace(/^#\/properties\//, '') : 'input-control-no-id'

  const inputMode = type === 'password' ? 'text' : type
  const inputType = type === 'numeric' || type === 'decimal' ? 'number' : type

  const innerInputProps: InnerInputProps = {}
  const useStyles = makeStyles({
    form: {
      width: 'initial'
    },
    inlineEditInput: {
      border: '1px solid #FFFFFF',
      padding: '4px 0',
      cursor: 'pointer !important',
      '& #edit-button': {
        display: 'none'
      },
      '&:hover': {
        border: '1px solid #EFF2F3',
        backgroundColor: '#EFF2F3',
        padding: '4px 16px 4px 2px',
        '& #edit-button': {
          display: 'initial',
          position: 'absolute',
          right: '4px',
          top: '4px'
        }
      },
      '&:focus-within, &:active': {
        border: '1px solid #039BE5',
        padding: '4px 16px',
        backgroundColor: '#ffffff',
        '& #edit-button': {
          display: 'none !important'
        }
      }
    },
    label: {
      fontWeight: 'normal',
      transform: 'translate(36px, 24px) scale(1.2)',
      width: '60%'
    },
    normalLabelWeight: {
      fontWeight: 'normal'
    },
    startIcon: {
      marginTop: '10px !important',
      transition:
        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    }
  })
  const classes = useStyles()
  let isValid = true

  const firstFormHelperText = isValid ? description : !isValid ? errors : null

  if (errors != null) {
    isValid = errors.length === 0
  }
  if (leftAdornment != null) {
    innerInputProps['startAdornment'] = (
      <MuiInputAdornment
        className={
          (isInputFocused && isLabelInside) || (!isEmpty && isLabelInside)
            ? classes.startIcon
            : ''
        }
        position="start"
      >
        {leftAdornment}
      </MuiInputAdornment>
    )
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  if (rightAdornment != null && !showPasswordIcon) {
    innerInputProps['endAdornment'] = (
      <MuiInputAdornment position="end">{rightAdornment}</MuiInputAdornment>
    )
  }

  if (showPasswordIcon && type === 'password') {
    innerInputProps['endAdornment'] = (
      <MuiInputAdornment position="end">
        <MuiIconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? (
            <PreviewIcon size="small" />
          ) : (
            <PreviewHideIcon size="small" />
          )}
        </MuiIconButton>
      </MuiInputAdornment>
    )
  }
  if (inlineEdit) {
    innerInputProps['endAdornment'] = (
      <MuiInputAdornment position="end">
        <PenIcon id="edit-button" size="smallest" />
      </MuiInputAdornment>
    )
  }
  const formClass = inlineEdit ? classes.inlineEditInput : ''

  let labelProps = {}

  if (showNormalLabelWeight) {
    labelProps = {
      className: classes.normalLabelWeight
    }
  }

  if (!isInputFocused && leftAdornment != null && isEmpty && isLabelInside) {
    labelProps = {
      shrink: false,
      className: classes.label
    }
  }

  return (
    <MuiHidden xsUp={hidden}>
      <MuiFormControl className={fullWidth ? '' : classes.form}>
        <MuiTextField
          {...restProps}
          FormHelperTextProps={{ error: error }}
          InputLabelProps={{
            htmlFor: `${htmlId}-input`,
            disabled: !isLabelInside,
            disableAnimation: !isLabelInside,
            ...labelProps
          }}
          InputProps={{
            id: `${htmlId}-input`,
            name: htmlId,
            inputMode: inputMode,
            type: showPassword ? 'text' : inputType,
            ...InputProps,
            ...innerInputProps,
            className: formClass
          }}
          className={className}
          color={color}
          error={error}
          helperText={firstFormHelperText}
          onBlur={(e) => {
            setIsInputFocused(false)
            setIsEmpty(e.target.value === '')
            if (onBlur) {
              onBlur(e)
            }
          }}
          onChange={onChange}
          onFocus={(e) => {
            setInitialValue(e.target.value)
            setIsInputFocused(true)
          }}
          variant={isLabelInside ? 'filled' : variant}
        >
          {children}
        </MuiTextField>
        {inlineEdit && isInputFocused ? (
          <InlineEditButtons
            onCancelEditClick={() => {
              if (onChange) onChange(initialValue)
              if (onCancelEditClick) onCancelEditClick()
            }}
            onEditClick={onEditClick}
          />
        ) : null}
      </MuiFormControl>
    </MuiHidden>
  )
}
