import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const TriangleUpIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <path
        d="M16.8,6.4c-0.4-0.5-1.2-0.5-1.6,0l-13,18c-0.2,0.3-0.3,0.7-0.1,1C2.3,25.8,2.6,26,3,26h26c0,0,0,0,0,0 c0.6,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7L16.8,6.4z"
        fill="#222222"
      />
    </g>
  </>
)
