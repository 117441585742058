import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductGenericIcon = createSvgIcon(
  <>
    <title>product generic</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="generic"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g
          id="Group"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(2 2)"
        >
          <path
            d="M4.872 7.878C1.851 7.768.236 8.975.027 11.494c-.314 3.78 2.226 4.167 3.23 4.167 1.003 0 17.685.212 19.602 0 1.917-.211 5.536-2.483 4.004-6.771-1.022-2.86-3.28-3.748-6.776-2.667C18.1 1.677 15.15-.379 11.24.057 7.328.493 5.206 3.1 4.872 7.877z"
            id="Path-63"
          />
          <path
            d="M14.147 17.78l-3.528 4.053a.2.2 0 0 0 .05.304l1.164.67a.2.2 0 0 1 .095.22l-.863 3.641a.2.2 0 0 0 .345.18l3.559-4.01a.2.2 0 0 0-.044-.303l-1.182-.739a.2.2 0 0 1-.089-.214l.838-3.626a.2.2 0 0 0-.345-.177z"
            id="Path-8"
          />
        </g>
      </g>
    </g>
  </>
)
