import { Box as MuiBox, Card } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import * as _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import emptyOrder from '../assets/images/empty-order.svg'
import { useLoading } from '../common/hooks/custom.hook'
import { Orders, LoadingComponent } from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { getOrders } from '../store/shopping/actions'
import { filterOrderByTypeSelector } from '../store/shopping/selectors'
import { Order as OrderTypes } from '../store/shopping/types'
import { BodyText, SmallText } from '../tmp-ui-element/typography'

type OrdersProps = {
  loadingOrders: boolean
  currEmail: string
  orders: {
    processing: OrderTypes[]
    completed: OrderTypes[]
    canceled: OrderTypes[]
  }
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    }
  })
)

const OrdersPage = (props: OrdersProps): React.ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { orders, selectedStyles } = props
  const { organizationId, customerId } = useSelector(
    (state: ApplicationState) => state.profile.profile
  )

  const isLoading = useLoading()

  useEffect(() => {
    if (organizationId && customerId) {
      dispatch(getOrders(Number(organizationId), customerId as number))
    }
  }, [organizationId, customerId])

  return (
    <>
      <GlobalCss />
      <MainLayout isLoading={isLoading}>
        <MuiBox className={classes.main}>
          {/* TITLE */}
          {!isLoading && (
            <MuiBox style={{ fontSize: '24px', lineHeight: '32px' }}>
              {t('Orders')}
            </MuiBox>
          )}
          {/*SHOW LIST ORDER*/}
          {isLoading ? (
            <MuiBox pt={4}>
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <>
              {(!_.isEmpty(orders.processing) || !_.isEmpty(orders.completed) || !_.isEmpty(orders.canceled)) ? (
                <>
                  {/*PROCESSING*/}
                  {!_.isEmpty(orders.processing) && (
                    <Orders orders={orders.processing} title={t('Processing')} />
                  )}

                  {/* COMPLETED*/}
                  {!_.isEmpty(orders.completed) && (
                    <Orders orders={orders.completed} title={t('Completed')} />
                  )}

                  {/* CANCELED*/}
                  {!_.isEmpty(orders.canceled) && (
                    <Orders orders={orders.canceled} title={t('Canceled')} />
                  )}
                </>
              ) : (
                <MuiBox pt={4}>
                  <Card variant="outlined">
                    <MuiBox p={3}>
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <img alt="No results found" src={emptyOrder} />
                      </MuiBox>
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        pt={3}
                      >
                        <BodyText>{t('You have no orders')}</BodyText>
                      </MuiBox>
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        pt={1}
                      >
                        <SmallText
                          style={{
                            color: '#849096',
                            textAlign: 'center'
                          }}
                        >
                          {t(
                            'The data will be displayed when it is available'
                          )}
                        </SmallText>
                      </MuiBox>
                    </MuiBox>
                  </Card>
                </MuiBox>
              )}
            </>
          )}
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    orders: filterOrderByTypeSelector(state),
    currProfile: state.profile.profile,
    loadingOrders: state.shopping.loadingOrders,
    currEmail: state.app.currEmail,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(OrdersPage)
