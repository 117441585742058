import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

import errorDesktop from '../assets/images/error-desktop.svg'
import errorMobile from '../assets/images/error.svg'
import { SelectedStyles } from '../store/app/types'
import { ThemeProvider, BodyText, H1 } from '../tmp-ui-element'

type BrandErrorProps = {
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222222',
      padding: '16px 0'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400
    },
    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

const ErrorPage = (props: BrandErrorProps): React.ReactElement => {
  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          <MuiBox
            style={{ textAlign: 'center' }}
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexDirection="column"
          >
            <H1 style={{ fontSize: '40px', lineHeight: '48px' }}>
              That's an error
            </H1>
            <BodyText
              style={{
                paddingTop: '24px',
                fontSize: '16px',
                lineHeight: '24px'
              }}
            >
              We can't find the URL you requested. Please check it again
            </BodyText>
            <img
              id="error-img"
              src={window.innerWidth <= 780 ? errorMobile : errorDesktop}
              alt={'error-img'}
            />
          </MuiBox>
        </ThemeProvider>
      </div>
    </>
  )
}

export default ErrorPage
