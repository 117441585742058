import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CheckIcon, DogIcon } from '../tmp-ui-element'

const TextRequiredWrapper = styled('div')`
  text-align: left;
`

const TextItemRequired = styled('div')`
  display: flex;
  align-items: center;
  padding: 4px 0;
`

type PasswordRequirementProps = {
  isMinimum8Characters: boolean
  isOneUpperCaseCharacter: boolean
  isOneNumber: boolean
}

const PasswordRequirement = ({
  isMinimum8Characters,
  isOneUpperCaseCharacter,
  isOneNumber
}: PasswordRequirementProps) => {
  const { t } = useTranslation()

  return (
    <TextRequiredWrapper>
      <p
        style={{
          margin: 0,
          paddingTop: '16px',
          paddingBottom: '8px'
        }}
      >
        {t('Password must contain')}
      </p>
      <div>
        <TextItemRequired>
          {isMinimum8Characters ? (
            <CheckIcon color="#039BE5" size="smallest" />
          ) : (
            <DogIcon color="#FF9800" size="smallest" />
          )}
          <span style={{ paddingLeft: '8px' }}>
            {t('Minimum 8 characters')}
          </span>
        </TextItemRequired>
        <TextItemRequired>
          {isOneUpperCaseCharacter ? (
            <CheckIcon color="#039BE5" size="smallest" />
          ) : (
            <DogIcon color="#FF9800" size="smallest" />
          )}
          <span style={{ paddingLeft: '8px' }}>
            {t('One uppercase character')}
          </span>
        </TextItemRequired>
        <TextItemRequired>
          {isOneNumber ? (
            <CheckIcon color="#039BE5" size="smallest" />
          ) : (
            <DogIcon color="#FF9800" size="smallest" />
          )}
          <span style={{ paddingLeft: '8px' }}>{t('One number')}</span>
        </TextItemRequired>
      </div>
    </TextRequiredWrapper>
  )
}

export default PasswordRequirement
