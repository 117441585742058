import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHouseBroadbandIcon = createSvgIcon(
  <>
    <title>product house broadband</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="house-broadband"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group-6" stroke="#222222" transform="translate(1 2.276)">
          <path
            d="M14.318.637L1.402 12.69A.756.756 0 0 0 1.918 14h1.719c.11 0 .2.09.2.2V26a2 2 0 0 0 2 2h18.316a2 2 0 0 0 2-2V14.2c0-.11.09-.2.2-.2h1.723a.758.758 0 0 0 .518-1.313L15.682.637a1 1 0 0 0-1.364 0z"
            id="Shape"
            strokeLinecap="square"
          />
          <circle cx="15" cy="21.724" id="Oval" r="2" strokeLinecap="square" />
          <path
            d="M19 17.224c-1.026-.937-2.41-1.5-4-1.5-1.538 0-2.974.563-4 1.5"
            id="Shape"
            strokeLinecap="round"
          />
          <path
            d="M23 14.724c-2.059-1.821-4.882-3-8-3-3.118 0-5.941 1.179-8 3"
            id="Shape"
            strokeLinecap="round"
          />
        </g>
      </g>
    </g>
  </>
)
