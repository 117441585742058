import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { all, fork } from 'redux-saga/effects'

import { appReducer } from './app/reducer'
import appSaga from './app/saga'
import { AppState } from './app/types'
import { profileReducer } from './profile/reducer'
import profileSaga from './profile/saga'
import { ProfileState } from './profile/types'
import { shoppingReducer } from './shopping/reducer'
import shoppingSaga from './shopping/saga'
import { ShoppingState } from './shopping/types'

export interface ApplicationState {
  app: AppState
  profile: ProfileState
  shopping: ShoppingState
}

const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: [
    'currEmail',
    'isAlreadyCheckedUserLoggedIn',
    'isAlreadyLoadedSelectedStyles'
  ]
}

const profilePersistConfig = {
  key: 'profile',
  storage: storage,
  whitelist: ['profile', 'address']
}

const shoppingPersistConfig = {
  key: 'shopping',
  storage: storage,
  whitelist: ['currentSelectedOrderId', 'orderDetailItem', 'pricingDetailItem']
}

export const createRootReducer = () =>
  combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    profile: persistReducer(profilePersistConfig, profileReducer),
    shopping: persistReducer(shoppingPersistConfig, shoppingReducer)
  })

export function* rootSaga() {
  yield all([fork(appSaga), fork(profileSaga), fork(shoppingSaga)])
}
