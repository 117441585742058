import { makeStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import React, { MouseEventHandler, ReactElement } from 'react'
import * as Yup from 'yup'

import {
  Button,
  CircleCheckIcon,
  CircleRemoveIcon,
  MailIcon,
  Input,
  BodyText,
  H2
} from '../tmp-ui-element'

import { Container } from './container'

export interface ResetPasswordProps {
  value?: string
  isSuccess?: boolean
  isFailure?: boolean
  onNavigateBack: MouseEventHandler
  onBackToSendReset: MouseEventHandler
  onSuccess: () => void
  onFailure: () => void
}

export const ResetPassword = (props: ResetPasswordProps): ReactElement => {
  const {
    isSuccess,
    isFailure,
    onNavigateBack,
    onBackToSendReset,
    onSuccess
    // onFailure,
  } = props
  const useStyles = makeStyles({
    button: {
      marginTop: '16px',
      letterSpacing: 0,
      fontWeight: 600
    },
    textContainer: {
      marginTop: '12px'
    },
    itemContainer: {
      position: 'relative'
    },
    backIcon: {
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0'
    },
    pointer: {
      cursor: 'pointer'
    }
  })
  const classes = useStyles(props)

  if (isSuccess) {
    return (
      <Container hideLogo>
        <div>
          <CircleCheckIcon
            color="#039BE5"
            style={{ height: '50px', width: '50px' }}
          />
          <div className={classes.textContainer}>
            <H2 className={classes.textContainer} gutterBottom>
              Reset link sent successfully!
            </H2>
            <BodyText className={classes.textContainer} gutterBottom>
              We&apos;ve sent instructions to reset your password to
              minhaxon+vendor@gmail.com.
            </BodyText>
            <BodyText className={classes.textContainer} gutterBottom>
              You&apos;ll receive this email in the next couple of minutes. Make
              sure to check your spam folder, as well.
            </BodyText>
            <BodyText
              className={`${classes.textContainer} ${classes.pointer}`}
              customColor="#039BE5"
              fontWeight={700}
              gutterBottom
              onClick={onNavigateBack}
            >
              Back to sign in
            </BodyText>
          </div>
        </div>
      </Container>
    )
  }
  if (isFailure) {
    return (
      <Container hideLogo>
        <div>
          <CircleRemoveIcon
            color="#F44336"
            style={{ height: '50px', width: '50px' }}
          />
          <div className={classes.textContainer}>
            <H2 className={classes.textContainer} gutterBottom>
              Something went wrong!
            </H2>
            <BodyText className={classes.textContainer} gutterBottom>
              We were unable to send an email to minhaxon+vendor@gmail.com.
            </BodyText>
            <BodyText className={classes.textContainer} gutterBottom>
              If this error remains please contact support@epilot.cloud
            </BodyText>
            <BodyText
              className={`${classes.textContainer} ${classes.pointer}`}
              customColor="#039BE5"
              fontWeight={700}
              gutterBottom
              onClick={onBackToSendReset}
            >
              Back to send reset link
            </BodyText>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container
      headerText="Reset password"
      onNavigateBack={onNavigateBack}
      showBackIcon
    >
      <div>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async () => {
            await new Promise((resolve) => setTimeout(resolve, 500))
            onSuccess()
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('Required')
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit
            } = props

            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.textContainer}>
                  <BodyText gutterBottom>
                    Enter your email address to get reset link.
                  </BodyText>
                </div>
                <Input
                  description={touched.email ? errors.email : ''}
                  error={errors.email != null && touched.email}
                  fullWidth
                  helperText={touched.email ? errors.email : ''}
                  id="email"
                  isLabelInside
                  label="Email"
                  leftAdornment={<MailIcon size="smallest" />}
                  onChange={handleChange}
                  value={values.email}
                />
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={isSubmitting || values.email === ''}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <>Send reset link</>
                </Button>
              </form>
            )
          }}
        </Formik>
      </div>
    </Container>
  )
}
