import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ServerIcon = createSvgIcon(
  <>
    <title>server</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        data-cap="butt"
        fill="none"
        height="28"
        stroke="#222222"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="2"
      />
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="2"
        y2="30"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="12"
        y1="7"
        y2="7"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="26"
        y1="7"
        y2="7"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="12"
        y1="12"
        y2="12"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="26"
        y1="12"
        y2="12"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="12"
        y1="17"
        y2="17"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="26"
        y1="17"
        y2="17"
      />
    </g>
  </>
)
