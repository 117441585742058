import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const RestoreIcon = createSvgIcon(
  <>
    <title>restore</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M2,16a13.993,13.993,0,1,1,4.094,9.906"
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points="16 7 16 16 25 16"
        strokeMiterlimit="10"
      />{' '}
      <polygon
        fill="none"
        points="2.969 29.031 9.229 22.771 1.151 20.95 2.969 29.031"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
