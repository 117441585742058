import { makeStyles } from '@material-ui/core'
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip'
import React from 'react'

import { RemoveIcon } from '../icons'

const useStyle = makeStyles({
  labelWithDot: (props: { dotLabel?: boolean }) =>
    props.dotLabel
      ? {
          '& .MuiChip-label': {
            '&::before': {
              content: "'\\2022'",
              fontSize: '20px',
              lineHeight: '16px',
              color: 'inherit',
              display: 'inline-block',
              marginRight: '4px',
              verticalAlign: 'middle'
            }
          }
        }
      : {}
})

export interface ChipProps extends Omit<MuiChipProps, 'color'> {
  type?: 'primary' | 'default' | 'info' | 'warning' | 'error' | 'success'
  dotLabel?: boolean
}

export const Chip = (props: ChipProps): React.ReactElement => {
  const {
    type = 'default',
    className,
    deleteIcon = <RemoveIcon size={'smallest'} />,
    dotLabel,
    ...restProps
  } = props

  const classes = useStyle({ dotLabel })

  return (
    <MuiChip
      {...restProps}
      className={`MuiChip-${type} ${className ? className : ''} ${
        classes.labelWithDot
      }`}
      clickable={false}
      deleteIcon={deleteIcon}
    />
  )
}
