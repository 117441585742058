import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SortIcon = createSvgIcon(
  <>
    <title>sort</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="_Icon-/-Others-/-sort-/-Black"
        stroke="none"
        strokeWidth="1"
      >
        <g fill="#222222" id="sort-up" transform="translate(9 6)">
          <path d="M14 11.667H0L7 20z" id="Path" />
          <path d="M14 8.333H0L7 0z" id="Path" />
        </g>
      </g>
    </g>
  </>
)
