import { Box as MuiBox, Card } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import chart from '../assets/images/chart.svg'
import emptyOrder from '../assets/images/empty-order.svg'
import invoice from '../assets/images/invoice.svg'
import message from '../assets/images/message.svg'
import { useLoading } from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { Orders, LoadingComponent } from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { Profile } from '../store/profile/types'
import { getRecentOrders } from '../store/shopping/actions'
import { OrderDetailItem } from '../store/shopping/types'
import { BodyText, SmallText } from '../tmp-ui-element/typography'

type DashboardProps = {
  currEmail: string
  currProfile: Profile
  recentOrders: OrderDetailItem[]
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    comingSoon: {
      overflowX: 'auto',
      msOverflowX: 'auto',
      minWidth: '100%' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        minWidth: 'calc(100vw - 16px)',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }
    },
    comingSoonItem: {
      flexGrow: 1,
      minWidth: '220px',
      marginRight: '8px',
      width: 'fit-content'
    },
    comingSoonEndItem: {
      flexGrow: 1,
      minWidth: '220px',
      marginRight: '0px',
      width: 'fit-content' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        marginRight: '8px'
      }
    },
    comingSoonOnMobile: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex',
        minWidth: '8px',
        maxWidth: '8px',
        width: 'fit-content'
      }
    },
    size: {
      width: '80px',
      height: '80px'
    },
    rightIcon: {
      position: 'absolute',
      right: '10px'
    }
  })
)

const DashboardPage = (props: DashboardProps): React.ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { organizationId, customerId } = useSelector(
    (state: ApplicationState) => state.profile.profile
  )
  const { recentOrders, currProfile, selectedStyles } = props
  const isLoading = useLoading()
  const classes = useStyles()
  const { goToOrdersPage } = useNavigation()

  useEffect(() => {
    if (organizationId && customerId) {
      dispatch(getRecentOrders(Number(organizationId), customerId as number))
    }
  }, [organizationId, customerId])

  return (
    <>
      <GlobalCss />
      <MainLayout isLoading={isLoading}>
        <MuiBox className={classes.main}>
          {isLoading ? (
            <MuiBox pt={4}>
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <>
              <MuiBox
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 400
                }}
              >
                {t('Hello')} {currProfile && currProfile?.firstName}!
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                pt={3}
              >
                <MuiBox
                  style={{
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px'
                  }}
                  textAlign="left"
                  width="70%"
                >
                  {t('Recent orders')}
                </MuiBox>
                {recentOrders && recentOrders.length > 0 && (
                  <MuiBox
                    onClick={goToOrdersPage}
                    style={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#039BE5',
                      cursor: 'pointer'
                    }}
                    textAlign="right"
                    width="30%"
                  >
                    {t('View all')}
                  </MuiBox>
                )}
              </MuiBox>

              {/*RECENT ORDERS */}
              {recentOrders && recentOrders.length > 0 ? (
                <Orders orders={recentOrders} />
              ) : (
                <Card variant="outlined">
                  <MuiBox p={3}>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <img alt="No results found" src={emptyOrder} />
                    </MuiBox>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      pt={3}
                    >
                      <BodyText>{t('You have no orders')}</BodyText>
                    </MuiBox>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      pt={1}
                    >
                      <SmallText
                        style={{
                          color: '#849096',
                          textAlign: 'center'
                        }}
                      >
                        {t(
                          'The data will be displayed when it is available'
                        )}
                      </SmallText>
                    </MuiBox>
                  </MuiBox>
                </Card>
              )}

              {/* COMING SOON */}
              <MuiBox alignItems="center" display="flex" pb={2} pt={3}>
                <MuiBox
                  style={{
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px'
                  }}
                  textAlign="left"
                  width="70%"
                >
                  {t('Coming soon')}
                </MuiBox>
              </MuiBox>
              <MuiBox
                className={classes.comingSoon}
                display="flex"
                flexDirection="flex-wrap"
              >
                <Card className={classes.comingSoonItem} variant="outlined">
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    height="220px"
                    justifyContent="center"
                  >
                    <img alt="No results found" src={invoice} />
                    <MuiBox justifyContent="center">
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#849096'
                        }}
                      >
                        {t('Payment & Invoices')}
                      </span>
                    </MuiBox>
                  </MuiBox>
                </Card>
                <Card className={classes.comingSoonItem} variant="outlined">
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    height="220px"
                    justifyContent="center"
                  >
                    <img alt="No results found" src={message} />
                    <MuiBox justifyContent="center">
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#849096'
                        }}
                      >
                        {t('Messages')}
                      </span>
                    </MuiBox>
                  </MuiBox>
                </Card>
                <Card className={classes.comingSoonEndItem} variant="outlined">
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    height="220px"
                    justifyContent="center"
                  >
                    <img alt="No results found" src={chart} />
                    <MuiBox justifyContent="center">
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#849096'
                        }}
                      >
                        {t('Meter reading')}
                      </span>
                    </MuiBox>
                  </MuiBox>
                </Card>
                <MuiBox className={classes.comingSoonOnMobile} />
              </MuiBox>
            </>
          )}
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    recentOrders: state.shopping.recentOrders,
    currEmail: state.app.currEmail,
    selectedStyles: state.app.selectedStyles
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
