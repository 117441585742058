import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const StarIcon = createSvgIcon(
  <>
    <title>star</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="16 2.685 20.326 11.451 30 12.857 23 19.68 24.652 29.315 16 24.766 7.348 29.315 9 19.68 2 12.857 11.674 11.451 16 2.685"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
