import MMenuItem, {
  MenuItemProps as MuiMenuItemProps
} from '@material-ui/core/MenuItem'
import React from 'react'

export interface MenuItemProps extends Omit<MuiMenuItemProps, 'button'> {
  children?: React.ReactNode
  value?: string
  disabled?: boolean
}

export const MenuItem = (props: MenuItemProps): React.ReactElement => {
  const { children } = props

  return <MMenuItem {...props}>{children}</MMenuItem>
}
