import React, { memo, useRef, useLayoutEffect } from 'react'

import { usePrevious } from '../../common/hooks/custom.hook'

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
  hasErrored?: boolean
}

export function SingleOTPInputComponent(props: SingleOTPInputProps) {
  const { focus, autoFocus, name, inputMode, hasErrored, ...rest } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const prevFocus = usePrevious(!!focus)

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus()
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [autoFocus, focus, name, prevFocus])

  return (
    <>
      <input
        // className={`${hasErrored ? 'errInput' : 'none'}`}
        inputMode={inputMode}
        name={name}
        ref={inputRef}
        {...rest}
        // style={{
        //   border: `${hasErrored ? '1px solid #F44336' : 'none'}`
        // }}
      />
    </>
  )
}

const SingleOTPInput = memo(SingleOTPInputComponent)

export default SingleOTPInput
