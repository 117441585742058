import { Box as MuiBox, Card, CardContent, Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import tmr_failed from '../../assets/images/transmit-meter-reading-failed.svg'
import { useLocale } from '../../common/hooks/custom.hook'
import { convertDateTime } from '../../common/utils'
import { SelectedStyles } from '../../store/app/types'
import { Meter } from '../../store/shopping/types'
import { BodyText, Button, H2, SmallText } from '../../tmp-ui-element'
import CodeInput from '../code-input'
import { LoadingComponent } from '../index'

type TransmitMeterReadingProps = {
  loadingTransmitMetersReading: boolean | null
  isErrorTMR: boolean
  isShowBtnSubmitMeterReading: boolean
  isValidValueTransmitMeterReading: boolean
  onChangeMeterReading: (otp: string, id: number) => void
  handleDisableTMR: () => void
  handleTransmitMeterReading: () => void
  transmitMeterReadingData: Meter
  selectedStyles?: SelectedStyles
}

const useStyles = makeStyles(() =>
  createStyles({
    meterCode: {
      position: 'relative',
      '& div:nth-child(1)': {
        display: 'flex',
        width: 'auto !important',

        '& input': {
          backgroundColor: '#EFF2F3 !important',
          cursor: 'pointer',
          fontFamily: 'inherit',
          marginRight: '4px',
          border: 'none',
          borderRadius: '4px',
          fontSize: '24px',
          fontWeight: 600,
          color: '#222222 !important',
          WebkitTextFillColor: '#222222',
          zIndex: 1,
          opacity: 1 /* required on iOS */,
          padding: 0
        }
      }
    }
  })
)

const TransmitMeterReading = ({
  loadingTransmitMetersReading,
  isErrorTMR,
  isShowBtnSubmitMeterReading,
  isValidValueTransmitMeterReading,
  onChangeMeterReading,
  handleTransmitMeterReading,
  transmitMeterReadingData,
  handleDisableTMR,
  selectedStyles
}: TransmitMeterReadingProps) => {
  const { t } = useTranslation()
  const locale = useLocale()
  const classes = useStyles()

  return (
    <MuiBox pb={3} pt={1} style={{ fontSize: '24px', lineHeight: '32px' }}>
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item md={6} sm={12} xs={12}>
          <MuiBox alignItems="center" display="flex">
            <MuiBox
              style={{ fontSize: '24px', lineHeight: '32px' }}
              textAlign="left"
              width="80%"
            >
              {t('Transmit meter reading')}
            </MuiBox>
            <MuiBox textAlign="right" width="20%">
              <SmallText
                onClick={handleDisableTMR}
                style={{
                  fontWeight: 600,
                  color: '#039BE5',
                  cursor: 'pointer'
                }}
              >
                {t('Cancel')}
              </SmallText>
            </MuiBox>
          </MuiBox>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item md={6} sm={12} xs={12}>
          <Card
            style={{
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)'
            }}
            variant="outlined"
          >
            <CardContent
              style={{
                minHeight: '276px',
                position: 'relative'
              }}
            >
              {loadingTransmitMetersReading ? (
                <MuiBox
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <LoadingComponent color={selectedStyles?.palette?.primary} />
                </MuiBox>
              ) : isErrorTMR ? (
                <MuiBox>
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <img alt="No results found" src={tmr_failed} />
                  </MuiBox>
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    pt={4}
                  >
                    <BodyText>{t('Something went wrong')}</BodyText>
                  </MuiBox>
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    pt={2}
                  >
                    <SmallText
                      style={{
                        color: '#849096',
                        textAlign: 'center'
                      }}
                    >
                      {t('An error occurred and we are working to fix it')}
                    </SmallText>
                  </MuiBox>
                </MuiBox>
              ) : (
                <MuiBox>
                  <MuiBox alignItems="center" display="flex">
                    <MuiBox textAlign="left" width="30%">
                      <H2>{transmitMeterReadingData?.sector?.name}</H2>
                    </MuiBox>
                    <MuiBox textAlign="right" width="70%">
                      <SmallText>
                        {t('Meter number')}{' '}
                        <SmallText>
                          {transmitMeterReadingData?.meterNumber}
                        </SmallText>
                      </SmallText>
                    </MuiBox>
                  </MuiBox>

                  <MuiBox
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    pb={2}
                    pt={4}
                  >
                    <BodyText>
                      {`${t('Reading date')} ${convertDateTime(
                        '',
                        locale,
                        'dd MMMM yyyy'
                      )}`}
                    </BodyText>
                  </MuiBox>

                  <MuiBox pb={4}>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                    >
                      {transmitMeterReadingData?.sector?.name === 'Power' &&
                        transmitMeterReadingData?.sector?.source === 0 && (
                          <MuiBox
                            alignItems="center"
                            className={classes.meterCode}
                            display="flex"
                          >
                            <MuiBox>
                              <CodeInput
                                autoFocus
                                className="otpContainer"
                                hasErrored={!isValidValueTransmitMeterReading}
                                inputClassName="otpInput"
                                inputMode="numeric"
                                inputStyle={{
                                  width: '36px !important',
                                  height: '48px',
                                  margin: 0,
                                  marginRight: '4px'
                                }}
                                isNumberInput
                                length={7}
                                onChangeOTP={(otp) =>
                                  onChangeMeterReading(otp, 7)
                                }
                              />
                            </MuiBox>

                            <MuiBox
                              style={{
                                fontSize: '12px',
                                position: 'absolute',
                                left: '280px',
                                top: '16px'
                              }}
                            >
                              {transmitMeterReadingData?.lastMeterReading
                                ? transmitMeterReadingData?.lastMeterReading
                                    ?.unit?.value
                                : transmitMeterReadingData?.sector?.name ===
                                    'Power' &&
                                  transmitMeterReadingData?.sector?.source === 0
                                ? 'kWh'
                                : 'm³'}
                            </MuiBox>
                          </MuiBox>
                        )}
                      {transmitMeterReadingData?.sector?.name === 'Gas' &&
                        transmitMeterReadingData?.sector?.source === 0 && (
                          <MuiBox
                            alignItems="center"
                            className={classes.meterCode}
                            display="flex"
                          >
                            <CodeInput
                              autoFocus
                              className="otpContainer"
                              hasErrored={!isValidValueTransmitMeterReading}
                              inputClassName="otpInput"
                              inputMode="numeric"
                              inputStyle={{
                                width: '36px !important',
                                height: '48px',
                                margin: 0,
                                marginRight: '4px'
                              }}
                              isNumberInput
                              length={5}
                              onChangeOTP={(otp) =>
                                onChangeMeterReading(otp, 5)
                              }
                            />
                            <MuiBox
                              style={{
                                fontSize: '12px',
                                position: 'absolute',
                                left: '200px',
                                top: '16px'
                              }}
                            >
                              {
                                transmitMeterReadingData?.lastMeterReading?.unit
                                  ?.value
                              }
                            </MuiBox>
                          </MuiBox>
                        )}
                    </MuiBox>
                    {!isValidValueTransmitMeterReading && (
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        pt={0.5}
                      >
                        <SmallText style={{ color: '#F44336' }}>
                          {t('This reading must be more than prior value')}
                        </SmallText>
                      </MuiBox>
                    )}
                  </MuiBox>

                  {/* Transmit */}
                  <MuiBox
                    display="flex"
                    justifyContent="center"
                    maxWidth="350px"
                    style={{ margin: '0 auto' }}
                  >
                    <Button
                      disabled={!isShowBtnSubmitMeterReading}
                      fullWidth
                      onClick={handleTransmitMeterReading}
                      size="large"
                      style={{
                        backgroundColor: `${
                          selectedStyles?.palette?.primary || 'primary'
                        }`
                      }}
                      type="submit"
                      variant="contained"
                    >
                      <span
                        style={{
                          color: `${
                            (isShowBtnSubmitMeterReading &&
                              selectedStyles?.typography.primaryColor) ||
                            'inherit'
                          }`
                        }}
                      >
                        {t('Transmit')}
                      </span>
                    </Button>
                  </MuiBox>
                </MuiBox>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MuiBox>
  )
}

export default TransmitMeterReading
