import _ from 'lodash'
import { createSelector } from 'reselect'

import { ApplicationState } from '../index'

import { Order } from './types'

const getOrders = (state: ApplicationState) => state.shopping.orders

export const filterOrderByTypeSelector = createSelector(
  [getOrders],
  (orders) => {
    const newOrders = {
      processing: [] as Order[],
      completed: [] as Order[],
      canceled: [] as Order[]
    }

    _.forEach(orders, (order) => {
      if (!_.isEmpty(order)) {
        if (order.status === 0 || order.status === 1 || order.status === 2) {
          newOrders.processing.push(order)
        } else if (order.status === 3) {
          newOrders.completed.push(order)
        } else {
          newOrders.canceled.push(order)
        }
      }
    })

    return newOrders
  }
)
