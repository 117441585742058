import { Box as MuiBox, Card, CardContent } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useNavigation from '../common/hooks/navigation.hook'
import { getOrderDetail, updateCurrentId } from '../store/shopping/actions'
import { Chip, SmallText } from '../tmp-ui-element'

type RetailPrice = {
  paymentFrequency: number
  price: number
}
type ProductType = {
  type: number
  quantity: number
}
type Orders = {
  id?: number
  symbolId: string
  packageNames: string
  status: number
  isPriceSubjectToChange: boolean
  retailPrices: RetailPrice[]
  productTypes: ProductType[]
}

type ListOrderProps = {
  orders: Orders[]
  title?: string
}

const GlobalCss = withStyles({
  '@global': {
    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    cardWrapper: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EFF2F3'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const ListOrders = (props: ListOrderProps): React.ReactElement => {
  const { orders } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { goToOrderDetailPage } = useNavigation()

  const handlePaymentFrequency = (paymentFrequency: number) => {
    switch (paymentFrequency) {
      case 0:
        return t('one-time')
      case 1:
        return t('monthly')
      case 2:
        return t('yearly')
      default:
        return t('Price subject to change')
    }
  }

  const handleBgColorChipStatus = (status: number) => {
    switch (status) {
      case 1:
        return '#E09B0F'
      case 2:
        return '#16BFBE'
      case 3:
        return '#6BBF16'
      case 4:
        return '#849096'
      default:
        return '#039BE5'
    }
  }

  const handleTxtChipStatus = (status: number) => {
    switch (status) {
      case 1:
        return t('Processing')
      case 2:
        return t('Delivery')
      case 3:
        return t('Completed')
      case 4:
        return t('Canceled')
      default:
        return t('Ordered')
    }
  }

  const formatNumber = (number: number) => {
    return (
      number &&
      number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    )
  }

  const showOrderDetail = (item: Record<string, unknown>, id: number) => {
    dispatch(updateCurrentId(id))
    dispatch(getOrderDetail(item))

    goToOrderDetailPage()
  }

  return (
    <>
      <GlobalCss />
      <>
        {props.title && (
          <MuiBox
            pb={2}
            pt={3}
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '24px'
            }}
            textAlign="left"
          >
            {props.title}
          </MuiBox>
        )}

        {/*CONTENT*/}
        <Card className={classes.root} variant="outlined">
          {_.map(orders, (item, key) => (
            <MuiBox className={classes.cardWrapper} key={key}>
              {/*{(orders.length - 2 > key) && (*/}
              <MuiBox
                key={key}
                onClick={() => showOrderDetail(item, item.id as number)}
              >
                <CardContent key={key}>
                  <MuiBox display="flex" flexDirection="row" width="100%">
                    <MuiBox textAlign="left" width="50%">
                      <MuiBox>
                        <MuiBox className={classes.txtOverFlow}>
                          <span style={{ color: '#01579B' }}>
                            {item.packageNames}
                          </span>
                        </MuiBox>
                        <MuiBox>
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: '12px',
                              lineHeight: '16px',
                              color: '#849096',
                              paddingRight: '8px'
                            }}
                          >
                            {item.symbolId}
                          </span>
                          {props.title !== t('Completed') &&
                            props.title !== t('Canceled') && (
                              <Chip
                                label={handleTxtChipStatus(item.status)}
                                size="small"
                                style={{
                                  backgroundColor: handleBgColorChipStatus(
                                    item.status
                                  )
                                }}
                                type="primary"
                              />
                            )}
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>

                    {/*Price*/}
                    <MuiBox textAlign="right" width="50%">
                      {item.isPriceSubjectToChange ? (
                        <>
                          <SmallText style={{ color: '#849096' }}>
                            {t('Price subject to change')}
                          </SmallText>
                        </>
                      ) : (
                        <>
                          {item.retailPrices &&
                            item.retailPrices.map((price, key) => (
                              <MuiBox
                                display="flex"
                                flexDirection="row"
                                key={key}
                              >
                                <MuiBox
                                  textAlign="right"
                                  width="calc(100% - 75px)"
                                >
                                  {formatNumber(price.price)}
                                </MuiBox>
                                <MuiBox
                                  height="16px"
                                  pl={0.5}
                                  textAlign="left"
                                  width="75px"
                                >
                                  <span style={{ width: '10px' }}>€ </span>
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#849096',
                                      lineHeight: '16px'
                                    }}
                                  >
                                    {handlePaymentFrequency(
                                      price.paymentFrequency
                                    )}
                                  </span>
                                </MuiBox>
                              </MuiBox>
                            ))}
                        </>
                      )}
                    </MuiBox>
                  </MuiBox>
                </CardContent>
                {!(orders.length - 1 === key) && (
                  <div className={classes.line} />
                )}
              </MuiBox>
              {/*)}*/}
            </MuiBox>
          ))}
        </Card>
      </>
    </>
  )
}

export default ListOrders
