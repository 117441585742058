export const merge = (
  target: Record<string, unknown>,
  source: Record<string, unknown>
) => {
  const isObject = (obj: unknown) => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach((key: string) => {
    const targetValue = target[key] as Record<string, unknown>
    const sourceValue = source[key] as Record<string, unknown>

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue)
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = merge(
        Object.assign({}, targetValue),
        sourceValue as Record<string, unknown>
      )
    } else {
      target[key] = sourceValue
    }
  })

  return target
}

export const highlightMatchedText = (
  source: string,
  value: string
): Record<string, unknown>[] => {
  if (value === '') {
    return [
      {
        title: source,
        highlight: false
      }
    ]
  }

  const splitTextArr = source.split(new RegExp(value, 'i'))
  const matchedTextArr = source.match(new RegExp(value, 'gi'))

  const result: Array<Record<string, unknown>> = []

  for (let i = 0; i < splitTextArr.length; i++) {
    result.push({
      title: splitTextArr[i],
      highlight: false
    })
    matchedTextArr &&
      matchedTextArr[i] &&
      result.push({
        title: matchedTextArr[i],
        highlight: true
      })
  }

  return result
}
// export const getNested = (
//   obj: Record<string, unknown>,
//   ...args: string[]
// ): Record<string, unknown> => {
//   return args.reduce(
//     (obj, level): unknown => obj && (obj[level] as Record<string, unknown>),
//     obj
//   )
// }
