import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHouseElectricityIcon = createSvgIcon(
  <>
    <title>product house electricity</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="house-electricity"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <path
            d="M17.698 9.549l-6.514 6.804a.2.2 0 0 0 .052.315l2.342 1.225-1.662 6.377a.2.2 0 0 0 .337.19l6.566-6.725a.2.2 0 0 0-.044-.314l-2.22-1.26a.2.2 0 0 1-.095-.224l1.577-6.2a.2.2 0 0 0-.339-.188z"
            id="Path-8"
          />
          <path
            d="M14.318.637L1.402 12.69A.756.756 0 0 0 1.918 14h1.719c.11 0 .2.09.2.2V26a2 2 0 0 0 2 2h18.316a2 2 0 0 0 2-2V14.2c0-.11.09-.2.2-.2h1.723a.758.758 0 0 0 .518-1.313L15.682.637a1 1 0 0 0-1.364 0z"
            id="Shape"
            strokeLinecap="square"
          />
        </g>
      </g>
    </g>
  </>
)
