import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { LANDING_PAGE } from '../common/constants/route.constant'

type PrivateRouteProps = {
  children: React.ReactElement
  path: string
  exact?: boolean
  loggedIn: boolean
}
const PrivateRoute = ({
  exact = false,
  children,
  path,
  loggedIn
}: PrivateRouteProps): React.ReactElement => {
  return loggedIn ? (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  ) : (
    <Redirect to={LANDING_PAGE} />
  )
}

export default PrivateRoute
