import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import MainRoute from './routes/main.route'
import { store, persistor } from './store/configureStore'

/* eslint @typescript-eslint/no-var-requires: "off" */
const Bugsnag = require('@bugsnag/js')
const BugsnagPluginReact = require('@bugsnag/plugin-react')

Bugsnag.start({
  apiKey: 'dbab71ab4fbe7cef466f49a143d997ed',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STAGE || 'local',
  enabledReleaseStages: ['prod', 'staging', 'dev']
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

/* ----------------------------------------------------------------------------
 * React App
 * ------------------------------------------------------------------------- */
function App() {
  return (
    <ErrorBoundary>
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MainRoute />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </ErrorBoundary>
  )
}

export default App
