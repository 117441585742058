import { Box as MuiBox } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'
import { connect } from 'react-redux'

import { AppState } from '../store/app/types'

const useStyles = makeStyles(() =>
  createStyles({
    success: {
      backgroundColor: '#6BBF16',
      position: 'fixed',
      width: 'calc(100% - 240px)',
      right: 0,
      top: '56px',
      zIndex: 9999,
      ['@media (max-width:780px)']: {
        width: '100%',
        left: 0
      }
    },
    hideContentSuccess: {
      display: 'none'
    }
  })
)

type SuccessProps = {
  children: ReactElement
  onClose?: () => void
  isOpen?: boolean
}

const Success = (props: SuccessProps): React.ReactElement => {
  const classes = useStyles()
  const { children, onClose = () => ({}), isOpen } = props

  return (
    <>
      {isOpen && (
        <MuiBox
          alignItems="center"
          className={classes.success}
          display="flex"
          minHeight="32px"
          px={2}
          py={0.5}
        >
          <MuiBox textAlign="left" width="85%">
            {children}
          </MuiBox>
          <MuiBox
            onClick={onClose}
            style={{ cursor: 'pointer' }}
            textAlign="right"
            width="15%"
          >
            <svg
              fill="none"
              height="8"
              viewBox="0 0 8 8"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.25 1.25L1.25 7.25"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M7.25 7.25L1.25 1.25"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </MuiBox>
        </MuiBox>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    ...state
  }
}

export default connect(mapStateToProps)(Success)
