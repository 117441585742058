import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductChpIcon = createSvgIcon(
  <>
    <title>product chp</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g fill="none" fillRule="evenodd" id="chp" stroke="none" strokeWidth="1">
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(2 6)">
          <path
            d="M0 0v21.726a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V0"
            id="Path-69"
            strokeLinecap="round"
          />
          <rect
            height="15.933"
            id="Rectangle"
            rx="2"
            width="20"
            x="4"
            y="4.814"
          />
          <path d="M0 10.789h4v4.979H0z" id="Rectangle" />
          <path d="M24 10.789h4v4.979h-4z" id="Rectangle-Copy" />
          <path
            d="M15.715 7.348l-4.331 4.88a.2.2 0 0 0 .05.308l1.577.889-1.103 4.567a.2.2 0 0 0 .343.18l4.368-4.826a.2.2 0 0 0-.044-.305l-1.463-.897a.2.2 0 0 1-.09-.216l1.037-4.401a.2.2 0 0 0-.344-.179z"
            id="Path-8"
          />
        </g>
      </g>
    </g>
  </>
)
