import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductStorageIcon = createSvgIcon(
  <>
    <title>product storage</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="storage"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(8 2)">
          <rect height="25" id="Rectangle" rx="2" width="16" x="0" y="3" />
          <path d="M4 3V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1" id="Path-77" />
          <path d="M7.74 6.354v5" id="Path-78" strokeLinecap="round" />
          <path d="M5.24 8.854h5" id="Path-79" strokeLinecap="round" />
          <path d="M5.76 24.328h4.48" id="Path-80" strokeLinecap="round" />
        </g>
      </g>
    </g>
  </>
)
