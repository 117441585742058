import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const WarningIcon = createSvgIcon(
  <>
    <title>warning</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="2 29 16 3 30 29 2 29"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="13"
        y2="20"
      />{' '}
      <circle
        cx="16"
        cy="24"
        data-color="color-2"
        data-stroke="none"
        r="1"
        stroke="none"
      />
    </g>
  </>
)
