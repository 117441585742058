import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UngroupIcon = createSvgIcon(
  <>
    <title>ungroup</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-color="color-2"
        fill="none"
        points=" 23,9 31,9 31,31 9,31 9,23 "
        strokeMiterlimit="10"
      />{' '}
      <rect
        fill="none"
        height="22"
        stroke="#222222"
        strokeMiterlimit="10"
        width="22"
        x="1"
        y="1"
      />
    </g>
  </>
)
