import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PositionPinIcon = createSvgIcon(
  <>
    <title>position pin</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M26,10.545 c0,5.891-10,16.418-10,16.418S6,16.437,6,10.545C6,4.505,11.168,1,16,1S26,4.505,26,10.545z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="11"
        fill="none"
        r="3"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M8,23.717 C4.373,24.44,2,25.641,2,27c0,2.209,6.268,4,14,4s14-1.791,14-4c0-1.359-2.373-2.56-6-3.283"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
