import { Auth } from 'aws-amplify'
import { History, Location } from 'history'
import { all, call, put } from 'redux-saga/effects'
import { takeLatest } from 'typed-redux-saga'

import {
  ACTIVATION_PENDING_PAGE,
  ERROR_PAGE,
  LANDING_PAGE,
  PortalAPIName,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  StylingEditorAPIName
} from '../../common/constants'
import { amplifyGet, get, PORTAL_API_URL_ECP_ADMIN } from '../../common/utils'
import { getProfile, updateProfile } from '../profile/actions'
import { TypedAction } from '../types'

import { updateAppState } from './actions'
import { AppActionTypes } from './types'

function* getSelectedStylesFlow(action: TypedAction<Record<string, unknown>>) {
  const { id, history } = action.payload as {
    id: string
    history: History
  }

  try {
    const selectedStyles = yield call(amplifyGet, {
      path: `/design-builder/brands/${id}/selected-styles`,
      apiName: StylingEditorAPIName
    })

    yield put({
      type: AppActionTypes.GET_SELECTED_STYLES_SUCCESS,
      payload: selectedStyles
    })
  } catch (error) {
    yield put({
      type: AppActionTypes.GET_SELECTED_STYLES_FAILURE,
      payload: error.message
    })
    history.push(ERROR_PAGE)
  }
}

function* checkEmailExistFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    yield put({
      type: AppActionTypes.CHECK_EMAIL_EXIST_REQUEST
    })
    console.log(encodeURIComponent(action.payload.email as string))
    console.log(action.payload.email)

    const response = yield call(amplifyGet, {
      path: `/customers`,
      apiName: PortalAPIName,
      params: {
        organizationId: action.payload.orgId,
        email: action.payload.email
      }
    })

    yield put({
      type: AppActionTypes.CHECK_EMAIL_EXIST_SUCCESS,
      payload: {
        email: action.payload.email,
        customerId: response.id
      }
    })

    return {
      type: AppActionTypes.CHECK_EMAIL_EXIST_SUCCESS,
      payload: action.payload.email
    }
  } catch (error) {
    yield put({
      type: AppActionTypes.CHECK_EMAIL_EXIST_FAILURE,
      payload: error?.response?.data?.message || 'Unknown error'
    })

    return {
      type: AppActionTypes.CHECK_EMAIL_EXIST_FAILURE,
      payload: error?.response?.data?.message || 'Unknown error'
    }
  }
}

function* authenticationFlow(action: TypedAction<Record<string, unknown>>) {
  const { email, history } = action.payload as {
    email: string
    orgId: string
    history: History
  }

  try {
    const checkEmailExistResponse = yield* checkEmailExistFlow(action)

    if (
      checkEmailExistResponse.type === AppActionTypes.CHECK_EMAIL_EXIST_SUCCESS
    ) {
      yield Auth.signIn(email as string, '123').catch((error) => {
        throw error
      })
    } else {
      yield put({
        type: AppActionTypes.AUTHENTICATION_FAILURE,
        payload: {
          isEmailExistInEpilotSystem: false
        }
      })
    }
  } catch (error) {
    const code = error.code

    if (code === 'UserNotConfirmedException') {
      // User was already in cognito, but hasn't been activated
      history.push(ACTIVATION_PENDING_PAGE)
    } else if (code === 'UserNotFoundException') {
      // User doesn't exist in cognito
      history.push(SIGN_UP_PAGE)
    } else {
      history.push(SIGN_IN_PAGE)
    }

    yield put({
      type: AppActionTypes.AUTHENTICATION_SUCCESS
    })
  }
}

function* checkUserLoggedInFLow() {
  try {
    const { attributes } = yield Auth.currentAuthenticatedUser()

    const customerId = attributes['custom:ivy_customer_id']
    const organizationId = attributes['custom:ivy_org_id']

    yield put({ type: AppActionTypes.CHECK_USER_LOGGED_IN_SUCCESS })

    yield put(updateProfile({ customerId, organizationId }))
    yield put(getProfile(customerId))
  } catch (e) {
    console.log(e)
    yield put({ type: AppActionTypes.CHECK_USER_LOGGED_IN_FAILURE })
  }
}

function* logOutFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const { history, isGlobalSignOut } = action.payload as {
      history: History
      isGlobalSignOut?: boolean
    }

    yield Auth.signOut({ global: isGlobalSignOut })
    yield put(updateAppState({ loggedIn: false }))
    yield localStorage.removeItem('needToLogOut')
    yield history.push(LANDING_PAGE)
  } catch (e) {
    console.log(e)
  }
}

function* checkPremiumFeatureFlow(
  action: TypedAction<Record<string, unknown>>
) {
  const { organizationId, featureId, history } = action.payload as {
    organizationId: string
    featureId: string
    history: History
  }

  try {
    const response = yield call(amplifyGet, {
      path: `/premium-features`,
      apiName: PortalAPIName,
      params: {
        organizationId,
        featureId
      }
    })
    const { enable } = response

    yield put({
      type: AppActionTypes.CHECK_PREMIUM_FEATURE_SUCCESS
    })

    if (!enable) {
      history.push(ERROR_PAGE)
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: AppActionTypes.CHECK_PREMIUM_FEATURE_FAILURE
    })
    history.push(ERROR_PAGE)
  }
}

function* handleGetPrivacyAndSupportEmail(
  action: TypedAction<Record<string, unknown>>
) {
  try {
    const response = yield call(
      get,
      `${PORTAL_API_URL_ECP_ADMIN}/ecp-admin/brands/${action.payload.brandId}`,
      {}
    )

    yield put({
      type: AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_FAILURE,
      payload: error?.message
    })
  }
}

function* appSaga() {
  yield all([
    takeLatest(
      AppActionTypes.GET_SELECTED_STYLES_REQUEST,
      getSelectedStylesFlow
    ),
    takeLatest(AppActionTypes.AUTHENTICATION_REQUEST, authenticationFlow),
    takeLatest(
      AppActionTypes.CHECK_USER_LOGGED_IN_REQUEST,
      checkUserLoggedInFLow
    ),
    takeLatest(AppActionTypes.LOG_OUT, logOutFlow),
    takeLatest(
      AppActionTypes.CHECK_PREMIUM_FEATURE_REQUEST,
      checkPremiumFeatureFlow
    ),
    takeLatest(
      AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_REQUEST,
      handleGetPrivacyAndSupportEmail
    )
  ])
}

export default appSaga
