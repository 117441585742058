import { Box as MuiBox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useSetPassword } from '../common/hooks/custom.hook'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { ChangePasswordErrorType } from '../store/profile/types'
import {
  Button,
  Input,
  LoadingIcon,
  PadlockIcon,
  SmallText
} from '../tmp-ui-element'

import PasswordRequirement from './password-requirement'

export interface NewPasswordProps {
  handleChangePassword: ({
    currentPassword,
    newPassword
  }: {
    currentPassword: string
    newPassword: string
  }) => void
  selectedStyles?: SelectedStyles
}

const NewPassword = ({
  handleChangePassword,
  selectedStyles
}: NewPasswordProps): ReactElement => {
  const { t } = useTranslation()
  const { loadingChangePassword, changePasswordError } = useSelector(
    (state: ApplicationState) => state.profile
  )
  const [isInvalidPassword, setIsInvalidPassword] = useState(false)
  const [isExceededLimit, setIsExceededLimit] = useState(false)

  useEffect(() => {
    switch (changePasswordError.type) {
      case ChangePasswordErrorType.INVALID_PARAMETER:
      case ChangePasswordErrorType.WRONG_CURRENT_PASSWORD:
        setIsInvalidPassword(true)
        break
      case ChangePasswordErrorType.LIMIT_EXCEEDED:
        setIsExceededLimit(true)
        setIsInvalidPassword(false)
        break
      default:
        setIsExceededLimit(false)
        setIsInvalidPassword(false)
    }
  }, [changePasswordError.type, setIsInvalidPassword, setIsExceededLimit])

  const {
    isMinimum8Characters,
    isOneNumber,
    isOneUpperCaseCharacter,
    isValidateForm,
    onChangePassword
  } = useSetPassword()

  const nextTick = useEffect

  const useStyles = makeStyles({
    button: {
      marginTop: '16px',
      letterSpacing: 0,
      fontWeight: 600
    },
    textContainer: {
      marginTop: '16px'
    },
    bulletContainer: {
      textAlign: 'left',
      paddingInlineStart: '20px'
    },
    passwordStrength: {
      textAlign: 'left',
      marginTop: '16px'
    },

    weak: {
      color: '#F44336'
    },
    fair: {
      color: '#FF9800'
    },
    good: {
      color: '#039BE5'
    },
    strong: {
      color: '#6BBF16'
    },
    listContainer: {
      marginTop: '16px'
    }
  })
  const classes = useStyles()

  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '' }}
      onSubmit={(values) => {
        handleChangePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        })
      }}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props

        nextTick(() => {
          if (
            !(Object.keys(values).length === 0 && values.constructor === Object)
          ) {
            onChangePassword({ password: values.newPassword })
          }
        }, [values])

        return (
          <form onSubmit={handleSubmit}>
            <Input
              description={
                isInvalidPassword ? t('The entered password is incorrect') : ''
              }
              error={isInvalidPassword}
              fullWidth
              id="currentPassword"
              isLabelInside
              label={t('Current password')}
              leftAdornment={<PadlockIcon size="smallest" />}
              onChange={handleChange}
              showPasswordIcon
              type="password"
              value={values.currentPassword}
            />
            <Input
              description={errors.newPassword}
              error={errors.newPassword != null && touched.newPassword}
              fullWidth
              id="newPassword"
              isLabelInside
              label={t('New password')}
              leftAdornment={<PadlockIcon size="smallest" />}
              onChange={handleChange}
              showPasswordIcon
              type="password"
              value={values.newPassword}
            />
            {isExceededLimit && (
              <MuiBox pt={2}>
                <SmallText style={{ color: '#F44336' }}>
                  {t('You’ve tried too many times')}
                </SmallText>
              </MuiBox>
            )}

            <PasswordRequirement
              isMinimum8Characters={isMinimum8Characters}
              isOneNumber={isOneNumber}
              isOneUpperCaseCharacter={isOneUpperCaseCharacter}
            />

            <Button
              className={classes.button}
              disabled={
                loadingChangePassword ||
                values.newPassword === '' ||
                values.currentPassword === '' ||
                !isValidateForm
              }
              // color="primary"
              fullWidth
              size="large"
              style={{
                backgroundColor: `${
                  selectedStyles?.palette?.primary || 'primary'
                }`
              }}
              type="submit"
              variant="contained"
            >
              {loadingChangePassword ? (
                <LoadingIcon
                  color={selectedStyles?.palette?.primary}
                  height="32px"
                  width="32px"
                />
              ) : (
                <span
                  style={{
                    color: `${
                      (values.newPassword !== '' &&
                        values.currentPassword !== '' &&
                        isValidateForm &&
                        selectedStyles?.typography.primaryColor) ||
                      'inherit'
                    }`
                  }}
                >
                  {t('Update password')}
                </span>
              )}
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}

export default NewPassword
