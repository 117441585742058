import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import hexToRgba from 'hex-to-rgba'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import useNavigation from '../common/hooks/navigation.hook'
import { AppState } from '../store/app/types'
import {
  BulletTwoIcon,
  DashboardIcon,
  EpilotWhiteLogoIcon,
  MeterIcon
} from '../tmp-ui-element'

type SidebarProps = {
  isLoading?: boolean
  isDashboardPage?: boolean
  isOrderPage?: boolean
  isMeterPage?: boolean
  textColor?: string
  bgColorDashboardTab?: string
  bgColorOrdersTab?: string
  bgColorMetersTab?: string
  brandId: string
  logoName?: string
  logoUrl?: string
}

const GlobalCss = withStyles({
  '@global': {
    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {
        // backgroundColor: '#FFFFFF !important',
      }
    },
    '.MuiPaper-outlined': {
      border: '1px solid #D2DEE4'
    },
    '.MuiBackdrop-root': {
      opacity: '0.7 !important'
    },
    '.MuiDrawer-paperAnchorLeft': {
      // backgroundColor: 'red',
      width: '82vw'
    },
    '.MuiDialogContent-root': {
      padding: '16px 0'
    },
    '.MuiDialogTitle-root': {
      backgroundColor: '#039BE5'
    },
    '.MuiSvgIcon-root': {
      color: '#ffffff',
      position: 'relative'
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    comingSoon: {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    size: {
      width: '80px',
      height: '80px'
    },
    rightIcon: {
      position: 'absolute',
      right: '10px'
    },
    logoButton: {
      display: 'flex'
    },
    menuItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: `${hexToRgba('#FFFFFF', 0.2)} !important`
      }
    }
  })
)

const Sidebar = (props: SidebarProps): React.ReactElement => {
  const { textColor, bgColorDashboardTab, bgColorOrdersTab, bgColorMetersTab, logoName, logoUrl } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const { goToDashboardPage, goToOrdersPage, goToMetersPage } = useNavigation()

  return (
    <>
      <GlobalCss />
      <MuiBox>
        <MuiBox
          alignItems="center"
          className={classes.logoButton}
          flexDirection="row"
          pl={2}
          style={{ width: '100%', height: '84px' }}
        >
          {logoUrl ? (
            <img
              alt={logoName}
              src={logoUrl}
              style={{ maxHeight: '40px', maxWidth: '120px', cursor: 'pointer' }}
              onClick={goToDashboardPage}
            />
          ) : (
            <EpilotWhiteLogoIcon
              onClick={goToDashboardPage}
              style={{ height: '40px', width: '120px', cursor: 'pointer' }}
              viewBox="0 0 260 80"
            />
          )}
        </MuiBox>
        <MuiBox>
          <MuiBox
            alignItems="center"
            className={classes.menuItem}
            display="flex"
            onClick={goToDashboardPage}
            p={2}
            style={{
              backgroundColor: bgColorDashboardTab
            }}
          >
            <DashboardIcon color="#ffffff" size={'small'} />
            <span
              style={{
                paddingLeft: '14px',
                fontWeight: 600,
                color: `${textColor}`
              }}
            >
              {t('Dashboard')}
            </span>
          </MuiBox>
          <MuiBox
            alignItems="center"
            className={classes.menuItem}
            display="flex"
            onClick={goToOrdersPage}
            p={2}
            style={{ backgroundColor: bgColorOrdersTab }}
          >
            <BulletTwoIcon color="#ffffff" size={'small'} />
            <span
              style={{
                paddingLeft: '14px',
                fontWeight: 600,
                color: `${textColor}`
              }}
            >
              {t('Orders')}
            </span>
          </MuiBox>
          <MuiBox
            alignItems="center"
            className={classes.menuItem}
            display="flex"
            onClick={goToMetersPage}
            p={2}
            style={{ backgroundColor: bgColorMetersTab }}
          >
            <MeterIcon color="#ffffff" size={'small'} />
            <span
              style={{
                paddingLeft: '14px',
                fontWeight: 600,
                color: `${textColor}`
              }}
            >
              {t('Meters')}
            </span>
          </MuiBox>
        </MuiBox>
      </MuiBox>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    ...state
  }
}

export default connect(mapStateToProps)(Sidebar)
