import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SearchIcon = createSvgIcon(
  <>
    <title>search</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="29"
        x2="20.777"
        y1="29"
        y2="20.777"
      />{' '}
      <circle
        cx="13"
        cy="13"
        fill="none"
        r="11"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
