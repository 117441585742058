import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const LinkIcon = createSvgIcon(
  <>
    <title>link</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M16,20.644A6.968,6.968,0,0,1,13.1,18.9h0a7,7,0,0,1,0-9.9l4.95-4.95a7,7,0,0,1,9.9,0h0a7,7,0,0,1,0,9.9L24.9,17"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M16,11.356A6.968,6.968,0,0,1,18.9,13.1h0a7,7,0,0,1,0,9.9l-4.95,4.95a7,7,0,0,1-9.9,0h0a7,7,0,0,1,0-9.9L7.1,15"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
