import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PreferencesIcon = createSvgIcon(
  <>
    <title>preferences</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="17"
        x2="31"
        y1="5"
        y2="5"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="1"
        x2="8"
        y1="5"
        y2="5"
      />{' '}
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        width="5"
        x="8"
        y="1"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="17"
        x2="31"
        y1="27"
        y2="27"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="1"
        x2="8"
        y1="27"
        y2="27"
      />{' '}
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        width="5"
        x="8"
        y="23"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="28"
        x2="31"
        y1="16"
        y2="16"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="1"
        x2="19"
        y1="16"
        y2="16"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="8"
        strokeMiterlimit="10"
        width="5"
        x="19"
        y="12"
      />
    </g>
  </>
)
