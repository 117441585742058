import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UserListIcon = createSvgIcon(
  <>
    <title>user list</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="31"
        y1="7"
        y2="7"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="31"
        y1="13"
        y2="13"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="25"
        x2="31"
        y1="19"
        y2="19"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="25"
        x2="31"
        y1="25"
        y2="25"
      />{' '}
      <path
        d="M19,29H1v-6.242 c0-1.775,1.164-3.334,2.866-3.838C5.46,18.448,7.604,18,10,18c2.356,0,4.514,0.456,6.125,0.932C17.83,19.436,19,20.994,19,22.773V29 z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M5,8c0-2.761,2.239-5,5-5 s5,2.239,5,5s-2.239,6-5,6S5,10.761,5,8z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
