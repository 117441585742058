import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const Home5Icon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="none">
      <path
        d="M27.75 6.25l-11.5-4-11.5 4"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path d="M7.25 5.417v9.334" stroke="#222222" />
      <path d="M25.25 5.341v9.41" stroke="#222222" />
      <path
        d="M14.25 30.25v-7h4v7h12v-13h-28v13h12z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M14.25 8.25h-3v4h3v-4z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M21.25 8.25h-3v4h3v-4z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M26.25 21.25h-3.5v4.5h3.5v-4.5z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M9.75 21.25h-3.5v4.5h3.5v-4.5z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
