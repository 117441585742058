import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductGasIcon = createSvgIcon(
  <>
    <title>product gas</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g fill="none" fillRule="evenodd" id="gas" stroke="none" strokeWidth="1">
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <path
          d="M12.347 13.944c.005-.742.003-1.266-.006-1.573a8.871 8.871 0 0 0-.196-1.608c-.298-1.386-1.493-4.142-1.923-4.142-.43 0 .448 2.991.064 4.142a7.439 7.439 0 0 1-.86 1.787c-.22.333-.65.882-1.29 1.647-1.928 2.038-2.958 3.967-3.089 5.789-.196 2.733.177 3.921 1.984 6.274.953 1.24 2.817 2.407 4.5 3.047 2.136.813 4.486.704 5.069.666 1.986-.128 2.796-.562 3.797-.93.885-.327 2.165-1.176 3.096-2.016.546-.493 1.274-1.396 2.183-2.71.98-1.774 1.416-3.584 1.308-5.432-.163-2.771-1.388-6.127-1.942-6.335-.554-.208.327 3.616-.55 5.401-.512 1.04-1.33 1.82-2.454 2.339a.2.2 0 0 1-.264-.268c1.353-2.804 1.532-5.45.536-7.941-.845-2.113-1.427-3.029-1.727-3.479-.19-.284-.567-.867-1.182-1.577-.357-.412-.692-.843-1.247-1.39-3.518-3.468-7.868-3.918-7.932-3.51-.065.408 3.445 1.89 4.036 5.624.364 2.298-.153 4.403-1.55 6.315a.2.2 0 0 1-.361-.12z"
          id="Path-23-Copy-2"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      </g>
    </g>
  </>
)
