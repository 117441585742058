import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ImageIcon = createSvgIcon(
  <>
    <title>image</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="14.858,21.142 22,14 31,22 "
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="1,25 10,17 18.501,24.249 "
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <rect
        fill="none"
        height="26"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="3"
      />{' '}
      <circle
        cx="14.5"
        cy="10.5"
        data-color="color-2"
        fill="none"
        r="2.5"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
