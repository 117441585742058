import {
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { default as MuiCloseIcon } from '@material-ui/icons/Close'
import React from 'react'

import { BodyText, H2, SmallText } from '../typography'

export interface CartProps {
  footerContent?: React.ReactElement
  onClose?: React.MouseEventHandler
  children?: React.ReactElement | null
  title?: React.ReactElement
  hideTitle?: boolean
}

/**
 * Use `Cart` to show the user cart
 */
export const Cart = (props: CartProps): React.ReactElement => {
  const { onClose, title, children, footerContent, hideTitle } = props

  const useStyles = makeStyles({
    close: {
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    fullList: {
      width: 'auto',
      overflowY: 'auto',
      maxHeight: '80%'
    },
    footer: {
      flex: '1 1 auto',
      padding: '16px',
      borderTop: '1px solid #D2DEE4'
    },
    content: {
      overflowY: 'scroll',
      height: 'contain'
    }
  })
  const classes = useStyles()

  return (
    <>
      <div className={classes.fullList} role="presentation">
        {!hideTitle && (
          <MuiDialogTitle>
            <div className={classes.titleContainer}>
              {title}
              <MuiCloseIcon className={classes.close} onClick={onClose} />
            </div>
          </MuiDialogTitle>
        )}
        <MuiDialogContent>
          <div className="cart--totals-wrapper">{children}</div>
        </MuiDialogContent>
      </div>
      {footerContent ? (
        <div className={classes.footer}>{footerContent}</div>
      ) : null}
    </>
  )
}

export interface ClosedCartProps {
  onClick?: React.MouseEventHandler
  total?: string | React.ReactElement
  price?: string | React.ReactElement
  frecuency?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  color?: string
  centerText?: boolean
}

export const ClosedCart = (props: ClosedCartProps): React.ReactElement => {
  const { total, subtitle, price, frecuency, color, centerText } = props
  const useStyles = makeStyles({
    total: {
      float: 'left',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    price: {
      display: 'inline-block',
      verticalAlign: 'top'
    },
    container: {
      padding: '10px 0',
      textAlign: centerText ? 'center' : 'right',
      cursor: 'pointer'
    }
  })
  const classes = useStyles()

  if (centerText) {
    return (
      <div className={classes.container}>
        <BodyText
          className={classes.total}
          customColor={color ? color : '#039BE5'}
          fontWeight={600}
        >
          {total}
        </BodyText>
        <H2 display="inline" fontWeight={600}>
          {price}
        </H2>
        <BodyText display="inline" fontWeight={600}>
          {frecuency}
        </BodyText>
        <SmallText customColor="#849096" display="block">
          {subtitle}
        </SmallText>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <BodyText
        className={classes.total}
        customColor={color ? color : '#039BE5'}
        fontWeight={600}
      >
        {total}
      </BodyText>
      <div className={classes.price}>
        <H2 display="inline" fontWeight={600}>
          {price}
        </H2>
        <BodyText display="inline" fontWeight={600}>
          {frecuency}
        </BodyText>
        <SmallText customColor="#849096" display="block">
          {subtitle}
        </SmallText>
      </div>
    </div>
  )
}
