import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PhoneIcon = createSvgIcon(
  <>
    <title>phone</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M20.693,19.293l-2.282,2.853a24.077,24.077,0,0,1-8.557-8.557l2.853-2.282a1.939,1.939,0,0,0,.56-2.3l-2.6-5.855A1.939,1.939,0,0,0,8.407,2.062L3.457,3.346A1.95,1.95,0,0,0,2.018,5.5,28.761,28.761,0,0,0,26.5,29.982a1.95,1.95,0,0,0,2.153-1.439l1.284-4.95a1.939,1.939,0,0,0-1.089-2.258l-5.855-2.6A1.939,1.939,0,0,0,20.693,19.293Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
