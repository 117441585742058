import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UserAddIcon = createSvgIcon(
  <>
    <title>user add</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M19,20.334 C17.549,20.129,15.886,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h22"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="27"
        x2="27"
        y1="19"
        y2="27"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="23"
        x2="31"
        y1="23"
        y2="23"
      />
    </g>
  </>
)
