import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createMuiTheme,
  Theme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles'
import { Palette as MuiPalette } from '@material-ui/core/styles/createPalette'
import { ComponentsProps } from '@material-ui/core/styles/props'
import React from 'react'
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider
} from 'styled-components'

import { merge } from '../helpers'

import { ColorProps } from './colors'

import { epilotTheme, globalTheme } from './'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: ${(props: { theme: Theme }) => {
      return props.theme.typography.fontFamily
    }};
    font-size: ${(props: { theme: Theme }) =>
      props.theme.typography.fontSize}px;
  }
`

export type MaterialTheme = Theme
export interface ThemeProps extends Theme {
  props: { theme: 'epilot' | 'dark' } & ComponentsProps
  palette: ColorProps & MuiPalette
}
export interface ThemeProviderProps {
  children: React.ReactNode
  theme?: ThemeProps
  materialTheme?: boolean
}

const ThemeProvider = (props: ThemeProviderProps): React.ReactElement => {
  const { children, theme = epilotTheme, materialTheme } = props
  let defaultTheme

  if (materialTheme) {
    defaultTheme = merge(
      (createMuiTheme() as unknown) as Record<string, unknown>,
      (globalTheme as unknown) as Record<string, unknown>
    )
  } else {
    defaultTheme = merge(
      (createMuiTheme() as unknown) as Record<string, unknown>,
      (theme as unknown) as Record<string, unknown>
    )
    defaultTheme = merge(
      defaultTheme,
      (globalTheme as unknown) as Record<string, unknown>
    )
  }

  return (
    <StyledThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={defaultTheme}>
        <>
          <GlobalStyle />
          <CssBaseline />
          {children}
        </>
      </MuiThemeProvider>
    </StyledThemeProvider>
  )
}

export default ThemeProvider
