import { makeStyles } from '@material-ui/core'
import MuiTypography, {
  TypographyProps as MuiTypographyProps
} from '@material-ui/core/Typography'
import React from 'react'

type FontWeight = number
type lineHeight = string
type CustomColor = string

interface CustomTypographyStyle {
  fontWeight?: FontWeight
  lineHeight?: lineHeight
  customColor?: CustomColor
}

export interface TypographyProps extends MuiTypographyProps {
  children?: React.ReactNode
  fontWeight?: FontWeight
  lineHeight?: lineHeight
  customColor?: CustomColor
}

const useStyles = makeStyles({
  root: (props: CustomTypographyStyle) => ({
    fontWeight: props.fontWeight,
    lineHeight: props.lineHeight,
    color: props.customColor
  })
})

const BaseTypography = (props: TypographyProps): React.ReactElement => {
  const { children, fontWeight, lineHeight, customColor, ...muiProps } = props
  const classes = useStyles({ fontWeight, lineHeight, customColor })

  return (
    <MuiTypography {...muiProps} classes={classes}>
      {children}
    </MuiTypography>
  )
}

export const DisplayText = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h6'}>
      {children}
    </BaseTypography>
  )
}

export const H1 = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h1'}>
      {children}
    </BaseTypography>
  )
}

export const H2 = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h2'}>
      {children}
    </BaseTypography>
  )
}

export const H3 = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h3'}>
      {children}
    </BaseTypography>
  )
}

export const H4 = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h4'}>
      {children}
    </BaseTypography>
  )
}
export const H5 = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'h5'}>
      {children}
    </BaseTypography>
  )
}

export const LargeText = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'body1'}>
      {children}
    </BaseTypography>
  )
}

export const BodyText = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'body2'}>
      {children}
    </BaseTypography>
  )
}

export const SmallText = (props: TypographyProps): React.ReactElement => {
  const { children } = props

  return (
    <BaseTypography {...props} variant={'caption'}>
      {children}
    </BaseTypography>
  )
}
