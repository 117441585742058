export default {
  epilot: {
    overrides: {
      MuiSvgIcon: {
        root: {
          width: '21px'
        }
      }
    }
  },
  dark: {}
} as { [key: string]: Record<string, unknown> }
