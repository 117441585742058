import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ReplyIcon = createSvgIcon(
  <>
    <title>reply</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M14,3v8c8,0,17,3,17,16.947 C27.8,22.747,23,20,14,20v7.947L2,15L14,3z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
