import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SmallTriangleUpIcon = createSvgIcon(
  <>
    <title>small triangle up</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="23,21 9,21 16,11 "
        stroke="#222222"
        strokeMiterlimit="10"
        transform="translate(0, 0)"
      />
    </g>
  </>
)
