import { makeStyles } from '@material-ui/core/styles'
import { Formik, FormikHelpers } from 'formik'
import React, {
  ChangeEvent,
  MouseEventHandler,
  ReactElement,
  useEffect
} from 'react'
import { ObjectSchema } from 'yup'

import {
  BodyText,
  Button,
  Checkbox,
  Input,
  LoadingIcon,
  MailIcon,
  MultipleUserIcon,
  PadlockIcon
} from '../tmp-ui-element'

import { Container } from './container'

interface ArrayType {
  value: string
  label: string
}

export interface LoginProps {
  backgroundColor?: string
  textColor?: string
  loadingIndicatorColor?: string
  value?: string
  onResetPassword?: MouseEventHandler
  onSubmitForm: (
    values: Record<string, unknown>,
    functions: FormikHelpers<{
      email: string
      password: string
      symbol: string
    }>
  ) => void
  validateSchema?: () => ObjectSchema
  hideLanguageDropdown?: boolean
  onLanguageChange?: (event: ChangeEvent) => void
  languages?: ArrayType[]
  headerText?: string
  subHeaderText?: string | React.ReactElement
  languageValue?: string
  companySlogan?: string
  authOrganizationLabel?: string
  authPasswordLabel?: string
  authEmailLabel?: string
  authSigninButtonLabel?: string
  authTermOfServiceLink?: string
  authForgotPasswordLink?: string
  onRememberMeChange?: (isChecked: boolean) => void
  rememberMeLabel?: string
  termsText?: string | React.ReactElement
  isSubmitting?: boolean
  existSupportEmail?: boolean
  isShowSymbolField?: boolean
  isShowEmailField?: boolean
  isShowPasswordField?: boolean
  isShowRememberMe?: boolean
  isShowButtonSubmitForm?: boolean
  isValidForm?: boolean
  logoName?: string
  logoUrl?: string
  maxHeightLoginFormOnMobile?: string
  heightLoginFormOnMobile?: string
  customChildContent?: string | React.ReactElement
  titleIcon?: string | React.ReactElement
  termsTextInForm?: string | React.ReactElement
  passwordMustContain?: string | React.ReactElement
  customErrorMessage?: string | React.ReactElement
  onChangeValues?: (
    values: Record<string, unknown>,
    errors: Record<string, unknown>
  ) => void
}

export const Login = (props: LoginProps): ReactElement => {
  const {
    isSubmitting = false,
    authEmailLabel,
    authSigninButtonLabel,
    authForgotPasswordLink,
    authPasswordLabel,
    authOrganizationLabel,
    onResetPassword = () => ({}),
    onSubmitForm,
    hideLanguageDropdown,
    onLanguageChange,
    languages,
    headerText,
    subHeaderText,
    languageValue,
    companySlogan,
    onRememberMeChange = () => ({}),
    rememberMeLabel,
    termsText,
    isShowSymbolField = true,
    isShowEmailField = true,
    isShowPasswordField = true,
    isShowRememberMe = true,
    customChildContent,
    titleIcon,
    termsTextInForm,
    isShowButtonSubmitForm = true,
    validateSchema,
    onChangeValues,
    isValidForm,
    backgroundColor = '#039BE5',
    textColor = '#FFFFFF',
    loadingIndicatorColor = '#039BE5',
    logoName,
    logoUrl,
    maxHeightLoginFormOnMobile,
    heightLoginFormOnMobile,
    existSupportEmail = true
  } = props

  const useStyles = makeStyles({
    button: {
      marginTop: '16px',
      letterSpacing: 0,
      fontWeight: 600
    },
    textContainer: {
      marginTop: '12px'
    },
    pointer: {
      cursor: 'pointer'
    },
    left: {
      textDecoration: 'underline',
      float: 'left'
    },
    right: {
      textDecoration: 'underline',
      float: 'right'
    },
    checkboxContainer: {
      float: 'left',
      marginTop: '12px'
    },
    checkboxClassName: {
      marginRight: '6px'
    },
    underText: {
      marginTop: '16px',
      display: 'inline-block'
    },
    inputField: {
      '& .MuiInputBase-root': {
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: 0
      }
    },
    emailField: {
      '& .MuiInputBase-root': {
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: 0
      }
    },
    passwordField: {
      '& .MuiInputBase-root': {
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: 0
      }
    }
  })

  const classes = useStyles(props)
  const nextTick = useEffect

  return (
    <Container
      backgroundColor={backgroundColor}
      companySlogan={companySlogan}
      headerText={headerText}
      heightLoginFormOnMobile={heightLoginFormOnMobile}
      hideLanguageDropdown={hideLanguageDropdown}
      languageValue={languageValue}
      languages={languages}
      logoName={logoName}
      logoUrl={logoUrl}
      maxHeightLoginFormOnMobile={maxHeightLoginFormOnMobile}
      onLanguageChange={onLanguageChange}
      subHeaderText={subHeaderText}
      termsText={termsText}
      titleIcon={titleIcon}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
          symbol: ''
        }}
        onSubmit={async (values, functions) => {
          onSubmitForm(values, functions)
        }}
        validationSchema={validateSchema}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit } = props

          nextTick(() => {
            if (
              !(
                Object.keys(values).length === 0 &&
                values.constructor === Object
              )
            ) {
              onChangeValues && onChangeValues(values, errors)
            }
          }, [errors, values])

          return (
            <form onSubmit={handleSubmit}>
              {isShowSymbolField && (
                <Input
                  className={classes.inputField}
                  description={touched.symbol ? errors.symbol : ''}
                  error={errors.symbol != null && touched.symbol}
                  fullWidth
                  helperText={errors.symbol}
                  id="symbol"
                  isLabelInside
                  label={authOrganizationLabel}
                  leftAdornment={<MultipleUserIcon size="smallest" />}
                  onChange={handleChange}
                  showPasswordIcon
                  value={values.symbol}
                />
              )}
              {isShowEmailField && (
                <Input
                  className={classes.emailField}
                  description={errors.email ? errors.email : ''}
                  error={errors.email != null}
                  fullWidth
                  helperText={errors.email ? errors.email : ''}
                  id="email"
                  isLabelInside
                  label={authEmailLabel}
                  leftAdornment={<MailIcon size="smallest" />}
                  onChange={handleChange}
                  value={values.email}
                />
              )}
              {isShowPasswordField && (
                <Input
                  className={classes.passwordField}
                  description={errors.password ? errors.password : ''}
                  error={errors.password != null}
                  fullWidth
                  helperText={errors.password}
                  id="password"
                  isLabelInside
                  label={authPasswordLabel}
                  leftAdornment={<PadlockIcon size="smallest" />}
                  onChange={handleChange}
                  showPasswordIcon
                  type="password"
                  value={values.password}
                />
              )}
              {customChildContent}
              {isShowButtonSubmitForm && (
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={isSubmitting || !isValidForm || !existSupportEmail}
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: backgroundColor,
                    color: textColor
                  }}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? (
                    <LoadingIcon
                      color={loadingIndicatorColor}
                      height="32px"
                      width="32px"
                    />
                  ) : (
                    authSigninButtonLabel
                  )}
                </Button>
              )}
              {isShowRememberMe && (
                <div className={classes.textContainer}>
                  <Checkbox
                    checkboxClassName={classes.checkboxClassName}
                    className={classes.checkboxContainer}
                    label={rememberMeLabel}
                    onChange={(elem: ChangeEvent) => {
                      onRememberMeChange(
                        (elem.target as HTMLInputElement).checked
                      )
                    }}
                  />
                  <BodyText
                    className={`${classes.textContainer} ${classes.pointer} ${classes.right}`}
                    display="inline"
                    gutterBottom
                    onClick={onResetPassword}
                  >
                    {authForgotPasswordLink}
                  </BodyText>
                </div>
              )}
              {termsTextInForm}
            </form>
          )
        }}
      </Formik>
    </Container>
  )
}
