import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const LaptopIcon = createSvgIcon(
  <>
    <title>laptop</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M26,28H6a5,5,0,0,1-5-5H31A5,5,0,0,1,26,28Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <path
        d="M4,19V7A3,3,0,0,1,7,4H25a3,3,0,0,1,3,3V19"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
      <circle
        cx="16"
        cy="8"
        data-color="color-2"
        data-stroke="none"
        r="1"
        stroke="none"
      />
    </g>
  </>
)
