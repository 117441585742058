import { Box as MuiBox, Card, Switch } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateSubscription } from '../store/profile/actions'
import { BodyText, LoadingIcon } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)
type SubscriptionProps = {
  selectedStyles?: SelectedStyles
}

const SubscriptionPage = (props: SubscriptionProps): React.ReactElement => {
  const { selectedStyles } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const [checked, setChecked] = useState<boolean>(false)
  const {
    profile: {
      profile: { subscription, customerId },
      loadingSubscription
    }
  } = useSelector((state: ApplicationState) => state)

  useEffect(() => {
    if (subscription !== checked) {
      setChecked(subscription)
    }
  }, [subscription])

  const onChange = () => {
    dispatch(updateSubscription(customerId as number, !checked))
  }

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false}>
        <MuiBox className={classes.main}>
          <MuiBox
            style={{
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 400
            }}
          >
            {t('Subscription')}
          </MuiBox>

          {/*CONTENT*/}
          <MuiBox
            alignItems="center"
            display="flex"
            flexDirection="row"
            py={2}
            width="100%"
          >
            <Card className={classes.root} variant="outlined">
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                p={2}
                width="100%"
              >
                <MuiBox textAlign="left" width="80%">
                  <BodyText>{t('Receive promotional email or SMS')}</BodyText>
                </MuiBox>
                <MuiBox display="flex" justifyContent="flex-end" width="20%">
                  {loadingSubscription ? (
                    <LoadingIcon color={selectedStyles?.palette?.primary} height="24px" width="24px" />
                  ) : (
                    <Switch checked={checked} onChange={onChange} />
                  )}
                </MuiBox>
              </MuiBox>
            </Card>
          </MuiBox>
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(SubscriptionPage)
