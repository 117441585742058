import { epilotThemeDarkColor } from './colors'
import { ThemeProps } from './theme-provider'

export default ({
  shape: {
    borderRadius: 4
  },
  palette: {
    ...epilotThemeDarkColor,
    primary: {
      main: '#000000',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#222222',
      contrastText: '#FFFFFF'
    },
    common: {
      blue: {
        lightest: '#F5FFFF'
      }
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700
  },
  props: {
    theme: 'dark',
    MuiButtonBase: {
      disableRipple: true
    },
    MuiButtonGroup: {
      disableRipple: true
    },
    MuiRadio: {
      disableRipple: true
    },
    MuiTextField: {
      InputLabelProps: {
        disabled: true,
        disableAnimation: true,
        shrink: true
      }
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiFilledInput: {
      disableUnderline: true
    },
    MuiSelect: {
      MenuProps: {
        elevation: 3,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      }
    },
    MuiDialog: {
      maxWidth: 'xl'
    }
  },

  overrides: {
    MuiButton: {
      disabled: {
        backgroundColor: '#F9FAFB'
      },
      root: {
        height: '32px',
        padding: '4px 16px 6px',
        lineHeight: '0',
        textTransform: 'none',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        fontVariant: 'tabular-nums',
        minWidth: '24px',
        '&.Mui-disabled': {
          color: '#849096 !important',
          backgroundColor: '#F9FAFB !important',
          border: 'none !important'
        }
      },
      text: {
        fontSize: '14px',
        fontWeight: '600',
        fontVariant: 'tabular-nums',
        padding: '1px 16px 0'
      },
      sizeSmall: {
        height: '24px',
        padding: '0 12px',
        fontSize: '12px'
      },
      sizeLarge: {
        height: '48px',
        padding: '0 24px',
        fontSize: '16px'
      },
      outlined: {
        padding: '4px 16px 6px'
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#67C3EF'
        },
        '&:active': {
          backgroundColor: '#01579B'
        }
      },
      outlinedPrimary: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #039BE5',
        '&:active': {
          backgroundColor: '#01579B',
          borderColor: '#01579B',
          color: '#FFFFFF'
        },
        '&:hover': {
          borderColor: '#67C3EF',
          backgroundColor: '#67C3EF',
          color: '#FFFFFF'
        }
      },
      outlinedSecondary: {
        borderColor: '#849096',
        '&:hover': {
          backgroundColor: '#E5EBEE',
          borderColor: '#E5EBEE'
        },
        '&:active': {
          backgroundColor: '#D2DEE4',
          borderColor: '#D2DEE4'
        }
      },
      containedSecondary: {
        backgroundColor: '#F44336',
        borderColor: '#F44336',
        '&:hover': {
          backgroundColor: '#F88E86',
          borderColor: '#F88E86',
          color: '#FFFFFF'
        },
        '&:active': {
          backgroundColor: '#A0362E',
          borderColor: '#A0362E'
        }
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: '#E5EBEE'
        },
        '&:active': {
          backgroundColor: '#D2DEE4'
        }
      }
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        borderColor: '#FFFFFF !important'
      },
      groupedContainedSecondary: {
        borderColor: '#FFFFFF !important'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiFormControlLabel: {
      root: {
        display: 'table-cell',
        borderRadius: '4px'
      },
      label: {
        fontSize: '14px'
      }
    },
    MuiSvgIcon: {
      root: {
        width: '19px'
      }
    },
    MuiIconButton: {
      root: {
        color: '#000000',
        '&:hover': {
          backgroundColor: 'initial !important'
        }
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
        }
      }
    },
    PrivateSwitchBase: {
      root: {
        padding: '0px 8px 0px 0px',
        '&:hover': {
          backgroundColor: 'initial !important'
        }
      },
      disabled: {
        '& svg *': {
          color: '#D2DEE4'
        },
        '& svg:nth-of-type(2)': {
          fill: '#E5EBEE',
          transform: 'scale(2)'
        }
      },
      checked: {
        '&.Mui-disabled': {
          '& svg:nth-of-type(2)': {
            fill: '#849096 !important',
            transform: 'scale(1.2)'
          }
        },
        '& svg:nth-of-type(2)': {
          transform: 'scale(1.2) !important'
        }
      }
    },
    MuiFormLabel: {
      root: {
        transform: 'scale(1)'
      }
    },
    MuiInputLabel: {
      root: {
        transform: 'translate(0px, -20px) scale(1) !important',
        backgroundColor: '#ffffff',
        border: 'none',
        fontSize: '12px',
        fontWeight: '600',
        color: '#222222 !important'
      },
      formControl: {
        transform: 'scale(1)',
        position: 'relative',
        top: '10px',
        textAlign: 'left'
      },
      filled: {
        color: '#ffffff !important',
        transform: 'translate(12px, 24px) scale(1.2)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(12px, 12px) scale(0.75)'
        }
      },
      shrink: {
        transform: 'scale(1)'
      }
    },
    MuiInputBase: {
      root: {
        height: '32px',
        borderRadius: '4px',
        padding: '0 14px',
        lineHeight: '0',
        textTransform: 'none',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        fontWeight: '500',
        fontVariant: 'tabular-nums',
        border: '1px solid #000000',
        color: '#000000',
        '&:hover': {
          border: '1px solid #039BE5'
        },
        '&:active': {
          border: '1px solid #039BE5'
        },
        '&:focus': {
          border: '1px solid #039BE5'
        },
        '&:focus-within': {
          border: '1px solid #039BE5'
        },
        ' label': { display: 'none' }
      },
      multiline: {
        height: 'initial',
        padding: '4px 16px',
        fontSize: '14px'
      },
      input: {
        height: '56px',
        padding: '0'
      },
      error: {
        border: '1px solid #ff0000'
      },
      disabled: {
        backgroundColor: '#F9FAFB',
        border: 'none',
        '&:hover': {
          border: 'none'
        },
        '&:active': {
          border: 'none'
        },
        '&:focus': {
          border: 'none'
        },
        '&:focus-within': {
          border: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        display: 'none'
      },
      input: {
        padding: 0
      },
      adornedEnd: {
        paddingRight: '7px'
      },
      adornedStart: {
        paddingLeft: '7px'
      }
    },
    MuiInputAdornment: {
      filled: {
        marginTop: '0 !important'
      },
      positionStart: {
        marginRight: '2px'
      },
      positionEnd: {
        marginLeft: '2px'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#ffffff'
        },
        '&:active': {
          backgroundColor: '#ffffff'
        },
        '&:focus': {
          backgroundColor: '#ffffff'
        },
        '&:focus-within': {
          backgroundColor: '#ffffff'
        }
      },
      input: {
        padding: '12px 10px'
      }
    },
    MuiFormHelperText: {
      root: {
        border: 'none',
        marginLeft: '0px !important'
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0'
      },
      h2: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '0'
      },
      h3: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '1px',
        textTransform: 'upperCase'
      },
      h4: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '1px'
      },
      h5: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '1px'
      },
      h6: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '0'
      },
      body1: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0'
      },
      body2: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0'
      },
      caption: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px'
      }
    },
    MuiDrawer: {
      paperAnchorTop: {
        maxHeight: '80%',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px'
      },
      paperAnchorBottom: {
        maxHeight: '80%',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
      }
    },
    MuiSelect: {
      select: {
        padding: '0 24px 0 16px',
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
          backgroundColor: 'transparent'
        }
      },
      selectMenu: {
        height: '100%'
      },
      iconOpen: {
        transform: 'none'
      }
    },
    MuiMenu: {
      list: {
        padding: 0,
        borderRadius: '4px'
      },
      paper: {
        margin: '4px 0'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 600,
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
        '&.Mui-disabled': {
          color: '#849096',
          backgroundColor: '#F9FAFB'
        },
        '&:hover': {
          backgroundColor: '#EFF2F3'
        }
      },
      selected: {
        backgroundColor: '#FFFFFF !important',
        color: '#039BE5',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: '#EFF2F3 !important'
        }
      }
    },
    MuiLinearProgress: {
      root: {
        height: '2px'
      },
      colorPrimary: {
        backgroundColor: '#D2DEE4'
      }
    },
    MuiAvatar: {
      root: {
        width: '30px',
        height: '30px',
        border: '1px solid #D2DEE4',
        '&.MuiAvatar-small': {
          width: '22px',
          height: '22px'
        }
      },
      colorDefault: {
        backgroundColor: '#039BE5',
        color: '#EFF2F3'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#EFF2F3'
        }
      }
    },
    MuiListItemText: {
      primary: {
        lineHeight: '18px'
      },
      secondary: {
        lineHeight: '14px',
        display: 'block',
        color: '#848096'
      }
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 'unset',
        display: 'flex'
      }
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        '& > *': {
          fontWeight: '700'
        }
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        textTransform: 'uppercase',
        color: '#849096'
      }
    },
    MuiBadge: {
      badge: {
        height: '16px',
        borderRadius: '8px',
        minWidth: '16px',
        fontSize: '12px',
        padding: '0 4px',
        color: '#222222'
      },
      root: {
        '&.outline $badge': {
          borderWidth: '1px',
          borderStyle: 'solid'
        },
        '&.default $badge': {
          backgroundColor: '#039BE5',
          color: '#FFFFFF'
        },
        '&.disabled $badge': {
          backgroundColor: '#F9FAFB',
          color: '#849096'
        },
        '&.info $badge': {
          backgroundColor: '#ACB8FE'
        },
        '&.warning $badge': {
          backgroundColor: '#FEE482'
        },
        '&.error $badge': {
          backgroundColor: '#F88E86'
        },
        '&.success $badge': {
          backgroundColor: '#A6D873'
        },
        '&.default.outline $badge': {
          backgroundColor: '#FFFFFF',
          color: '#039BE5',
          borderColor: '#039BE5'
        },
        '&.disabled.outline $badge': {
          backgroundColor: '#FFFFFF',
          color: '#849096',
          borderColor: '#849096'
        },
        '&.info.outline $badge': {
          backgroundColor: '#F9FAFF',
          borderColor: '#ACB8FE'
        },
        '&.warning.outline $badge': {
          backgroundColor: '#FEFAEA',
          borderColor: '#FEE482'
        },
        '&.error.outline $badge': {
          backgroundColor: '#FDECEA',
          borderColor: '#F88E86'
        },
        '&.success.outline $badge': {
          backgroundColor: '#F0F8E7',
          borderColor: '#A6D873'
        }
      }
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
        padding: '4px 8px',
        height: 'auto',
        border: 'none',

        '& $avatar': {
          marginRight: '4px',
          marginLeft: 0,
          width: '16px',
          height: '16px'
        },

        // Filled chip
        '&.MuiChip-default': {
          backgroundColor: '#D2DEE4',
          '&:hover': {
            backgroundColor: '#EFF2F3'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#849096'
          }
        },

        '&.MuiChip-primary': {
          backgroundColor: '#039BE5',
          '&:hover': {
            backgroundColor: '#67C3EF'
          },
          '& $label': {
            color: '#FFFFFF'
          },
          '& svg *': {
            stroke: '#FFFFFF'
          }
        },
        '&.MuiChip-info': {
          backgroundColor: '#304FFE',
          '&:hover': {
            backgroundColor: '#ACB8FE'
          },
          '& $label': {
            color: '#FFFFFF'
          },
          '& svg *': {
            stroke: '#FFFFFF'
          }
        },
        '&.MuiChip-warning': {
          backgroundColor: '#FED330',
          '&:hover': {
            backgroundColor: '#FEE482'
          },
          '& $label': {
            color: '#FFFFFF'
          },
          '& svg *': {
            stroke: '#FFFFFF'
          }
        },
        '&.MuiChip-error': {
          backgroundColor: '#F44336',
          '&:hover': {
            backgroundColor: '#F88E86'
          },
          '& $label': {
            color: '#FFFFFF'
          },
          '& svg *': {
            stroke: '#FFFFFF'
          }
        },
        '&.MuiChip-success': {
          backgroundColor: '#6BBF16',
          '&:hover': {
            backgroundColor: '#A6D873'
          },
          '& $label': {
            color: '#FFFFFF'
          },
          '& svg *': {
            stroke: '#FFFFFF'
          }
        }
      },
      outlined: {
        '& $avatar': {
          marginLeft: 0
        },

        // Outlined chip
        '&.MuiChip-default': {
          backgroundColor: '#FFFFFF',
          borderColor: '#D2DEE4',
          '&:hover': {
            backgroundColor: '#FFFFFF'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#849096'
          }
        },

        '&.MuiChip-primary': {
          backgroundColor: '#FFFFFF',
          borderColor: '#039BE5',
          '&:hover': {
            backgroundColor: '#FFFFFF'
          },
          '& $label': {
            color: '#039BE5'
          },
          '& svg *': {
            stroke: '#039BE5'
          }
        },
        '&.MuiChip-info': {
          backgroundColor: '#F9FAFF',
          borderColor: '#ACB8FE',
          '&:hover': {
            backgroundColor: '#F9FAFF'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#ACB8FE'
          }
        },
        '&.MuiChip-warning': {
          backgroundColor: '#FEFAEA',
          borderColor: '#FEE482',
          '&:hover': {
            backgroundColor: '#FEFAEA'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#FEE482'
          }
        },
        '&.MuiChip-error': {
          backgroundColor: '#FDECEA',
          borderColor: '#F88E86',
          '&:hover': {
            backgroundColor: '#FDECEA'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#F88E86'
          }
        },
        '&.MuiChip-success': {
          backgroundColor: '#F0F8E7',
          borderColor: '#A6D873',
          '&:hover': {
            backgroundColor: '#F0F8E7'
          },
          '& $label': {
            color: '#222222'
          },
          '& svg *': {
            stroke: '#A6D873'
          }
        }
      },
      sizeSmall: {
        padding: '0px 4px',
        height: 'auto'
      },
      label: {
        display: 'flex',
        color: '#222222',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
        paddingLeft: 0,
        paddingRight: 0
      },
      labelSmall: {
        paddingLeft: 0,
        paddingRight: 0
      },
      disabled: {
        backgroundColor: '#F9FAFB !important',
        opacity: '1 !important',
        border: 'none !important',

        '& $label': {
          color: '#849096 !important'
        }
      },
      deletable: {
        '& svg': {
          marginLeft: '8px',
          cursor: 'pointer'
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '8px'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '8px'
      }
    },
    MuiPopover: {
      paper: {
        overflowX: 'visible',
        overflowY: 'visible',
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#222222',
        opacity: '0.8 !important'
      }
    },
    MuiSlider: {
      thumb: {
        backgroundColor: '#ffffff',
        '&:hover': {
          boxShadow: 'none'
        }
      },
      active: {
        boxShadow: 'none'
      },
      rail: {
        backgroundColor: '#D2DEE4',
        height: '1px'
      },
      track: {
        backgroundColor: '#000000'
      },
      markLabel: {
        color: '#849096',
        fontFamily: 'Open Sans',
        fontSize: '12px'
      },
      mark: {
        width: '0px'
      }
    }
  }
} as unknown) as ThemeProps
