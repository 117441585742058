import { ShoppingActionTypes } from './types'

export const getToken = (id: number) => ({
  type: ShoppingActionTypes.LOAD_ACCESS_TOKEN_SUCCESS,
  payload: id
})

export const getAddresses = (id: number) => ({
  type: ShoppingActionTypes.LOAD_ORDER_ADDRESSES_REQUEST,
  payload: id
})

export const getOrders = (orgId: number, customerId: number) => ({
  type: ShoppingActionTypes.LOAD_ORDERS_REQUEST,
  payload: { orgId: orgId, customerId: customerId }
})

export const getRecentOrders = (orgId: number, customerId: number) => ({
  type: ShoppingActionTypes.LOAD_RECENT_ORDERS_REQUEST,
  payload: { orgId: orgId, customerId: customerId }
})

export const updateCurrentId = (id: number) => ({
  type: ShoppingActionTypes.UPDATE_CURRENT_ID_REQUEST,
  payload: id
})

export const getOrderDetail = (item: Record<string, unknown>) => ({
  type: ShoppingActionTypes.LOAD_ORDER_DETAIL_REQUEST,
  payload: item
})

export const getPricingDetail = (id: number) => ({
  type: ShoppingActionTypes.LOAD_PRICING_DETAIL_REQUEST,
  payload: id
})

export const getStatuses = (id: number) => ({
  type: ShoppingActionTypes.LOAD_STATUS_REQUEST,
  payload: id
})

export const getMeters = (id: number) => ({
  type: ShoppingActionTypes.LOAD_METERS_REQUEST,
  payload: id
})

export const getMetersHistory = (meterId: number | undefined) => ({
  type: ShoppingActionTypes.LOAD_METER_HISTORY_REQUEST,
  payload: meterId
})

export const transmitMetersReading = (
  meterId: number,
  value: number,
  unit_id: number,
  author: string
) => ({
  type: ShoppingActionTypes.TRANSMIT_METERS_READING_REQUEST,
  payload: { meterId: meterId, value: value, unit_id: unit_id, author: author }
})
