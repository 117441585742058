import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHouseGasIcon = createSvgIcon(
  <>
    <title>product house gas</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="house-gas"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <path
            d="M14.318.637L1.402 12.69A.756.756 0 0 0 1.918 14h1.719c.11 0 .2.09.2.2V26a2 2 0 0 0 2 2h18.316a2 2 0 0 0 2-2V14.2c0-.11.09-.2.2-.2h1.723a.758.758 0 0 0 .518-1.313L15.682.637a1 1 0 0 0-1.364 0z"
            id="Shape"
            strokeLinecap="square"
          />
          <path
            d="M13.177 17.04c-.111.268-.164-1.312-.173-1.625a4.808 4.808 0 0 0-.107-.874c-.163-.754-1.278-1.639-1.483-1.639-.206 0 .679 1.013.47 1.639a4.038 4.038 0 0 1-.47.972c-.12.18-.354.48-.704.895-1.051 1.108-1.613 2.157-1.684 3.147-.108 1.486.096 2.132 1.082 3.412.52.674 1.536 1.308 2.455 1.656 1.164.442 2.446.383 2.764.363 1.084-.07 1.525-.306 2.071-.507.483-.177 1.181-.639 1.689-1.095.298-.268.695-.76 1.19-1.474.536-.964.773-1.949.714-2.953-.089-1.507-.588-2.874-.878-2.982-.29-.108.042 1.603-.437 2.574-.478.97-1.487 2.104-1.576 1.006-.088-1.097.861-2.869 0-5.014-.46-1.149-1.325-2.32-1.802-2.827-1.863-1.981-3.273-2.925-3.735-2.674-.463.25 1.164 1.833 1.487 3.862.322 2.03-.762 3.87-.873 4.138z"
            id="Path-23-Copy"
          />
        </g>
      </g>
    </g>
  </>
)
