import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductChargeIcon = createSvgIcon(
  <>
    <title>product charge</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="charge"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(3 2)">
          <path
            d="M16 12.552h.985a1 1 0 0 1 1 1v11.413a2.552 2.552 0 0 0 2.552 2.552 2.534 2.534 0 0 0 2.534-2.552L23 14.717"
            id="Path-35"
          />
          <path
            d="M20.5 10.82v1.732c0 1.066.865 1.93 1.931 1.93h1.138a1.931 1.931 0 0 0 1.931-1.93V10.82a.2.2 0 0 0-.2-.2h-4.6a.2.2 0 0 0-.2.2z"
            id="Path-36"
          />
          <path d="M21.618 8.207v2.414" id="Path-37" strokeLinecap="round" />
          <path d="M24.5 8.207v2.414" id="Path-38" strokeLinecap="round" />
          <rect height="24.138" id="Rectangle" rx="7" width="16" x="0" y="0" />
          <path
            d="M10.698 4.359L4.213 10.49a.2.2 0 0 0 .051.326l2.16 1.022a.2.2 0 0 1 .107.236l-1.606 5.58a.2.2 0 0 0 .328.201l6.538-6.061a.2.2 0 0 0-.045-.325l-2.179-1.12a.2.2 0 0 1-.1-.232l1.561-5.56a.2.2 0 0 0-.33-.2z"
            id="Path-8"
          />
        </g>
      </g>
    </g>
  </>
)
