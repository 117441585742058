import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PreviewHideIcon = createSvgIcon(
  <>
    <title>preferences</title>
    <g
      className="nc-icon-wrapper"
      fill="#ffffff"
      stroke="#ffffff"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M8.36652 23.627C5.6934 21.8953 3.33947 19.7149 1.40851 17.182C1.14513 16.8435 1.00146 16.4272 1.00001 15.9984C0.998563 15.5695 1.13942 15.1522 1.40051 14.812C3.42151 12.162 8.88552 6 15.9995 6C18.7127 6.06243 21.3542 6.88268 23.6255 8.368"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M26.9937 10.9229C28.3019 12.1112 29.5035 13.4118 30.5847 14.8099C30.8495 15.15 30.9933 15.5688 30.9933 15.9999C30.9933 16.4309 30.8495 16.8497 30.5847 17.1899C28.5357 19.8419 23.0197 25.9999 15.9997 25.9999C14.6378 25.994 13.2856 25.7715 11.9937 25.3409"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.7577 20.2427C10.632 19.1179 9.99919 17.592 9.99854 16.0007C9.99788 14.4093 10.6294 12.8829 11.7542 11.7572C12.879 10.6315 14.4049 9.9987 15.9962 9.99805C17.5876 9.99739 19.114 10.6289 20.2397 11.7537"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M21.9167 15C22.0612 15.8603 22.0166 16.7418 21.7859 17.5831C21.5552 18.4244 21.1439 19.2053 20.5808 19.8716C20.0176 20.5378 19.316 21.0733 18.5249 21.4409C17.7338 21.8085 16.872 21.9993 15.9997 22C15.6626 22 15.3261 21.9719 14.9937 21.916"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M1.99365 30L29.9937 2"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
