import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import hexToRgba from 'hex-to-rgba'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import useCurrentScreen from '../common/hooks/current-screen.hook'
import { useQuery } from '../common/hooks/custom.hook'
import { Header, SideBar, SidebarOnMobile } from '../components'
import { ApplicationState } from '../store'
import { ECPRouteParams, SelectedStyles } from '../store/app/types'
import { Profile } from '../store/profile/types'
import { ThemeProvider } from '../tmp-ui-element'
import { Anchor } from '../types'

type AppEpilotProps = {
  isLoading?: boolean
  currProfile: Profile
  children: React.ReactElement
  isShowHeader?: boolean
  isShowSidebarMobile?: boolean
  isShowSidebar?: boolean
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    headerWrapper: {
      width: 'inherit',
      display: 'flex',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #D2DEE4 !important',
      boxShadow: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        backgroundColor: '#039BE5'
      }
    },
    menuButton: {
      display: 'none',
      background: 'red' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex'
      }
    },
    logoButton: {
      display: 'none',
      background: 'red' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex'
      }
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    size: {
      width: '80px',
      height: '80px'
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100%'
    },
    sideBarWrapper: {
      position: 'fixed',
      height: '100vh',
      top: 0,
      left: 0,
      width: '240px',
      // backgroundColor: '#039BE5' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'none'
      }
    },
    sideBarWrapperMobile: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex'
      }
    },
    rightContentWrapper: {
      marginLeft: '240px',
      overflow: 'auto',
      width: 'calc(100vw - 240px)' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        marginLeft: '0px',
        width: '100%'
      }
    },
    title: {
      flexGrow: 1
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    comingSoon: {
      overflowX: 'auto',
      msOverflowX: 'auto',
      minWidth: '100%' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        minWidth: 'calc(100vw - 16px)',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }
    },
    comingSoonItem: {
      flexGrow: 1,
      minWidth: '220px',
      marginRight: '8px',
      width: 'fit-content'
    },
    comingSoonEndItem: {
      flexGrow: 1,
      minWidth: '220px',
      marginRight: '0px',
      width: 'fit-content' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        marginRight: '8px'
      }
    },
    comingSoonOnMobile: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex',
        minWidth: '8px',
        maxWidth: '8px',
        width: 'fit-content'
      }
    },
    rightIcon: {
      position: 'absolute',
      right: '10px'
    }
  })
)

const MainLayout = (props: AppEpilotProps): React.ReactElement => {
  const { brandId } = useQuery() as ECPRouteParams
  const {
    currProfile,
    children,
    isLoading = false,
    isShowHeader = true,
    isShowSidebar = true,
    isShowSidebarMobile = true,
    selectedStyles
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [anchor, setAnchor] = useState('left' as Anchor)
  const classes = useStyles()
  const { isOrdersPage, isMetersPage, isDashboardPage, isOrderDetailPage } = useCurrentScreen()

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-ec-portal" data-component="app-epilot">
        <ThemeProvider>
          <div className={classes.root}>
            <div className={classes.mainContainer}>
              {isShowSidebar && (
                <MuiBox className={classes.sideBarWrapper} style={{backgroundColor: `${selectedStyles?.palette?.primary}`}}>
                  <SideBar
                    logoName={selectedStyles?.logo?.name}
                    logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
                    textColor={selectedStyles?.typography?.primaryColor || '#FFFFFF'}
                    bgColorDashboardTab={
                      isDashboardPage() ? hexToRgba('#FFFFFF', 0.2) : selectedStyles?.palette?.primary}
                    bgColorOrdersTab={(isOrdersPage() || isOrderDetailPage()) ? hexToRgba('#FFFFFF', 0.2) : selectedStyles?.palette?.primary}
                    bgColorMetersTab={isMetersPage() ? hexToRgba('#FFFFFF', 0.2) : selectedStyles?.palette?.primary}
                    brandId={brandId as string}
                  />
                </MuiBox>
              )}
              <MuiBox className={classes.rightContentWrapper}>
                {isShowHeader && (
                  <Header
                    currProfile={currProfile}
                    isLoading={isLoading}
                    openSidebar={() => {
                      setIsOpen(true)
                      setAnchor('left')
                    }}
                    selectedStyles={selectedStyles}
                  />
                )}
                {isShowSidebarMobile && (
                  <MuiBox className={classes.sideBarWrapperMobile}>
                    <SidebarOnMobile
                      anchor={anchor}
                      currProfile={currProfile}
                      onClose={() => setIsOpen(false)}
                      open={isOpen}
                      selectedStyles={selectedStyles}
                    />
                  </MuiBox>
                )}
                <div style={{ paddingTop: 56 }} />
                {children}
              </MuiBox>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    selectedStyles: state.app.selectedStyles
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
