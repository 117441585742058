import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import {
  useLoading,
  useQuery,
  useSetPassword
} from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { LoadingComponent } from '../components'
import PasswordRequirement from '../components/password-requirement'
import { ApplicationState } from '../store'
import { updateAppState } from '../store/app/actions'
import { updateProfile } from '../store/profile/actions'
import { BodyText, CheckIcon, DogIcon, ThemeProvider } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#849096',
      padding: '16px 0'
    },
    '.MuiFormLabel-root': {
      letterSpacing: 0,
      fontWeight: 600,
      lineHeight: '16px'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400,
      lineHeight: '24px',
      letterSpacing: 0
    } /* eslint-disable */,
    '@media (min-width: 780px)': {
      '.MuiBox-root div:nth-child(2)': {
        display: 'inherit'
      }
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

const ResetPasswordPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const {
    app: { selectedStyles },
    profile: {
      profile: { language }
    }
  } = useSelector((state: ApplicationState) => state)
  const { confirmationCode, userName, orgId } = useQuery() as {
    confirmationCode: string
    userName: string
    orgId: string
    brandId: string
  }
  const [isSetPasswordSuccess, setIsSetPasswordSuccess] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const {
    isMinimum8Characters,
    isOneNumber,
    isOneUpperCaseCharacter,
    values,
    isValidateForm,
    onChangePassword
  } = useSetPassword()

  const isLoadingStyles = useLoading()

  const dispatch = useDispatch()
  const { goToSignInPage, goToLandingPage } = useNavigation()

  useEffect(() => {
    if (!userName || !confirmationCode || !orgId) {
      goToLandingPage()
    }
  }, [userName, confirmationCode, orgId])

  const handleNextButton = async () => {
    try {
      setIsLoading(true)
      await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        values.password as string
      )
      dispatch(updateAppState({ currEmail: userName }))
      setIsSetPasswordSuccess(true)
      setIsLoading(false)
      goToSignInPage()
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const onLanguageChange = (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          {isLoadingStyles ? (
            <MuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <Login
              maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
              heightLoginFormOnMobile="93%"
              logoName={selectedStyles?.logo?.name}
              logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
              backgroundColor={selectedStyles?.palette?.primary}
              textColor={selectedStyles?.typography?.primaryColor}
              authPasswordLabel={t('Password')}
              authSigninButtonLabel={t('Save password and login')}
              customChildContent={
                <>
                  {!isSetPasswordSuccess && (
                    <PasswordRequirement
                      isMinimum8Characters={isMinimum8Characters}
                      isOneUpperCaseCharacter={isOneUpperCaseCharacter}
                      isOneNumber={isOneNumber}
                    />
                  )}
                </>
              }
              headerText={t('Set a new password')}
              isShowButtonSubmitForm={!isSetPasswordSuccess}
              isShowEmailField={false}
              isShowPasswordField={!isSetPasswordSuccess}
              isShowRememberMe={false}
              isShowSymbolField={false}
              isValidForm={isValidateForm}
              languages={[
                { value: 'en', label: 'English' },
                {
                  value: 'de',
                  label: 'Deutsch'
                }
              ]}
              languageValue={language}
              isSubmitting={isLoading}
              onChangeValues={onChangePassword}
              onLanguageChange={onLanguageChange}
              onSubmitForm={handleNextButton}
              rememberMeLabel="Remember me"
              loadingIndicatorColor={selectedStyles?.palette?.primary}
              subHeaderText={
                <BodyText>
                  {t('Please choose a password for your account')}
                </BodyText>
              }
            />
          )}
        </ThemeProvider>
      </div>
    </>
  )
}

export default ResetPasswordPage
