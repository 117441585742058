import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const Home4Icon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="none">
      <path
        d="M1.25 14.25l15-12 15 12"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <path
        d="M13.25 30.25v-8.5h6v8.5"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <path
        d="M5.25 15.25v15h22v-15"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M14 12.75H9v5h5v-5z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M23.5 12.75h-5v5h5v-5z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path d="M7.25 9.45v-5.2" stroke="#222222" strokeMiterlimit="10" />
    </g>
  </>
)
