import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PadlockIcon = createSvgIcon(
  <>
    <title>padlock</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M10,10.782V7a6.018,6.018,0,0,1,6-6h0a6.018,6.018,0,0,1,6,6v3.783"
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="20"
        fill="none"
        r="11"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="22"
        y2="25"
      />{' '}
      <circle
        cx="16"
        cy="19"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
