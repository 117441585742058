import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const RefreshIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M29,16c0,7.2-5.8,13-13,13 S3,23.2,3,16S8.8,3,16,3c5.2,0,9.8,3.1,11.8,7.6"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="28.9,3 27.9,10.7 20.2,9.7 "
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
