import { all, call, put } from '@redux-saga/core/effects'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { History } from 'history'
import { delay, select, takeLatest } from 'typed-redux-saga'

import {
  PortalAPIName,
  PortalCognitoTriggerAPIName
} from '../../common/constants'
import { amplifyGet, amplifyPatch, amplifyPost } from '../../common/utils'
import { logOut } from '../app/actions'
import { ApplicationState } from '../index'
import { TypedAction } from '../types'

import { getProfile, updateProfileState } from './actions'
import { ProfileActionTypes } from './types'

function* handleGetProfile(action: TypedAction<Record<string, unknown>>) {
  try {
    const profile = yield call(amplifyGet, {
      path: `/customers/${action.payload}/profiles`,
      apiName: PortalAPIName
    })

    yield put({
      type: ProfileActionTypes.GET_PROFILE_SUCCESS,
      payload: profile ?? {}
    })
  } catch (error) {
    yield put({
      type: ProfileActionTypes.GET_PROFILE_FAILURE,
      payload: error.message
    })
  }
}

function* updateLanguageFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    yield call(amplifyPatch, {
      path: `/customers/${action.payload.customerId}/profiles`,
      apiName: PortalAPIName,
      params: {
        language: action.payload.data
      }
    })

    yield put({
      type: ProfileActionTypes.UPDATE_LANGUAGE_SUCCESS,
      payload: action.payload.data
    })

    yield delay(3000)

    yield put(
      updateProfileState({
        isShowChangeLanguageSuccessMessage: false
      })
    )
  } catch (error) {
    yield put({
      type: ProfileActionTypes.UPDATE_LANGUAGE_FAILURE,
      payload: error.message
    })
  }
}

function* updateSubscriptionFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    yield call(amplifyPatch, {
      path: `/customers/${action.payload.customerId}/profiles`,
      apiName: PortalAPIName,
      params: { subscription: action.payload.data }
    })

    yield put({
      type: ProfileActionTypes.UPDATE_SUB_SUCCESS,
      payload: action.payload.data
    })
  } catch (error) {
    yield put({
      type: ProfileActionTypes.UPDATE_SUB_FAILURE,
      payload: error.message
    })
  }
}

function* uploadAvatarFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const { customerId } = yield select(
      (state: ApplicationState) => state.profile.profile
    )
    const updatedAvatar = yield call(amplifyPost, {
      path: `/customers/${action.payload.customerId}/avatars`,
      apiName: PortalAPIName,
      params: {
        name: action.payload.name,
        base64: action.payload.base64
      }
    })

    yield put({
      type: ProfileActionTypes.UPLOAD_AVATAR_SUCCESS,
      payload: updatedAvatar
    })
    yield put(getProfile(customerId as number))
  } catch (error) {
    yield put({
      type: ProfileActionTypes.UPLOAD_AVATAR_FAILURE,
      payload: error.message
    })
  }
}

function* deleteAvatarFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    yield call(amplifyPost, {
      path: `/customers/${action.payload.customerId}/avatars`,
      apiName: PortalAPIName,
      params: {
        name: action.payload.name
      }
    })

    yield put({
      type: ProfileActionTypes.DELETE_AVATAR_SUCCESS
    })
  } catch (error) {
    yield put({
      type: ProfileActionTypes.DELETE_AVATAR_FAILURE,
      payload: error.message
    })
  }
}

function* deleteUserAccountFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const { history } = action.payload as {
      history: History
    }

    const authorizeToken = yield Auth.currentSession()
      .then((session: CognitoUserSession) => {
        return (
          'Bearer ' +
          btoa(
            JSON.stringify({
              idtoken: session?.getIdToken()?.getJwtToken(),
              accesstoken: session?.getAccessToken()?.getJwtToken()
            })
          )
        )
      })
      .catch((error: Error) => console.log(error))

    yield Auth.currentAuthenticatedUser()
      .then(
        (user: CognitoUser) =>
          new Promise((resolve, reject) => {
            user.deleteUser((error) => {
              if (error) {
                return reject(error)
              }
              resolve()
            })
          })
      )
      .catch((e) => {
        throw e
      })

    try {
      yield call(amplifyPost, {
        path: `/post-confirmation?event=ConfirmAccountDeleted`,
        apiName: PortalCognitoTriggerAPIName,
        customHeader: { Authorization: authorizeToken }
      })
    } catch (e) {
      console.log(e)
    }
    yield put({
      type: ProfileActionTypes.DELETE_USER_ACCOUNT_SUCCESS
    })

    yield put(logOut({ history, isGlobalSignOut: true }))

    yield delay(3000)

    yield put(
      updateProfileState({
        isShowDeleteUserAccountMessage: false
      })
    )
  } catch (error) {
    yield put({
      type: ProfileActionTypes.DELETE_USER_ACCOUNT_FAILURE,
      payload: error.message
    })
  }
}

function* changePasswordFlow(action: TypedAction<Record<string, unknown>>) {
  try {
    const { currentPassword, newPassword } = action.payload as {
      currentPassword: string
      newPassword: string
      history: History
    }

    yield Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      return Auth.changePassword(user, currentPassword, newPassword)
    })

    try {
      yield call(amplifyPost, {
        path: `/post-confirmation?event=ConfirmPasswordChanged`,
        apiName: PortalCognitoTriggerAPIName
      })
    } catch (e) {
      console.log(e)
    }

    yield put({
      type: ProfileActionTypes.CHANGE_PASSWORD_SUCCESS
    })

    yield delay(3000)

    yield put(
      updateProfileState({
        isShowChangePasswordSuccessMessage: false
      })
    )
  } catch (error) {
    console.log(error)
    const { code, message } = error

    yield put({
      type: ProfileActionTypes.CHANGE_PASSWORD_FAILURE,
      payload: {
        type: code,
        message: message
      }
    })
  }
}

function* profileSaga() {
  yield all([
    takeLatest(ProfileActionTypes.GET_PROFILE_REQUEST, handleGetProfile),
    takeLatest(ProfileActionTypes.UPDATE_LANGUAGE_REQUEST, updateLanguageFlow),
    takeLatest(ProfileActionTypes.UPDATE_SUB_REQUEST, updateSubscriptionFlow),
    takeLatest(ProfileActionTypes.UPLOAD_AVATAR_REQUEST, uploadAvatarFlow),
    takeLatest(ProfileActionTypes.DELETE_AVATAR_REQUEST, deleteAvatarFlow),
    takeLatest(
      ProfileActionTypes.DELETE_USER_ACCOUNT_REQUEST,
      deleteUserAccountFlow
    ),
    takeLatest(ProfileActionTypes.CHANGE_PASSWORD_REQUEST, changePasswordFlow)
  ])
}

export default profileSaga
