import { Reducer } from 'redux'

import { AppActionTypes } from '../app/types'

import { ProfileActionTypes, ProfileState } from './types'

export const initialState: ProfileState = {
  loadingProfile: false,
  loadingSubscription: false,
  loadingUploadAvatar: false,
  loadingDeleteUserAccount: false,
  loadingChangePassword: false,
  loadingUpdateLanguage: false,
  isShowChangePasswordSuccessMessage: false,
  isShowDeleteUserAccountMessage: false,
  isShowChangeLanguageSuccessMessage: false,
  changePasswordError: {
    type: null,
    message: ''
  },
  profile: {
    firstName: '',
    lastName: '',
    company_name: '',
    email: '',
    phone: '',
    subscription: true,
    language: 'de',
    avatar: '',
    addresses: [
      {
        streetName: '',
        streetNumber: '',
        postcode: '',
        city: '',
        country: ''
      }
    ],
    customerId: null,
    organizationId: null,
    brandId: ''
  }
}

const reducer: Reducer<ProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case ProfileActionTypes.GET_PROFILE_REQUEST: {
      return {
        ...state,
        loadingProfile: true
      }
    }
    case ProfileActionTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        },
        loadingProfile: false
      }
    }
    case ProfileActionTypes.GET_PROFILE_FAILURE: {
      return {
        ...state,
        loadingProfile: false
      }
    }
    case ProfileActionTypes.UPDATE_PROFILE_INFO: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      }
    }
    case ProfileActionTypes.UPDATE_PROFILE_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case ProfileActionTypes.UPDATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isShowChangeLanguageSuccessMessage: true,
        loadingUpdateLanguage: false,
        profile: {
          ...state.profile,
          language: action.payload
        }
      }
    }
    case ProfileActionTypes.UPDATE_LANGUAGE_FAILURE: {
      return {
        ...state,
        loadingUpdateLanguage: false
      }
    }
    case ProfileActionTypes.UPDATE_LANGUAGE_REQUEST: {
      return {
        ...state,
        loadingUpdateLanguage: true,
        isShowChangeLanguageSuccessMessage: false
      }
    }
    case ProfileActionTypes.UPDATE_SUB_REQUEST: {
      return {
        ...state,
        loadingSubscription: true
      }
    }
    case ProfileActionTypes.UPDATE_SUB_SUCCESS: {
      return {
        ...state,
        loadingSubscription: false,
        profile: {
          ...state.profile,
          subscription: action.payload
        }
      }
    }
    case ProfileActionTypes.UPDATE_SUB_FAILURE: {
      return {
        ...state,
        loadingSubscription: false
      }
    }
    case ProfileActionTypes.UPLOAD_AVATAR_SUCCESS: {
      return {
        ...state,
        loadingUploadAvatar: false
      }
    }
    case ProfileActionTypes.DELETE_AVATAR_FAILURE:
    case ProfileActionTypes.UPLOAD_AVATAR_FAILURE: {
      return {
        ...state,
        loadingUploadAvatar: false
      }
    }
    case ProfileActionTypes.DELETE_AVATAR_REQUEST:
    case ProfileActionTypes.UPLOAD_AVATAR_REQUEST: {
      return {
        ...state,
        loadingUploadAvatar: true
      }
    }
    case ProfileActionTypes.DELETE_AVATAR_SUCCESS: {
      return {
        ...state,
        loadingUploadAvatar: false,
        profile: {
          ...state.profile,
          avatar: ''
        }
      }
    }
    case AppActionTypes.CHECK_EMAIL_EXIST_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          customerId: action.payload.customerId
        }
      }
    }
    case ProfileActionTypes.DELETE_USER_ACCOUNT_FAILURE:
    case ProfileActionTypes.DELETE_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loadingDeleteUserAccount: false,
        isShowDeleteUserAccountMessage: true
      }
    }
    case ProfileActionTypes.DELETE_USER_ACCOUNT_REQUEST: {
      return {
        ...state,
        loadingDeleteUserAccount: true
      }
    }

    case ProfileActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loadingChangePassword: false,
        isShowChangePasswordSuccessMessage: true
      }
    }
    case ProfileActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        loadingChangePassword: false,
        changePasswordError: {
          ...action.payload
        }
      }
    }
    case ProfileActionTypes.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loadingChangePassword: true
      }
    }

    default: {
      return state
    }
  }
}

export { reducer as profileReducer }
