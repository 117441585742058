import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PrivateIcon = createSvgIcon(
  <>
    <title>private</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="Private"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <circle cx="16" cy="16" id="Oval" r="15" stroke="#222222" />
        <path d="M11 15h10v7H11z" id="Rectangle" stroke="#222222" />
        <path d="M13 15v-3a3 3 0 0 1 6 0v3" id="Path" stroke="#222222" />
      </g>
    </g>
  </>
)
