import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MeterIcon = createSvgIcon(
  <>
    <title>meter</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="5"
        y2="7"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="23.778"
        x2="22.364"
        y1="8.222"
        y2="9.636"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="27"
        x2="25"
        y1="16"
        y2="16"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="7"
        x2="5"
        y1="16"
        y2="16"
      />{' '}
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="13.88"
        x2="8"
        y1="13.88"
        y2="8"
      />{' '}
      <circle
        cx="16"
        cy="16"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
