import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MultipleUserIcon = createSvgIcon(
  <>
    <title>multiple user</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M10.713,13.719 C10.232,12.705,9.197,12,8,12H4c-1.657,0-3,1.343-3,3v5h2v7h6"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="6"
        cy="6"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M21.287,13.719 C21.768,12.705,22.803,12,24,12h4c1.657,0,3,1.343,3,3v5h-2v7h-6"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="26"
        cy="6"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M19,31h-6v-8h-3v-7 c0-2.209,1.791-4,4-4h4c2.209,0,4,1.791,4,4v7h-3V31z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="5"
        fill="none"
        r="4"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
