import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import {
  ACTIVATE_ACCOUNT_PAGE,
  ACTIVATION_PENDING_PAGE,
  ADDRESS_PAGE,
  CHANGE_PASSWORD_PAGE,
  CREATE_ACCOUNT_PAGE,
  DASHBOARD_PAGE,
  ERROR_PAGE,
  FORGOT_PASSWORD_PAGE,
  LANDING_PAGE,
  LANGUAGE_PAGE,
  METERS_PAGE,
  ORDER_DETAIL_PAGE,
  ORDERS_PAGE,
  PROFILE_DETAIL_PAGE,
  PROFILE_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  SUBSCRIPTION_PAGE
} from '../common/constants'
import useCurrentScreen from '../common/hooks/current-screen.hook'
import { useConfigAmplify, useQuery } from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import UserAuthentication from '../components/user-authentication'
import {
  ActivationPending,
  UserAddress,
  BrandError,
  ChangePasswordPage,
  CreateAccountPage,
  Dashboard,
  ForgotPassword,
  LandingPage,
  Language,
  Meter,
  Order,
  OrderDetail,
  Profile,
  ProfileDetail,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  Subscription
} from '../pages'
import ActivateAccount from '../pages/activate-account.page'
import { ApplicationState } from '../store'
import {
  checkPremiumFeature,
  getSelectedStyles,
  logOut,
  getPrivacyAndSupportEmail
} from '../store/app/actions'
import { ECPRouteParams } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'

import PrivateRoute from './private.route'

export default function MainRoute() {
  const { brandId, orgId } = useQuery() as ECPRouteParams
  const {
    profile: { profile: currProfile },
    app: {
      loggedIn,
      isAlreadyLoadedSelectedStyles,
      isAlreadyCheckedPremiumFeature
    }
  } = useSelector((state: ApplicationState) => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { goToErrorPage } = useNavigation()
  const { isErrorPage } = useCurrentScreen()

  useConfigAmplify()

  useEffect(() => {
    const handleLogOut = (e: StorageEvent) => {
      if (e.key === 'needToLogOut') {
        dispatch(logOut({ history, location }))
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleLogOut)

    return function cleanup() {
      window.removeEventListener('storage', handleLogOut)
    }
  }, [dispatch, history, location])

  useEffect(() => {
    if (
      orgId &&
      currProfile.organizationId &&
      orgId !== currProfile.organizationId
    ) {
      localStorage.setItem('needToLogOut', 'true')
    }
    if (orgId) {
      dispatch(
        updateProfile({
          organizationId: orgId
        })
      )
    }
  }, [orgId, dispatch, currProfile.organizationId])

  // check premium feature
  useEffect(() => {
    if (!isAlreadyCheckedPremiumFeature) {
      if (orgId) {
        dispatch(
          checkPremiumFeature({
            organizationId: orgId as string,
            history
          })
        )
      } else if (currProfile.organizationId) {
        dispatch(
          checkPremiumFeature({
            organizationId: currProfile.organizationId as string,
            history
          })
        )
      }
    }
  }, [
    orgId,
    currProfile.organizationId,
    dispatch,
    history,
    isAlreadyCheckedPremiumFeature
  ])

  useEffect(() => {
    if (!orgId && !currProfile.organizationId && !isErrorPage()) {
      goToErrorPage()
    }
  }, [currProfile.organizationId, goToErrorPage, orgId, isErrorPage])

  useEffect(() => {
    currProfile.language
      ? i18n.changeLanguage(currProfile.language)
      : i18n.changeLanguage('en')
  }, [currProfile.language])

  useEffect(() => {
    if (!isAlreadyLoadedSelectedStyles) {
      if (brandId) {
        dispatch(updateProfile({ brandId }))
        dispatch(getPrivacyAndSupportEmail(brandId))
        dispatch(getSelectedStyles({ id: brandId, history }))
      } else {
        if (currProfile.brandId) {
          dispatch(getSelectedStyles({ id: currProfile.brandId, history }))
        }
      }
    }
  }, [
    currProfile.brandId,
    brandId,
    dispatch,
    history,
    isAlreadyLoadedSelectedStyles
  ])

  return (
    <UserAuthentication>
      <Switch>
        <Route path={SIGN_UP_PAGE}>
          {loggedIn ? <Redirect to={`${DASHBOARD_PAGE}`} /> : <SignUpPage />}
        </Route>

        <Route path={SIGN_IN_PAGE}>
          {loggedIn ? <Redirect to={`${DASHBOARD_PAGE}`} /> : <SignInPage />}
        </Route>

        <Route exact path={LANDING_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <LandingPage />}
        </Route>

        <Route exact path={ACTIVATION_PENDING_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <ActivationPending />}
        </Route>

        <Route exact path={ACTIVATE_ACCOUNT_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <ActivateAccount />}
        </Route>

        <Route exact path={FORGOT_PASSWORD_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <ForgotPassword />}
        </Route>

        <Route exact path={CREATE_ACCOUNT_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <CreateAccountPage />}
        </Route>

        <Route exact path={RESET_PASSWORD_PAGE}>
          {loggedIn ? <Redirect to={DASHBOARD_PAGE} /> : <ResetPasswordPage />}
        </Route>

        <Route exact path={ERROR_PAGE}>
          <BrandError />
        </Route>

        <PrivateRoute exact loggedIn={loggedIn} path={DASHBOARD_PAGE}>
          <Dashboard />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={PROFILE_PAGE}>
          <Profile />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={ORDERS_PAGE}>
          <Order />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={METERS_PAGE}>
          <Meter />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={ORDER_DETAIL_PAGE}>
          <OrderDetail />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={PROFILE_DETAIL_PAGE}>
          <ProfileDetail />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={ADDRESS_PAGE}>
          <UserAddress />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={SUBSCRIPTION_PAGE}>
          <Subscription />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={LANGUAGE_PAGE}>
          <Language />
        </PrivateRoute>

        <PrivateRoute exact loggedIn={loggedIn} path={CHANGE_PASSWORD_PAGE}>
          <ChangePasswordPage />
        </PrivateRoute>
      </Switch>
    </UserAuthentication>
  )
}
