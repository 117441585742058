import { Box as MuiBox, Card, Dialog, MenuItem } from '@material-ui/core'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import Croppie from 'croppie'
import _ from 'lodash'
import 'croppie/croppie.css'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { useLoading } from '../common/hooks/custom.hook'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { deleteAvatar, updateAvatar } from '../store/profile/actions'
import { Profile } from '../store/profile/types'
import {
  Avatar,
  BodyText,
  Button,
  CustomerIcon,
  H2,
  SmallRemoveIcon,
  SmallText
} from '../tmp-ui-element'

import { LoadingComponent } from './index'

type PersonalInfoProps = {
  title?: string
  currProfile: Profile
  selectedStyles?: SelectedStyles
  currEmail: string
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      '& .croppie-container': {
        '& .cr-boundary': {
          width: '100% !important',
          maxWidth: '560px !important'
        }
      },
      '& .uploadAvatarOnMobile': {
        display: 'none',
        /* eslint-disable */
        '@media only screen and (max-width: 780px)': {
          display: 'block' // zIndex: '10 !important',
          // '& .MuiBackdrop-root': {
          //   opacity: '0.8 !important'
          // },
        }
      }
    },

    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {
        '& .MuiDialog-root': {
          display: 'block',
          zIndex: '10 !important',
          '& .MuiBackdrop-root': {
            opacity: '0.8 !important'
          },

          '& .MuiDialog-paperWidthXl': {
            minWidth: '320px'
          }
        },
        '& .croppie-container': {
          '& .cr-boundary': {
            width: '100% !important',
            maxWidth: '350px !important'
          }
        }
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingBottom: '10px',
        paddingTop: '24px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    },
    btnUploadAvatar: {
      '&:focus': {
        outline: 'none !important'
      }
    },
    paperWidthXl: {
      minWidth: '640px',
      minHeight: '565px',
      '@media only screen and (max-width: 780px)': {
        minWidth: '320px'
      }
    },
    paperWidthUploadAvatarOnMobile: {
      '@media only screen and (max-width: 780px)': {
        minWidth: '320px'
      }
    },
    popupCropAvatar: {
      '& .MuiDialog-paperWidthXl': {
        minWidth: '640px',
        minHeight: '565px'
      },
      /* eslint-disable */
      '@media only screen and (max-width: 780px)': {
        // zIndex: '10 !important',
        '& .MuiBackdrop-root': {
          opacity: '0.8 !important'
        },

        '& .MuiDialog-paperWidthXl': {
          minWidth: '320px'
        }
      }
    }
  })
)

const StyledMenu = withStyles({
  paper: {
    minWidth: '256px',
    border: '1px solid #d3d4d5',
    '& ul': {
      minWidth: '192px',
      backgroundColor: '#FFFFFF',
      '& .ant-upload-list': {
        display: 'none'
      },
      '& li': {
        cursor: 'pointer',
        '& .ant-upload-select': {
          width: '100%',
          height: '100%',
          minWidth: '220px'
        },
        '&:focus': {
          backgroundColor: 'transparent'
        },
        '&:hover': {
          backgroundColor: '#EFF2F3'
        }
      }
    }
  },

  /* eslint-disable */
  '@media only screen and (max-width: 780px)': {
    paper: {
      display: 'none'
    }
  }
})((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

const PersonalInfo = (props: PersonalInfoProps): React.ReactElement => {
  const { currProfile, selectedStyles } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [files, setFiles] = useState<any>()
  const { customerId } = useSelector(
    (state: ApplicationState) => state.profile.profile
  )

  const isLoading = useLoading()
  const [openPopUpOnMobile, setOpenPopUpOnMobile] = useState(false)
  const [openPopUpCropAvatar, setOpenPopUpCropAvatar] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [croppie, setCroppie] = useState<Croppie | null>(null)

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopUpOnMobile(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClosePopupCropAvatar = () => {
    setOpenPopUpCropAvatar(false)
  }

  const handleClosePopupOnMobile = () => {
    setOpenPopUpOnMobile(false)
  }

  useEffect(() => {
    if (!_.isEmpty(files)) {
      setOpenPopUpCropAvatar(true)
      setTimeout(() => {
        setOpenPopUpOnMobile(false)
        handleImage()
      }, 500)
    }
  }, [files])

  const onChange = () => {
    handleClose()
  }

  function handleImage() {
    const el = document.getElementById('image-helper')
    if (el) {
      const croppieInstance = new Croppie(el, {
        enableExif: true,
        viewport: {
          height: 300,
          width: 300,
          type: 'circle'
        },
        boundary: {
          height: 350,
          width: 100
        }
      })
      croppieInstance.bind({
        url: files[0].base64
      })
      setCroppie(croppieInstance)
    }
  }

  const handleCropAvatar = () => {
    if (!_.isEmpty(files)) {
      if (croppie !== null) {
        croppie
          .result({
            type: 'base64',
            size: {
              width: 480,
              height: 480
            }
          })
          .then((blob) => {
            let base64Tmp
            base64Tmp = blob ? blob.toString() : ''
            const base64 = _.split(base64Tmp, ',')[1]
            dispatch(
              updateAvatar(
                customerId as number,
                `${files[0].name}`,
                `${base64}`
              )
            )
          })
      }
    }
  }

  const handleUpdateAvatar = () => {
    handleClosePopupOnMobile()
    handleClosePopupCropAvatar()
    handleCropAvatar()
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              base64: reader.result
            })
          )
        )
      }
      reader.readAsDataURL(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  })

  const handleRemoveAvatar = () => {
    setAnchorEl(null)
    handleClosePopupOnMobile()
    dispatch(deleteAvatar(customerId as number))
  }

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false} isLoading={isLoading}>
        <MuiBox className={classes.main}>
          {isLoading ? (
            <>
              <MuiBox pt={4}>
                <LoadingComponent color={selectedStyles?.palette?.primary} />
              </MuiBox>
            </>
          ) : (
            <>
              <MuiBox
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 400
                }}
              >
                {t('Personal info')}
              </MuiBox>

              {/*CART CONTENT*/}
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                py={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                    >
                      <MuiBox>
                        <MuiBox
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                        >
                          <>
                            {currProfile && currProfile.avatar ? (
                              <>
                                <Avatar
                                  backgroundColor="#FFFFFF"
                                  style={{ width: '80px', height: '80px' }}
                                >
                                  <img
                                    alt=""
                                    src={currProfile && currProfile.avatar}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover'
                                    }}
                                  />
                                </Avatar>
                              </>
                            ) : (
                              <Avatar
                                backgroundColor="#FFFFFF"
                                style={{ width: '80px', height: '80px' }}
                              >
                                <CustomerIcon color="#849096" size="normal" />
                              </Avatar>
                            )}
                          </>
                        </MuiBox>
                        <MuiBox pt={1}>
                          <Button
                            color="primary"
                            onClick={handleClickOpen}
                            size="large"
                            style={{
                              borderRadius: '4px',
                              height: '30px',
                              backgroundColor: Boolean(anchorEl)
                                ? '#D2DEE4'
                                : 'transparent'
                            }}
                            variant="text"
                          >
                            <SmallText
                              style={{
                                fontWeight: 600,
                                color: '#039BE5',
                                padding: '0 24px'
                              }}
                            >
                              {t('Change profile photo')}
                            </SmallText>
                          </Button>

                          {/*Dialog upload avatar on desktop*/}
                          <StyledMenu
                            anchorEl={anchorEl}
                            id="customized-menu"
                            keepMounted
                            onClose={handleClose}
                            open={Boolean(anchorEl)}
                            // style={{ backgroundColor: '#FFFFFF' }}
                          >
                            {/*Upload avatar*/}
                            <StyledMenuItem onClick={onChange}>
                              <section
                                className={classes.btnUploadAvatar}
                                onClick={onChange}
                                style={{
                                  cursor: 'pointer',
                                  width: '100%',
                                  height: '100%'
                                }}
                              >
                                <div
                                  className={classes.btnUploadAvatar}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />

                                  <>
                                    <BodyText
                                      className={classes.btnUploadAvatar}
                                    >
                                      {t('Upload new photo')}
                                    </BodyText>
                                  </>
                                </div>
                              </section>
                            </StyledMenuItem>
                            {/*Remove avatar*/}
                            {currProfile && currProfile.avatar && (
                              <StyledMenuItem onClick={handleRemoveAvatar}>
                                <BodyText>{t('Remove current photo')}</BodyText>
                              </StyledMenuItem>
                            )}
                          </StyledMenu>

                          {/*Dialog upload avatar on mobile*/}
                          <Dialog
                            open={openPopUpOnMobile}
                            onClose={handleClosePopupOnMobile}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{
                              paperWidthXl:
                                classes.paperWidthUploadAvatarOnMobile
                            }}
                            className="uploadAvatarOnMobile"
                          >
                            {/*Header*/}
                            <MuiBox
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              style={{ padding: '24px 24px 16px 24px' }}
                            >
                              <MuiBox
                                alignItems="center"
                                display="flex"
                                textAlign="left"
                                width="85%"
                              >
                                <H2>
                                  <>{t('Change profile photo')}</>
                                </H2>
                              </MuiBox>
                              <MuiBox
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                                textAlign="right"
                                width="15%"
                              >
                                <SmallRemoveIcon
                                  onClick={handleClosePopupOnMobile}
                                  style={{ cursor: 'pointer' }}
                                />
                              </MuiBox>
                            </MuiBox>
                            {/*Body*/}
                            <MuiBox>
                              {/*Upload avatar*/}
                              <MuiBox
                                className={classes.btnUploadAvatar}
                                style={{ padding: '8px 24px' }}
                              >
                                <section
                                  className={classes.btnUploadAvatar}
                                  onClick={onChange}
                                  style={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100%'
                                  }}
                                >
                                  <div
                                    className={classes.btnUploadAvatar}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />

                                    <>
                                      <BodyText
                                        className={classes.btnUploadAvatar}
                                      >
                                        {t('Upload new photo')}
                                      </BodyText>
                                    </>
                                  </div>
                                </section>
                              </MuiBox>
                              {/*Remove avatar*/}
                              {currProfile && currProfile.avatar && (
                                <MuiBox style={{ padding: '8px 24px' }}>
                                  <BodyText onClick={handleRemoveAvatar}>
                                    {t('Remove current photo')}
                                  </BodyText>
                                </MuiBox>
                              )}
                            </MuiBox>
                          </Dialog>

                          {/* Crop Avatar*/}
                          <Dialog
                            open={openPopUpCropAvatar}
                            onClose={handleClosePopupCropAvatar}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{ paperWidthXl: classes.paperWidthXl }}
                          >
                            {/*Header*/}
                            <MuiBox
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              style={{ padding: '24px 24px 16px 24px' }}
                            >
                              <MuiBox
                                alignItems="center"
                                display="flex"
                                textAlign="left"
                                width="85%"
                              >
                                <H2>
                                  <>{t('Upload new photo')}</>
                                </H2>
                              </MuiBox>
                              <MuiBox
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                                textAlign="right"
                                width="15%"
                              >
                                <SmallRemoveIcon
                                  onClick={handleClosePopupCropAvatar}
                                  style={{ cursor: 'pointer' }}
                                />
                              </MuiBox>
                            </MuiBox>
                            {/*Body*/}
                            <MuiBox display="flex" justifyContent="center">
                              {/*Upload avatar*/}
                              {!_.isEmpty(files) && (
                                <MuiBox
                                  width="100%"
                                  style={{ height: '70vh', maxHeight: '480px' }}
                                >
                                  <div id="image-helper" />
                                </MuiBox>
                              )}
                            </MuiBox>
                            <MuiBox
                              display="flex"
                              justifyContent="flex-end"
                              pt={4}
                              style={{
                                position: 'absolute',
                                bottom: '32px',
                                right: '32px'
                              }}
                            >
                              <MuiBox pr={2}>
                                <Button
                                  onClick={handleClosePopupCropAvatar}
                                  color="secondary"
                                  variant="outlined"
                                >
                                  {t('Cancel')}
                                </Button>
                              </MuiBox>
                              <MuiBox>
                                <Button
                                  onClick={handleUpdateAvatar}
                                  color="primary"
                                  variant="contained"
                                >
                                  {t('Upload')}
                                </Button>
                              </MuiBox>
                            </MuiBox>
                          </Dialog>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>

              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#849096'
                          }}
                        >
                          {t('Name')}
                        </span>
                        <MuiBox display="flex" justifyContent="center" pt={0.5}>
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px',
                              color: '#849096'
                            }}
                          >
                            {currProfile && currProfile.firstName}{' '}
                            {currProfile && currProfile.lastName}
                          </span>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#849096'
                          }}
                        >
                          {t('Email')}
                        </span>
                        <MuiBox display="flex" justifyContent="center" pt={0.5}>
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px',
                              color: '#849096'
                            }}
                          >
                            {currProfile && currProfile.email}
                          </span>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#849096'
                          }}
                        >
                          {t('Phone number')}
                        </span>
                        <MuiBox display="flex" justifyContent="left" pt={0.5}>
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px',
                              color: '#849096'
                            }}
                          >
                            {currProfile && currProfile?.phone ? (
                              <>{currProfile.phone}</>
                            ) : (
                              <>{t('No info')}</>
                            )}
                          </span>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </>
          )}
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    selectedStyles: state.app.selectedStyles,
    currEmail: state.app.currEmail
  }
}

export default connect(mapStateToProps)(PersonalInfo)
