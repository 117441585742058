import { Box as MuiBox, Card, CardContent } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactCodeInput from 'react-verification-code-input'

import { useLocale } from '../../common/hooks/custom.hook'
import { convertDateTime } from '../../common/utils'
import { SelectedStyles } from '../../store/app/types'
import { Meter, MeterHistory } from '../../store/shopping/types'
import { BodyText, H2, SmallText } from '../../tmp-ui-element'
import { LoadingComponent } from '../index'

type MeterProps = {
  meter: Meter
  enableTMR: (meter: Meter) => void
  showMeterHistory: (
    event: React.MouseEvent<HTMLElement>,
    meterId: number
  ) => void
  loadingMeterHistory: boolean | null
  currentSelectedMeterId: number
  meterHistory: MeterHistory[]
  selectedStyles?: SelectedStyles
}

const useStyles = makeStyles(() =>
  createStyles({
    meterCode: {
      position: 'relative',
      '& div:nth-child(1)': {
        display: 'flex',
        width: 'auto !important',

        '& input': {
          backgroundColor: '#EFF2F3 !important',
          cursor: 'pointer',
          fontFamily: 'inherit',
          marginRight: '4px',
          border: 'none',
          borderRadius: '4px',
          fontSize: '24px',
          fontWeight: 600,
          color: '#222222 !important',
          WebkitTextFillColor: '#222222',
          zIndex: 1,
          opacity: 1 /* required on iOS */,
          padding: 0
        }
      }
    },
    meterCodeHistory: {
      position: 'relative',
      '& div:nth-child(1)': {
        display: 'flex',
        width: 'auto !important',

        '& input': {
          backgroundColor: '#EFF2F3 !important',
          fontFamily: 'inherit',
          marginRight: '4px',
          border: '1px solid #EFF2F3 !important',
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: 600,
          color: '#222222 !important',
          WebkitTextFillColor: '#222222',
          zIndex: 1,
          opacity: 1 /* required on iOS */,
          padding: 0
        }
      }
    },
    line: {
      height: '1px',
      background: '#EFF2F3'
    }
  })
)

const MeterItem = ({
  meter,
  enableTMR,
  showMeterHistory,
  loadingMeterHistory,
  currentSelectedMeterId,
  meterHistory,
  selectedStyles
}: MeterProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const locale = useLocale()

  const formatNumber = (number: number) => {
    return (
      number &&
      number.toLocaleString('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    )
  }

  const convertMeterReading = (value?: string, meterType?: number) => {
    if (!value) {
      if (meterType === 0) {
        return '0000000'.split('')
      } else {
        return '00000'.split('')
      }
    } else {
      let tmpValue = formatNumber(parseInt(value))
      let time = 0

      if (typeof tmpValue === 'string' || tmpValue === 0) {
        if (tmpValue === 0) {
          tmpValue = tmpValue.toString()
        }
        const valueMeterReading = tmpValue.replaceAll('.' || ',', '').split('')

        if (meterType === 0) {
          time = 7 - valueMeterReading.length
        } else if (meterType === 1) {
          time = 5 - valueMeterReading.length
        }
        for (let num = 0; num <= 7; num++) {
          if (num < time) {
            valueMeterReading.unshift('0')
          }
        }

        return valueMeterReading
      }
    }
  }

  return (
    <Card
      className={`card_${meter.id}`}
      style={{ backgroundColor: 'transparent' }}
      variant="outlined"
    >
      <CardContent style={{ backgroundColor: '#ffffff' }}>
        <MuiBox>
          <MuiBox alignItems="center" display="flex">
            <MuiBox textAlign="left" width="30%">
              <H2>{meter?.sector?.name}</H2>
            </MuiBox>
            <MuiBox textAlign="right" width="70%">
              <SmallText>
                {t('Meter number')} <SmallText>{meter?.meterNumber}</SmallText>
              </SmallText>
            </MuiBox>
          </MuiBox>

          {/*Last meter reading*/}
          <MuiBox
            alignItems="center"
            display="flex"
            justifyContent="center"
            pb={2}
            pt={4}
          >
            {meter?.lastMeterReading?.value ? (
              <BodyText>
                {`${t('Last meter reading on')} ${convertDateTime(
                  meter?.lastMeterReading?.readingDate as string,
                  locale,
                  'dd MMM yyyy'
                )}`}
              </BodyText>
            ) : (
              <BodyText>
                {t('Click the digits to update your first meter reading')}
              </BodyText>
            )}
          </MuiBox>

          <MuiBox
            alignItems="center"
            display="flex"
            justifyContent="center"
            pb={4}
          >
            {meter?.sector?.name === 'Power' && meter?.sector?.source === 0 && (
              <MuiBox className={classes.meterCode}>
                <MuiBox
                  onClick={() => enableTMR(meter)}
                  style={{ cursor: 'pointer' }}
                >
                  <ReactCodeInput
                    disabled={true}
                    fieldHeight={48}
                    fieldWidth={36}
                    fields={7}
                    values={convertMeterReading(
                      meter?.lastMeterReading?.value
                        ? meter?.lastMeterReading?.value
                        : '0',
                      0
                    )}
                  />
                </MuiBox>

                <MuiBox
                  style={{
                    fontSize: '12px',
                    position: 'absolute',
                    left: '280px',
                    top: '16px'
                  }}
                >
                  {meter?.lastMeterReading?.unit?.value}
                </MuiBox>
              </MuiBox>
            )}
            {meter?.sector?.name === 'Gas' && meter?.sector?.source === 0 && (
              <MuiBox className={classes.meterCode}>
                <MuiBox
                  onClick={() => enableTMR(meter)}
                  style={{ cursor: 'pointer' }}
                >
                  <ReactCodeInput
                    disabled={true}
                    fieldHeight={48}
                    fieldWidth={36}
                    fields={5}
                    values={convertMeterReading(
                      meter?.lastMeterReading?.value
                        ? meter?.lastMeterReading?.value
                        : '0',
                      1
                    )}
                  />
                </MuiBox>
                <MuiBox
                  style={{
                    fontSize: '12px',
                    position: 'absolute',
                    left: '200px',
                    top: '16px'
                  }}
                >
                  {meter?.lastMeterReading?.unit?.value}
                </MuiBox>
              </MuiBox>
            )}
          </MuiBox>

          <MuiBox>
            <BodyText>
              {meter?.address?.street ? meter?.address?.street : ''}
              {meter?.address?.streetNumber
                ? ' ' + meter?.address?.streetNumber
                : ''}
              {meter?.address?.postcode ? ', ' + meter?.address?.postcode : ''}
              {meter?.address?.city ? ' ' + meter?.address?.city : ''}
            </BodyText>
          </MuiBox>
          <MuiBox pt={0.5}>
            <BodyText>{meter?.package}</BodyText>
          </MuiBox>
        </MuiBox>
      </CardContent>
      {/*History*/}
      {meter?.lastMeterReading?.value ? (
        <MuiBox
          alignItems="center"
          className="accordion"
          display="flex"
          onClick={(evt) => showMeterHistory(evt, meter.id)}
          style={{
            borderTop: '1px solid #D2DEE4',
            backgroundColor: 'transparent',
            padding: 0
          }}
        >
          <MuiBox
            style={{
              backgroundColor: '#FFFFFF',
              zIndex: -1,
              padding: '12px 16px'
            }}
            textAlign="left"
            width="70%"
          >
            <BodyText>{t('Meter reading history')}</BodyText>
          </MuiBox>
          <MuiBox
            style={{
              backgroundColor: '#FFFFFF',
              zIndex: -1,
              padding: '12px 16px',
              minHeight: '48px'
            }}
            textAlign="right"
            width="30%"
          >
            <span className="downBtn">
              <svg
                fill="none"
                height="5"
                viewBox="0 0 8 5"
                width="8"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.25 0.75L4.25 3.75L1.25 0.75"
                  stroke="#222222"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <span className="upBtn">
              <svg
                fill="none"
                height="5"
                viewBox="0 0 8 5"
                width="8"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 3.75L4.25 0.75L7.25 3.75"
                  stroke="#222222"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
          </MuiBox>
        </MuiBox>
      ) : (
        <MuiBox
          alignItems="center"
          className="accordion"
          display="flex"
          style={{
            borderTop: '1px solid #D2DEE4',
            backgroundColor: 'transparent',
            padding: 0,
            cursor: 'inherit'
          }}
        >
          <MuiBox
            style={{
              backgroundColor: '#FFFFFF',
              zIndex: -1,
              padding: '12px 16px',
              color: '#849096'
            }}
            textAlign="left"
            width="70%"
          >
            <BodyText>{t('Meter reading history')}</BodyText>
          </MuiBox>
          <MuiBox
            style={{
              backgroundColor: '#FFFFFF',
              zIndex: -1,
              padding: '12px 16px',
              minHeight: '48px'
            }}
            textAlign="right"
            width="30%"
          >
            <svg
              fill="none"
              height="5"
              viewBox="0 0 8 5"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.25 0.75L4.25 3.75L1.25 0.75"
                stroke="#849096"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </MuiBox>
        </MuiBox>
      )}

      <div
        className={`panel random_id_${meter.id}`}
        id={`random_id_${meter.id}`}
        style={{ maxHeight: '324px', overflow: 'auto' }}
      >
        <MuiBox className="loadingComponent">
          {(loadingMeterHistory === null || loadingMeterHistory) &&
            meter.id === currentSelectedMeterId && (
              <MuiBox py={4}>
                <LoadingComponent color={selectedStyles?.palette?.primary} />
              </MuiBox>
            )}
        </MuiBox>
        {_.map(meterHistory, (history, keyMeterHistory) => (
          <MuiBox key={keyMeterHistory} pb={1}>
            <MuiBox alignItems="center" display="flex" pb={0.5}>
              <MuiBox textAlign="left" width="30%">
                <SmallText>
                  {convertDateTime(history?.readingDate, locale, 'd MMM yyyy')}
                </SmallText>
              </MuiBox>
              <MuiBox textAlign="right" width="70%">
                {meter?.sector?.name === 'Power' &&
                  meter?.sector?.source === 0 && (
                    <MuiBox
                      alignItems="center"
                      className={classes.meterCodeHistory}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ReactCodeInput
                        disabled={true}
                        fieldHeight={28}
                        fieldWidth={20}
                        fields={7}
                        values={convertMeterReading(history?.value, 0)}
                      />
                      {meter?.lastMeterReading?.unit?.value}
                    </MuiBox>
                  )}
                {meter?.sector?.name === 'Gas' && meter?.sector?.source === 0 && (
                  <MuiBox
                    alignItems="center"
                    className={classes.meterCodeHistory}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <ReactCodeInput
                      disabled={true}
                      fieldHeight={28}
                      fieldWidth={20}
                      fields={5}
                      values={convertMeterReading(history?.value, 1)}
                    />
                    {history?.unit?.value}
                  </MuiBox>
                )}
              </MuiBox>
            </MuiBox>
            {history?.difference && (
              <MuiBox
                alignItems="center"
                display="flex"
                justifyContent="flex-end"
                pb={1}
              >
                <SmallText style={{ color: '#849096' }}>
                  + {history?.difference} {history?.unit?.value}
                </SmallText>
              </MuiBox>
            )}
            {!(meterHistory.length - 1 === keyMeterHistory) && (
              <div className={classes.line} />
            )}
          </MuiBox>
        ))}
      </div>
    </Card>
  )
}

export default MeterItem
