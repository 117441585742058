import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductCertificateIcon = createSvgIcon(
  <>
    <title>product certificate</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="certificate"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(4 2)">
          <rect height="28" id="Rectangle" rx="2" width="24" x="0" y="0" />
          <path d="M6 22h13" id="Line-4-Copy-2" strokeLinecap="round" />
          <path d="M6 24h13" id="Line-4" strokeLinecap="round" />
          <path
            d="M9.005 11L7 15.699l2.005-.174L10.153 17 12 12.231"
            id="Line-4-Copy-2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.005 11L12 15.699l2.005-.174L15.153 17 17 12.231"
            id="Line-4-Copy-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="matrix(-1 0 0 1 29 0)"
          />
          <circle cx="12" cy="8" id="Oval" r="4" />
        </g>
      </g>
    </g>
  </>
)
