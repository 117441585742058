import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const NetworkingIcon = createSvgIcon(
  <>
    <title>networking</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="16 12 16 18 11 22"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="16"
        x2="21"
        y1="18"
        y2="22"
      />{' '}
      <circle
        cx="16"
        cy="7"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="7"
        cy="25"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="25"
        cy="25"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
