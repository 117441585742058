export interface TypeColor {
  normal: string
  dark: string
  light: string
  lightest: string
}

export interface TypeGreyColor extends TypeColor {
  lighter: string
}

export interface ColorProps {
  black: string
  white: string
  grey: TypeGreyColor
  blue: TypeColor
  teal: TypeColor
  purple: TypeColor
  orange: TypeColor
  info: TypeColor
  error: TypeColor
  warning: TypeColor
  success: TypeColor
  disabled: TypeColor
}

const createColor = (
  normal: string,
  dark: string,
  light: string,
  lightest: string
): TypeColor => ({
  normal,
  dark,
  light,
  lightest
})

const epilotThemeColor = {
  black: '#222222',
  white: '#FFFFFF',
  grey: {
    ...createColor('#849096', '', '#D2DEE4', '#EFF2F3'),
    lighter: '#E5EBEE'
  },
  blue: createColor('#039BE5', '#01579B', '#67C3EF', '#E5F5FC'),
  teal: createColor('#16BFBE', '#1B8080', '#73D8D8', '#E7F8F8'),
  purple: createColor('#9C27B0', '#6B2577', '#C37DCF', '#F5E9F7'),
  orange: createColor('#FF9800', '#A7690E', '#FFC166', '#FFF4E5'),
  info: createColor('#304FFE', '#28388F', '#ACB8FE', '#F9FAFF'),
  error: createColor('#F44336', '#A0362E', '#F88E86', '#FDECEA'),
  warning: createColor('#FED330', '#E09B0F', '#FEE482', '#FEFAEA'),
  success: createColor('#6BBF16', '#4E801B', '#A6D873', '#F0F8E7'),
  disabled: createColor('#000000', '#849096', '#E5EBEE', '#EFF2F3')
}

const epilotThemeDarkColor = {
  black: '#222222',
  white: '#FFFFFF',
  grey: {
    ...createColor('#849096', '', '#D2DEE4', '#EFF2F3'),
    lighter: '#E5EBEE'
  },
  blue: createColor('#039BE5', '#01579B', '#67C3EF', '#E5F5FC'),
  teal: createColor('#16BFBE', '#1B8080', '#73D8D8', '#E7F8F8'),
  purple: createColor('#9C27B0', '#6B2577', '#C37DCF', '#F5E9F7'),
  orange: createColor('#FF9800', '#A7690E', '#FFC166', '#FFF4E5'),
  info: createColor('#304FFE', '#28388F', '#ACB8FE', '#F9FAFF'),
  error: createColor('#F44336', '#A0362E', '#F88E86', '#FDECEA'),
  warning: createColor('#FED330', '#E09B0F', '#FEE482', '#FEFAEA'),
  success: createColor('#6BBF16', '#4E801B', '#A6D873', '#F0F8E7'),
  disabled: createColor('#E5EBEE', '#849096', '#000000', '#000000')
}

export { epilotThemeColor, epilotThemeDarkColor }
