import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import email from '../assets/images/email.svg'
import failed_email from '../assets/images/failed_mail.svg'
import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import {
  useConfigAmplify,
  useLoading,
  useQuery
} from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { LoadingComponent } from '../components'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import { BodyText, ThemeProvider } from '../tmp-ui-element'

type ActivateAccountProps = {
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222222',
      padding: '16px 0'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

const ActivateAccount = (props: ActivateAccountProps): React.ReactElement => {
  const { selectedStyles } = props
  const mountedRef = useRef(true)
  const { t } = useTranslation()
  const { confirmationCode, userName, orgId } = useQuery() as {
    confirmationCode: string
    userName: string
    orgId: string
  }

  const {
    profile: {
      profile: { language }
    }
  } = useSelector((state: ApplicationState) => state)
  const dispatch = useDispatch()
  const { goToLandingPage } = useNavigation()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isActivateFailed, setIsActivateFailed] = useState(false)
  const isLoading = useLoading()

  useConfigAmplify()

  useEffect(() => {
    if (confirmationCode && userName) {
      try {
        async function confirmSignUp(username: string, code: string) {
          try {
            if (!mountedRef.current) return null
            setIsAuthenticating(true)
            await Auth.confirmSignUp(username, code)
            dispatch(updateProfile({ organizationId: orgId }))
            setIsAuthenticating(false)
          } catch (error) {
            if (!mountedRef.current) return null
            setIsAuthenticating(false)
            setIsActivateFailed(true)
            console.log('Error confirming sign up', error)
          }
        }
        confirmSignUp(userName, confirmationCode).then()
      } catch (error) {
        console.log('Error confirming sign up', error)
      }
    }

    if (!confirmationCode && !userName) {
      goToLandingPage()
    }
  }, [confirmationCode, userName])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const onLanguageChange = (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          {isAuthenticating && isLoading ? (
            <MuiBox pt={4}>
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <Login
              maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
              heightLoginFormOnMobile="93%"
              logoName={selectedStyles?.logo?.name}
              logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
              textColor={selectedStyles?.typography?.primaryColor}
              backgroundColor={selectedStyles?.palette?.primary}
              headerText={
                isActivateFailed ? t('Activation failed') : t('Success!')
              }
              isShowEmailField={false}
              authSigninButtonLabel={
                isActivateFailed ? t('Try again') : t('Log in')
              }
              isShowPasswordField={false}
              isShowRememberMe={false}
              isShowSymbolField={false}
              isValidForm={true}
              languages={[
                { value: 'en', label: 'English' },
                {
                  value: 'de',
                  label: 'Deutsch'
                }
              ]}
              languageValue={language}
              onLanguageChange={(event) => onLanguageChange(event)}
              onSubmitForm={goToLandingPage}
              subHeaderText={
                isActivateFailed ? (
                  <BodyText>{t('Activate account failed')}</BodyText>
                ) : (
                  <BodyText>
                    {t('Your account has been successfully created')}
                  </BodyText>
                )
              }
              titleIcon={
                isActivateFailed ? (
                  <img
                    alt="No results found"
                    height="96"
                    src={failed_email}
                    width="96"
                  />
                ) : (
                  <img
                    alt="No results found"
                    height="96"
                    src={email}
                    width="96"
                  />
                )
              }
            />
          )}
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(ActivateAccount)
