import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UserRecordIcon = createSvgIcon(
  <>
    <title>user record</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="30"
        stroke="#222222"
        strokeMiterlimit="10"
        width="26"
        x="3"
        y="1"
      />
      <circle
        cx="16"
        cy="11"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />
      <path
        d="M16,17a7,7,0,0,0-7,7H23A7,7,0,0,0,16,17Z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
