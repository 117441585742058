import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MapPinIcon = createSvgIcon(
  <>
    <title>map pin</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points="27,7.2 31,6 31,26 21,30 11,26 1,30 1,10 5,8.4 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M23,7.818 C23,12.026,16,19,16,19S9,12.026,9,7.818C9,3.504,12.617,1,16,1S23,3.504,23,7.818z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="8"
        data-color="color-2"
        fill="none"
        r="2"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
