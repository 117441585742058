import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ListBulletIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="4"
        cy="6"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="12"
        x2="31"
        y1="6"
        y2="6"
      />{' '}
      <circle
        cx="4"
        cy="16"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="12"
        x2="31"
        y1="16"
        y2="16"
      />{' '}
      <circle
        cx="4"
        cy="26"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="12"
        x2="31"
        y1="26"
        y2="26"
      />
    </g>
  </>
)
