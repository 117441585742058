import { Box as MuiBox, Card, Grid } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import emptyMeter from '../assets/images/empty-meter.svg'
import { useLoading } from '../common/hooks/custom.hook'
import {
  LoadingComponent,
  Success,
  MeterItem,
  TransmitMeterReading
} from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { Profile } from '../store/profile/types'
import {
  getMeters,
  getMetersHistory,
  transmitMetersReading
} from '../store/shopping/actions'
import { MeterHistory, Meter } from '../store/shopping/types'
import { BodyText, SmallText } from '../tmp-ui-element'

type MeterProps = {
  loadingMeterHistory: boolean | null
  loadingTransmitMetersReading: boolean | null
  errorMessageTMR: string | null
  currProfile: Profile
  meters: Meter[]
  meterHistories: Record<number, MeterHistory[]>
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB',
      '& .accordion': {
        cursor: 'pointer',
        padding: '18px',
        width: '100%',
        border: 'none',
        textAlign: 'left',
        outline: 'none',
        fontSize: '15px',
        transition: ' max-height 0.3s ease-in-out',
        zIndex: 2,
        '& .downBtn': {
          display: 'block'
        },
        '& .upBtn': {
          display: 'none'
        },
        '&:hover': {
          // backgroundColor: '#ccc'
        }
      },
      '& .active': {},
      '& .panel': {
        padding: '0 18px',
        display: 'none',
        backgroundColor: 'white',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out',
        '& .loadingComponent': {
          display: 'block'
        }
      },
      '& .otpInput': {
        backgroundColor: '#EFF2F3 !important',
        width: '36px',
        height: '48px',
        fontSize: '24px',
        textAlign: 'center',
        borderRadius: '4px'
      },
      '& .activeInput': {
        border: 'none',
        '&:focus': {
          outline: 'none',
          border: '1px solid #039BE5 !important'
        }
      },
      '& .errInput': {
        border: '1px solid #F44336 !important',
        '&:focus': {
          outline: 'none',
          border: '1px solid #F44336 !important'
        }
      }
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      padding: '32px',
      paddingTop: '0px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    meterCode: {
      position: 'relative',
      '& div:nth-child(1)': {
        display: 'flex',
        width: 'auto !important',

        '& input': {
          backgroundColor: '#EFF2F3 !important',
          cursor: 'pointer',
          fontFamily: 'inherit',
          marginRight: '4px',
          border: 'none',
          borderRadius: '4px',
          fontSize: '24px',
          fontWeight: 600,
          color: '#222222 !important',
          WebkitTextFillColor: '#222222',
          zIndex: 1,
          opacity: 1 /* required on iOS */,
          padding: 0
        }
      }
    },
    meterCodeHistory: {
      position: 'relative',
      '& div:nth-child(1)': {
        display: 'flex',
        width: 'auto !important',

        '& input': {
          backgroundColor: '#EFF2F3 !important',
          fontFamily: 'inherit',
          marginRight: '4px',
          border: '1px solid #EFF2F3 !important',
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: 600,
          color: '#222222 !important',
          WebkitTextFillColor: '#222222',
          zIndex: 1,
          opacity: 1 /* required on iOS */,
          padding: 0
        }
      }
    },
    line: {
      height: '1px',
      background: '#EFF2F3'
    }
  })
)

const MetersPage = (props: MeterProps): React.ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    currProfile,
    loadingMeterHistory,
    loadingTransmitMetersReading,
    meters,
    meterHistories,
    errorMessageTMR,
    selectedStyles
  } = props

  const isLoading = useLoading()
  const [isTransmitMeterReading, setIsTransmitMeterReading] = useState(false)
  const [currentSelectedMeterId, setCurrentSelectedMeterId] = useState(0)
  const [
    transmitMeterReadingTmp,
    setTransmitMeterReadingTmp
  ] = useState({} as Meter)
  const [
    isValidValueTransmitMeterReading,
    setIsValidValueTransmitMeterReading
  ] = useState(true)
  const [
    isShowBtnSubmitMeterReading,
    setIsShowBtnSubmitMeterReading
  ] = useState(false)
  const [isOpenSuccessMessagePopup, setIsOpenSuccessMessagePopup] = useState(
    false
  )
  const [currentTransmitValue, setCurrentTransmitValue] = useState(0)
  const [isSubmitTMR, setIsSubmitTMR] = useState(false)
  const [isErrorTMR, setIsErrorTMR] = useState(false)
  const [
    currentSuccessTMRId,
    setCurrentSuccessTMRId
  ] = useState<number | null>(null)

  useEffect(() => {
    dispatch(getMeters(currProfile.customerId as number))
  }, [currProfile.customerId, dispatch])

  useLayoutEffect(() => {
    const el = document.getElementsByClassName(
      `card_${currentSuccessTMRId}`
    ) as HTMLCollection
    if (el.length > 0) {
      // @ts-ignore
      el[0].style.border = '1px solid #039BE5'
      // @ts-ignore
      el[0].style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.25)'
      setTimeout(() => {
        // @ts-ignore
        el[0].style.border = '1px solid #D2DEE4'
        // @ts-ignore
        el[0].style.boxShadow = 'none'
        setCurrentSuccessTMRId(null)
      }, 3000)
    }
  })

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        handleCloseNotifySuccess()
      }, 3000)
    }
  }, [isLoading])

  useEffect(() => {
    if (
      loadingTransmitMetersReading === false &&
      isSubmitTMR
    ) {
      setIsErrorTMR(!!errorMessageTMR)
      if (!errorMessageTMR) {
        dispatch(getMeters(currProfile.customerId as number))
        setCurrentSuccessTMRId(transmitMeterReadingTmp?.id)
        setIsSubmitTMR(false)
        handleDisableTMR()
        setIsOpenSuccessMessagePopup(true)
      }
    }
  }, [
    loadingTransmitMetersReading,
    errorMessageTMR,
    currProfile.customerId,
    dispatch
  ])

  const showMeterHistory = (evt: React.MouseEvent, meterId: number) => {
    if (!loadingMeterHistory) {
      setCurrentSelectedMeterId(meterId ? meterId : 0)
      const handleCollapseHistory = (acc: any) => {
        acc.classList.toggle('active')

        let panel = acc.nextElementSibling as HTMLElement

        if (panel?.style?.display === 'block') {
          acc.getElementsByClassName('downBtn')[0].style.display = 'block'
          acc.getElementsByClassName('upBtn')[0].style.display = 'none'
          panel.style.display = 'none'
          // @ts-ignore
          panel.parentNode.style.boxShadow = 'none'
        } else {
          if (!meterHistories[meterId]) {
            dispatch(getMetersHistory(meterId))
          }
          acc.getElementsByClassName('downBtn')[0].style.display = 'none'
          acc.getElementsByClassName('upBtn')[0].style.display = 'block'
          panel.style.display = 'block'
          // @ts-ignore
          panel.parentNode.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.25)'
        }
      }
      handleCollapseHistory(evt.target)
    }
  }

  const handleEnableTMR = (item: Meter) => {
    handleCloseNotifySuccess()
    setIsValidValueTransmitMeterReading(true)
    setIsShowBtnSubmitMeterReading(false)
    setIsTransmitMeterReading(!isTransmitMeterReading)
    setTransmitMeterReadingTmp(item)
  }

  const handleDisableTMR = () => {
    setIsTransmitMeterReading(!isTransmitMeterReading)
    setIsErrorTMR(false)
    setTransmitMeterReadingTmp({} as Meter)
    setIsErrorTMR(false)
  }

  const onChangeMeterReading = (otp: string, length: number) => {
    setCurrentTransmitValue(parseInt(otp))
    const currValue = otp.split('')
    if (currValue.length === length) {
      let meterReadingCurr = transmitMeterReadingTmp?.lastMeterReading?.value || 0
      if (parseInt(meterReadingCurr as string) - parseInt(otp) < 0) {
        setIsValidValueTransmitMeterReading(true)
        setIsShowBtnSubmitMeterReading(true)
      } else {
        setIsValidValueTransmitMeterReading(false)
        setIsShowBtnSubmitMeterReading(false)
      }
    } else {
      setIsValidValueTransmitMeterReading(true)
      setIsShowBtnSubmitMeterReading(false)
    }
  }

  const handleTransmitMeterReading = () => {
    const author = currProfile?.firstName + currProfile?.lastName
    dispatch(
      transmitMetersReading(
        transmitMeterReadingTmp.id,
        currentTransmitValue,
        transmitMeterReadingTmp?.lastMeterReading?.unit?.id as unknown as number,
        author
      )
    )
    setIsSubmitTMR(true)
  }

  const handleCloseNotifySuccess = () => {
    setIsOpenSuccessMessagePopup(false)
  }

  return (
    <>
      <GlobalCss />
      <MainLayout isLoading={isLoading}>
        <>
          <MuiBox
            className={classes.main}
            style={{
              paddingTop: `${isOpenSuccessMessagePopup ? '56px' : '24px'}`
            }}
          >
            {isLoading ? (
              <MuiBox pt={4}>
                <LoadingComponent color={selectedStyles?.palette?.primary} />
              </MuiBox>
            ) : (
              <>
                <MuiBox>
                  <Success
                    isOpen={isOpenSuccessMessagePopup}
                    onClose={handleCloseNotifySuccess}
                  >
                    <BodyText style={{ color: '#FFFFFF' }}>
                      {t('Your meter reading was successfully transmitted')}
                    </BodyText>
                  </Success>
                </MuiBox>
                {isTransmitMeterReading || isErrorTMR
                  ? (
                    <TransmitMeterReading
                      loadingTransmitMetersReading={loadingTransmitMetersReading}
                      isErrorTMR={isErrorTMR}
                      isShowBtnSubmitMeterReading={isShowBtnSubmitMeterReading}
                      isValidValueTransmitMeterReading={isValidValueTransmitMeterReading}
                      onChangeMeterReading={onChangeMeterReading}
                      handleTransmitMeterReading={handleTransmitMeterReading}
                      transmitMeterReadingData={transmitMeterReadingTmp}
                      handleDisableTMR={handleDisableTMR}
                      selectedStyles={selectedStyles}
                    />
                  ) : (
                    <>
                      <MuiBox
                        pb={3}
                        style={{ fontSize: '24px', lineHeight: '32px' }}
                      >
                        {t('Meters')}
                      </MuiBox>
                      {!_.isEmpty(meters) ? (
                        <Grid container spacing={2} style={{ display: 'flex' }}>
                          {_.map(meters, (item, key) => (
                            <Grid item lg={4} md={6} sm={12} xs={12} key={key}>
                              <MeterItem
                                showMeterHistory={showMeterHistory}
                                currentSelectedMeterId={currentSelectedMeterId}
                                meter={item}
                                loadingMeterHistory={loadingMeterHistory}
                                meterHistory={meterHistories[item.id]}
                                enableTMR={handleEnableTMR}
                                selectedStyles={selectedStyles}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Card variant="outlined">
                          <MuiBox p={3}>
                            <MuiBox
                              alignItems="center"
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                            >
                              <img alt="No results found" src={emptyMeter} />
                            </MuiBox>
                            <MuiBox
                              alignItems="center"
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              pt={3}
                            >
                              <BodyText>{t('You have no meters')}</BodyText>
                            </MuiBox>
                            <MuiBox
                              alignItems="center"
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              pt={1}
                            >
                              <SmallText
                                style={{
                                  color: '#849096',
                                  textAlign: 'center'
                                }}
                              >
                                {t(
                                  'The data will be displayed when it is available'
                                )}
                              </SmallText>
                            </MuiBox>
                          </MuiBox>
                        </Card>
                      )}
                    </>
                  )
                }
              </>
            )}
          </MuiBox>
        </>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    loadingMeterHistory: state.shopping.loadingMeterHistory,
    loadingTransmitMetersReading: state.shopping.loadingTransmitMetersReading,
    meters: state.shopping.meters,
    meterHistories: state.shopping.meterHistories,
    errorMessageTMR: state.shopping.errorMessageTMR,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(MetersPage)
