import MuiDrawer, {
  DrawerProps as MuiDrawerProps
} from '@material-ui/core/Drawer'
import React from 'react'

export interface DrawerProps extends MuiDrawerProps {
  children?: React.ReactElement
}

export const Drawer = (props: DrawerProps): React.ReactElement => {
  const { children } = props

  return <MuiDrawer {...props}>{children}</MuiDrawer>
}
