import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UserIcon = createSvgIcon(
  <>
    <title>user</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M2,31L2,31 c0-7.732,6.268-14,14-14h0c7.732,0,14,6.268,14,14v0"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="9"
        fill="none"
        r="8"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
