import { Box as MuiBox, Card, Dialog, Grid } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import useNavigation from '../common/hooks/navigation.hook'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { deleteUserAccount } from '../store/profile/actions'
import {
  Avatar,
  BodyText,
  Button,
  CustomerIcon,
  H2,
  LanguageIcon,
  NewsletterIcon,
  PadlockIcon,
  PinIcon,
  SmallRemoveIcon
} from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB',
      '& .MuiDialog-paperWidthXl': {
        maxWidth: '320px'
      }
    },
    '.MuiButton-outlined': {
      padding: '6px 16px'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    cardWrapper: {
      flexGrow: 1,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EFF2F3'
      }
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto',
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const MyProfilePage = (): React.ReactElement => {
  const {
    profile: { loadingDeleteUserAccount, profile: currProfile }
  } = useSelector((state: ApplicationState) => state)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isShowConfirmDeleteModal, setIsShowConfirmDeleteModal] = useState(
    false
  )
  const classes = useStyles()
  const {
    goToChangePasswordPage,
    goToProfileDetailPage,
    goToLanguagePage,
    goToSubscriptionPage,
    goToAddressPage
  } = useNavigation()

  const toggleShowConfirmDeleteModal = () => {
    setIsShowConfirmDeleteModal(!isShowConfirmDeleteModal)
  }

  const handleDeleteAccount = async () => {
    dispatch(deleteUserAccount({ history }))
  }

  return (
    <>
      <GlobalCss />
      <MainLayout>
        <MuiBox className={classes.main}>
          <MuiBox
            style={{
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 400
            }}
          >
            {t('My profile')}
          </MuiBox>

          {/*INFORMATION*/}
          <MuiBox
            alignItems="center"
            display="flex"
            flexDirection="row"
            onClick={goToProfileDetailPage}
            pb={2}
            pt={3}
          >
            <Card className={classes.cardWrapper} variant="outlined">
              <MuiBox p={2}>
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  minHeight="96px"
                  width="100%"
                >
                  <MuiBox alignItems="center" display="flex">
                    {currProfile?.avatar ? (
                      <>
                        <Avatar
                          backgroundColor="#FFFFFF"
                          style={{ width: '80px', height: '80px' }}
                        >
                          <img
                            alt=""
                            src={currProfile.avatar}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                          {/*<img alt="" src={`data:image/png;base64,${currProfile && currProfile.avatar}`} />*/}
                        </Avatar>
                      </>
                    ) : (
                      <Avatar
                        backgroundColor="#FFFFFF"
                        style={{ width: '80px', height: '80px' }}
                      >
                        <CustomerIcon color="#849096" size="normal" />
                      </Avatar>
                    )}
                    {/*<Avatar bacclassName={classes.size} />*/}
                  </MuiBox>
                  <MuiBox pl={2}>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: '18px',
                          lineHeight: '24px'
                        }}
                      >
                        {currProfile?.firstName} {currProfile?.lastName}
                      </span>
                    </MuiBox>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      pt={2}
                    >
                      <BodyText>{currProfile?.email}</BodyText>
                    </MuiBox>
                    {currProfile?.phone && (
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        pt={1}
                      >
                        <BodyText>
                          {/*+1 (738) 938 268*/}
                          {currProfile?.phone}
                        </BodyText>
                      </MuiBox>
                    )}
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            </Card>
          </MuiBox>

          {/*CART CONTENT*/}
          <Grid container spacing={1}>
            <Grid item md={3} sm={6} xs={6}>
              <MuiBox
                alignItems="center"
                display="inline-flex"
                onClick={goToAddressPage}
                width="100%"
              >
                <Card className={classes.cardWrapper} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex" height="80px">
                      <MuiBox>
                        <Avatar
                          backgroundColor="#F0F8E7"
                          style={{
                            width: '40px',
                            height: '40px',
                            border: 'none'
                          }}
                        >
                          <PinIcon color="#6BBF16" size="small" />
                        </Avatar>
                        <MuiBox pt={2}>{t('Addresses')}</MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <MuiBox
                alignItems="center"
                display="inline-flex"
                onClick={goToChangePasswordPage}
                width="100%"
              >
                <Card className={classes.cardWrapper} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex" height="80px">
                      <MuiBox>
                        <Avatar
                          backgroundColor="#EFF2F3"
                          style={{
                            width: '40px',
                            height: '40px',
                            border: 'none'
                          }}
                        >
                          <PadlockIcon color="#222222" size="small" />
                        </Avatar>
                        <MuiBox pt={2}>{t('Password')}</MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <MuiBox
                alignItems="center"
                display="inline-flex"
                onClick={goToLanguagePage}
                width="100%"
              >
                <Card className={classes.cardWrapper} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex" height="80px">
                      <MuiBox>
                        <Avatar
                          backgroundColor="#F5E9F7"
                          style={{
                            width: '40px',
                            height: '40px',
                            border: 'none'
                          }}
                        >
                          <LanguageIcon color="#9C27B0" size="small" />
                        </Avatar>
                        <MuiBox pt={2}>{t('Language')}</MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <MuiBox
                alignItems="center"
                display="inline-flex"
                onClick={goToSubscriptionPage}
                width="100%"
              >
                <Card className={classes.cardWrapper} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex" height="80px">
                      <MuiBox>
                        <Avatar
                          backgroundColor="#FFF4E5"
                          style={{
                            width: '40px',
                            height: '40px',
                            border: 'none'
                          }}
                        >
                          <NewsletterIcon color="#FF9800" size="small" />
                        </Avatar>
                        <MuiBox pt={2}>{t('Subscription')}</MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </Grid>
          </Grid>

          {/*  DELETE ACCOUNT */}
          <Grid item md={4} sm={12} xs={12}>
            <MuiBox pt={2} style={{ bottom: 0, position: 'sticky' }}>
              <Button
                color="secondary"
                fullWidth
                onClick={toggleShowConfirmDeleteModal}
                variant="outlined"
                style={{ height: '48px' }}
              >
                <span style={{ color: '#F44336' }}>
                  {t('Delete my account')}
                </span>
              </Button>
            </MuiBox>
          </Grid>
          <Dialog
            open={isShowConfirmDeleteModal}
            onClose={toggleShowConfirmDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/*Header*/}
            <MuiBox
              display="flex"
              flexDirection="row"
              width="100%"
              style={{ padding: '24px 24px 16px 24px' }}
            >
              <MuiBox
                alignItems="center"
                display="flex"
                textAlign="left"
                width="85%"
              >
                <H2>
                  <>{t('Delete your account?')}</>
                </H2>
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                justifyContent="flex-end"
                textAlign="right"
                width="15%"
              >
                <SmallRemoveIcon
                  onClick={toggleShowConfirmDeleteModal}
                  style={{ cursor: 'pointer' }}
                />
              </MuiBox>
            </MuiBox>
            {/*Body*/}
            <MuiBox style={{ padding: '0 24px 24px 24px' }}>
              <BodyText>
                {t(
                  'Are you sure you want to delete your account? By deleting your account, you will no longer have access to the portal'
                )}
              </BodyText>
              <MuiBox display="flex" justifyContent="flex-end" pt={4}>
                <MuiBox pr={2}>
                  <Button
                    onClick={toggleShowConfirmDeleteModal}
                    color="secondary"
                    variant="outlined"
                  >
                    {t('Cancel')}
                  </Button>
                </MuiBox>
                <Button
                  isLoading={loadingDeleteUserAccount}
                  disabled={loadingDeleteUserAccount}
                  color="secondary"
                  variant="contained"
                  onClick={handleDeleteAccount}
                  style={{ minWidth: 80, padding: 0 }}
                >
                  {t('Delete')}
                </Button>
              </MuiBox>
            </MuiBox>
          </Dialog>
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    currEmail: state.app.currEmail
  }
}

export default connect(mapStateToProps)(MyProfilePage)
