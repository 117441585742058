import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const TimelineIcon = createSvgIcon(
  <>
    <title>timeline</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      {' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="1"
        y2="31"
      />{' '}
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        width="8"
        x="23"
        y="3"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="19"
        x2="16"
        y1="7"
        y2="7"
      />{' '}
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        width="8"
        x="23"
        y="21"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="19"
        x2="16"
        y1="25"
        y2="25"
      />{' '}
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        transform="translate(10 32) rotate(-180)"
        width="8"
        x="1"
        y="12"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="13"
        x2="16"
        y1="16"
        y2="16"
      />{' '}
    </g>
  </>
)
