import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductThermocheckIcon = createSvgIcon(
  <>
    <title>product thermocheck</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="thermocheck"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(8 2)">
          <path
            d="M2 0h12a2 2 0 0 1 2 2v8.564a2 2 0 0 1-.256.978L11 20H5L.256 11.542A2 2 0 0 1 0 10.564V2a2 2 0 0 1 2-2z"
            id="Path-60"
          />
          <path
            d="M5 20v7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-7"
            id="Path-61"
            transform="matrix(-1 0 0 1 16 0)"
          />
          <path
            d="M3.125 2H13a1 1 0 0 1 1 1v6.802a2 2 0 0 1-.247.964l-1.21 2.198A2 2 0 0 1 10.792 14H5.203a2 2 0 0 1-1.754-1.039l-1.194-2.18a2 2 0 0 1-.246-.995l.116-6.803a1 1 0 0 1 1-.983z"
            id="Path-62"
            transform="matrix(-1 0 0 1 16 0)"
          />
        </g>
      </g>
    </g>
  </>
)
