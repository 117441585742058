import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PropertyLocationIcon = createSvgIcon(
  <>
    <title>property location</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="1 14 16 2 31 14"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />
      <polyline
        fill="none"
        points="5 16 5 30 18 30"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <circle
        cx="24"
        cy="21"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        r="1"
        stroke="none"
        strokeLinecap="butt"
      />
      <path
        d="M30,20.844C30,24.451,24,30,24,30s-6-5.549-6-9.156A5.874,5.874,0,0,1,24,15,5.874,5.874,0,0,1,30,20.844Z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
