import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductWatercheckIcon = createSvgIcon(
  <>
    <title>product watercheck</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="watercheck"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g
          id="Group"
          stroke="#222222"
          strokeLinecap="round"
          transform="translate(6 2)"
        >
          <path
            d="M10 0c-.334 0-.415 1.75-.734 2.57-.213.548-.573 1.277-1.08 2.187A35.789 35.789 0 0 1 6.8 6.981c-.398.579-1.042 1.421-1.932 2.527-.805 1.079-1.4 1.893-1.787 2.444a28.658 28.658 0 0 0-1.364 2.119C1.446 14.552 0 16.399 0 18.958c0 2.56.963 3.971 1.895 5.142.931 1.171 2.974 2.723 4.117 3.211C7.154 27.8 8.254 28 10 28s2.851-.227 3.921-.689c1.07-.462 2.805-1.61 4.162-3.21 1.356-1.602 2.093-3.339 1.88-5.416-.211-2.076-.914-3.454-1.664-4.614-.75-1.16-1.105-1.724-1.717-2.539a1365.1 1365.1 0 0 1-1.515-2.024c-.833-1.046-1.432-1.836-1.795-2.37a96.757 96.757 0 0 1-1.548-2.38c-.482-.885-.821-1.597-1.018-2.138C10.412 1.809 10.334 0 10 0z"
            id="Path-24"
            strokeLinejoin="round"
          />
          <path
            d="M4.5 18.541l2.358 3.327a.2.2 0 0 0 .284.044l8.358-6.305"
            id="Path-41"
          />
        </g>
      </g>
    </g>
  </>
)
