import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core'
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps
} from '@material-ui/core/Checkbox'
import MuiFormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export const Checkbox = ({
  label,
  labelPlacement,
  color,
  className,
  checked,
  boxed,
  error,
  checkboxClassName,
  checkboxControlLabelClassName,
  ...rest
}: MuiCheckboxProps & {
  label?: React.ReactElement | string
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom'
  boxed?: boolean
  error?: string
  checkboxControlLabelClassName?: string
  checkboxClassName?: string
}): React.ReactElement => {
  const useStyles = makeStyles((theme) => ({
    container: {
      textAlign: 'center',
      borderRadius: theme?.shape?.borderRadius,
      padding: '16px 44px',
      margin: '0',
      color: '#222222',
      display: 'inline-flex',
      border: checked
        ? '1px solid ' + theme?.palette?.primary?.main
        : '1px solid #D2DEE4'
    },
    checkbox: {
      color: error != null ? '#f44336' : 'initial'
    }
  }))
  const classes = useStyles()
  const labelClassName = boxed
    ? `${className} ${classes.container}`
    : `${className} ${checkboxControlLabelClassName}`

  return (
    <>
      <MuiFormControlLabel
        className={labelClassName}
        control={
          <MuiCheckbox
            {...rest}
            checked={checked}
            className={
              checkboxClassName
                ? checkboxClassName
                : checkboxClassName + ' ' + classes.checkbox
            }
            color={color ? color : 'primary'}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {error != null && (
        <MuiFormHelperText error id="checkbox--helper-text">
          {error}
        </MuiFormHelperText>
      )}
    </>
  )
}
