import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PinIcon = createSvgIcon(
  <>
    <title>pin</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M27,12c0,6.8-11,18-11,18 S5,18.9,5,12c0-7,5.7-11,11-11S27,5.1,27,12z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="12"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
