import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ShareIcon = createSvgIcon(
  <>
    <title>share</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points=" 27,21 27,30 2,30 2,7 9,7 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M30,12L20,3v5 C13.1,8.3,8,10.6,8,21c2.4-3.2,5.1-5.1,12-5v5L30,12z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
