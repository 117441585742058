import { Box as MuiBox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, {
  ChangeEvent,
  MouseEventHandler,
  ReactElement,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useNavigation from '../common/hooks/navigation.hook'
import { ApplicationState } from '../store'
import { updateProfile, updateProfileState } from '../store/profile/actions'
import {
  H2,
  H3,
  ArrowLeftIcon,
  EpilotLogoIcon,
  EpilotWhiteLogoIcon,
  Select,
  MenuItem,
  BodyText,
  RemoveIcon,
  CircleRemoveIcon
} from '../tmp-ui-element'

interface ArrayType {
  value: string
  label: string
}
export interface ContainerProps {
  children?: ReactElement | ReactElement[]
  onNavigateBack?: MouseEventHandler
  showBackIcon?: boolean
  hideLogo?: boolean
  headerText?: string
  subHeaderText?: string | React.ReactElement
  hideLanguageDropdown?: boolean
  onLanguageChange?: (event: ChangeEvent) => void
  languages?: ArrayType[]
  languageValue?: string
  companySlogan?: string
  backgroundColor?: string
  logoName?: string
  logoUrl?: string
  maxHeightLoginFormOnMobile?: string
  heightLoginFormOnMobile?: string
  termsText?: string | React.ReactElement
  titleIcon?: string | React.ReactElement
}

export const Container = (props: ContainerProps): ReactElement => {
  const {
    onNavigateBack,
    showBackIcon,
    hideLogo,
    headerText,
    subHeaderText,
    hideLanguageDropdown,
    onLanguageChange = () => ({}),
    languages,
    languageValue,
    companySlogan,
    termsText,
    titleIcon,
    backgroundColor = '#039BE5',
    logoName,
    logoUrl,
    maxHeightLoginFormOnMobile = '',
    heightLoginFormOnMobile = ''
  } = props
  const lang: string = languageValue ? languageValue : 'de'
  const [language, setLanguage] = useState<string>(lang)
  const { goToLandingPage } = useNavigation()
  const dispatch = useDispatch()
  const { isShowDeleteUserAccountMessage } = useSelector(
    (state: ApplicationState) => state.profile
  )

  const useStyles = makeStyles((theme) => ({
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      padding: '24px'
    },
    itemContainer: {
      position: 'relative',
      display: hideLogo ? 'none' : 'inherit'
    },
    backIcon: {
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0'
    },
    logoContainer: {
      flexBasis: '384px',
      textAlign: 'left',
      // backgroundColor: '#039BE5',
      padding: '46px 32px',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        display: 'none'
      }
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100%'
    },
    mobileLogo: {
      display: 'none',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        display: 'block',
        marginBottom: '20px',
        height: '56px'
      }
    },
    formContainer: {
      position: 'relative',
      flexGrow: 2,
      textAlign: 'center'
    },
    container: {
      maxHeight: '352px',
      height: '100%',
      width: '408px',
      display: 'table',
      flexGrow: 3,
      // padding: '16px',
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        width: '100%',
        display: 'none',
        backgroundColor: ''
      }
    },
    containerOnMobile: {
      height: '100%',
      width: '408px',
      display: 'none',
      flexGrow: 3,
      margin: '0',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        width: '100%',
        display: 'block'
      }
    },
    dropdown: {
      display: 'none',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        display: 'block',
        width: '100%'
      }
    },
    errorMessageContainer: {
      padding: '4px 8px',
      border: '1px solid #F88E86',
      boxSizing: 'border-box',
      backgroundColor: '#FDECEA',
      borderRadius: 4,
      ['@media (max-width:780px)']: {
        width: '100%',
        display: 'none',
        backgroundColor: ''
      }
    },
    errorMessageContainerMobile: {
      padding: '4px 8px',
      border: '1px solid #F88E86',
      boxSizing: 'border-box',
      backgroundColor: '#FDECEA',
      borderRadius: 4,
      ['@media (max-width:780px)']: {
        paddingTop: `4px !important`
      }
    }
  }))
  const classes = useStyles(props)

  const renderDropdownElement = () => {
    if (languages != null) {
      return languages.map((language, i) => {
        return (
          <MenuItem key={'dropdown-item-' + i} value={language.value}>
            {language.label}
          </MenuItem>
        )
      })
    }
    return <></>
  }

  const renderErrorMessage = () => {
    return (
      <>
        <MuiBox display="flex" alignItems="center">
          <CircleRemoveIcon
            style={{ width: 16, height: 16, marginRight: 8 }}
            color={'#A0362E'}
            strokeWidth={3}
          />
          <BodyText>Your account has been deleted. </BodyText>
        </MuiBox>

        <RemoveIcon
          style={{
            width: 12,
            height: 12,
            cursor: 'pointer'
          }}
          color={'#A0362E'}
          strokeWidth={3}
          onClick={() =>
            dispatch(
              updateProfileState({ isShowDeleteUserAccountMessage: false })
            )
          }
        />
      </>
    )
  }

  return (
    <div className={classes.mainContainer}>
      <div
        className={classes.logoContainer}
        style={{
          backgroundColor: backgroundColor
        }}
      >
        {logoUrl ? (
          <img
            alt={logoName}
            src={logoUrl}
            style={{
              maxHeight: '40px',
              maxWidth: '120px',
              marginBottom: '24px',
              cursor: 'pointer'
            }}
            onClick={() => {
              goToLandingPage()
            }}
          />
        ) : (
          <EpilotWhiteLogoIcon
            style={{
              height: '62px',
              width: '120px',
              marginBottom: '24px',
              cursor: 'pointer'
            }}
            viewBox="0 0 282 32"
            onClick={goToLandingPage}
          />
        )}
        <H2 customColor="#ffffff">{companySlogan}</H2>
        {!hideLanguageDropdown && (
          <div style={{ position: 'absolute', bottom: '20px', left: '20px' }}>
            <Select
              IconComponent={() => (
                <span
                  style={{
                    display: 'inline-flex',
                    paddingRight: '12px !important',
                    zIndex: -1,
                    position: 'absolute',
                    top: '7.4px',
                    right: '12px'
                  }}
                >
                  <svg
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.25 0.75L4.25 3.75L1.25 0.75"
                      stroke="#222222"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
              onChange={(event: any) => {
                onLanguageChange(event)
                setLanguage(event.target.value)
              }}
              style={{ width: '200px', backgroundColor: 'white', zIndex: 1 }}
              value={language}
            >
              {renderDropdownElement()}
            </Select>
          </div>
        )}
      </div>

      <div className={classes.formContainer}>
        {isShowDeleteUserAccountMessage && (
          <MuiBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            m={4}
            className={classes.errorMessageContainer}
          >
            {renderErrorMessage()}
          </MuiBox>
        )}
        <MuiBox className={classes.container}>
          <div
            className={classes.mobileLogo}
            style={{
              backgroundColor: `${backgroundColor}`
            }}
          >
            {logoUrl ? (
              <MuiBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ height: '56px' }}
              >
                <img
                  alt={logoName}
                  src={logoUrl}
                  style={{
                    maxHeight: '30px',
                    maxWidth: '100px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    goToLandingPage()
                  }}
                />
              </MuiBox>
            ) : (
              <EpilotWhiteLogoIcon
                style={{ height: '56px', width: '100px', cursor: 'pointer' }}
                viewBox="0 0 282 80"
                onClick={() => {
                  goToLandingPage()
                }}
              />
            )}
          </div>
          <div className={classes.box}>
            <MuiBox style={{ minWidth: 360 }}>
              <div className={classes.itemContainer}>
                {showBackIcon ? (
                  <ArrowLeftIcon
                    size="small"
                    onClick={onNavigateBack}
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      bottom: '0',
                      cursor: 'pointer'
                    }}
                  />
                ) : null}
                {titleIcon}
                {headerText != null ? (
                  <>
                    <H2>{headerText}</H2>
                    <H3>{subHeaderText}</H3>
                  </>
                ) : (
                  <EpilotLogoIcon
                    style={{ height: '50px', width: '100px' }}
                    viewBox="0 0 282 32"
                  />
                )}
              </div>
              {props.children}
            </MuiBox>
          </div>
          {termsText}
        </MuiBox>
        <MuiBox className={classes.containerOnMobile}>
          <MuiBox
            style={{
              maxHeight: `${maxHeightLoginFormOnMobile}`,
              height: `${heightLoginFormOnMobile}`
            }}
          >
            <div
              className={classes.mobileLogo}
              style={{
                backgroundColor: `${backgroundColor}`
              }}
            >
              {logoUrl ? (
                <MuiBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '56px' }}
                >
                  <img
                    alt={logoName}
                    src={logoUrl}
                    style={{
                      maxHeight: '30px',
                      maxWidth: '100px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      goToLandingPage()
                    }}
                  />
                </MuiBox>
              ) : (
                <EpilotWhiteLogoIcon
                  style={{ height: '56px', width: '100px', cursor: 'pointer' }}
                  viewBox="0 0 282 80"
                  onClick={() => {
                    goToLandingPage()
                  }}
                />
              )}
            </div>

            <div className={classes.box}>
              {isShowDeleteUserAccountMessage && (
                <MuiBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.errorMessageContainerMobile}
                  mb={4}
                >
                  {renderErrorMessage()}
                </MuiBox>
              )}
              <MuiBox>
                <div className={classes.itemContainer}>
                  {showBackIcon ? (
                    <ArrowLeftIcon
                      size="small"
                      onClick={onNavigateBack}
                      style={{
                        margin: 'auto',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        bottom: '0',
                        cursor: 'pointer'
                      }}
                    />
                  ) : null}
                  {titleIcon}
                  {headerText != null ? (
                    <>
                      <H2>{headerText}</H2>
                      <H3>{subHeaderText}</H3>
                    </>
                  ) : (
                    <EpilotLogoIcon
                      style={{ height: '50px', width: '100px' }}
                      viewBox="0 0 282 32"
                    />
                  )}
                </div>
                {props.children}
              </MuiBox>
            </div>
            {termsText}
          </MuiBox>
          {!hideLanguageDropdown && (
            <div className={classes.dropdown}>
              <Select
                IconComponent={() => (
                  <span style={{ display: 'inline-flex', paddingRight: '8px' }}>
                    <svg
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.25 0.75L4.25 3.75L1.25 0.75"
                        stroke="#222222"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                )}
                onChange={(event: any) => {
                  onLanguageChange(event)
                  setLanguage(event.target.value)
                }}
                style={{ width: '94px', backgroundColor: 'white', zIndex: 1 }}
                value={language}
              >
                {renderDropdownElement()}
              </Select>
            </div>
          )}
        </MuiBox>
      </div>
    </div>
  )
}
