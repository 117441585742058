import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const UserEditIcon = createSvgIcon(
  <>
    <title>user edit</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M18,20.209 C16.798,20.078,15.466,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h12"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polygon
        data-color="color-2"
        fill="none"
        points=" 21,31 18,31 18,28 27,19 30,22 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
