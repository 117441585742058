import { Box as MuiBox, Card } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { NewPassword, Success } from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { changePassword, updateProfileState } from '../store/profile/actions'
import { BodyText, PenIcon, SmallText } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    },
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      flexGrow: 1,
      padding: '24px'
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto',
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    changePasswordContainer: {
      width: '350px',
      ['@media (max-width:780px)']: {
        width: '100%'
      }
    }
  })
)

type ChangePasswordProps = {
  selectedStyles?: SelectedStyles
}

const ChangePasswordPage = (props: ChangePasswordProps): React.ReactElement => {
  const { selectedStyles } = props
  const dispatch = useDispatch()
  const [isShowChangePassword, setIsShowChangePassword] = useState(false)
  const { isShowChangePasswordSuccessMessage } = useSelector(
    (state: ApplicationState) => state.profile
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const handleChangePassword = ({
    currentPassword,
    newPassword
  }: {
    currentPassword: string
    newPassword: string
  }) => {
    dispatch(
      changePassword({
        currentPassword,
        newPassword,
        history
      })
    )
  }

  useEffect(() => {
    if (isShowChangePasswordSuccessMessage) {
      setIsShowChangePassword(false)
    }
  }, [isShowChangePasswordSuccessMessage])

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false}>
        <>
          <Success
            isOpen={isShowChangePasswordSuccessMessage}
            onClose={() =>
              dispatch(
                updateProfileState({
                  isShowChangePasswordSuccessMessage: false
                })
              )
            }
          >
            <BodyText style={{ color: '#FFFFFF' }}>
              {t('Password has been changed successfully')}
            </BodyText>
          </Success>
          <MuiBox className={classes.main}>
            <MuiBox
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{
                fontSize: '24px',
                lineHeight: '32px',
                fontWeight: 400
              }}
            >
              {isShowChangePassword ? t('Change password') : t('Password')}
              {isShowChangePassword && (
                <MuiBox>
                  <SmallText
                    onClick={() => setIsShowChangePassword(false)}
                    style={{
                      fontWeight: 600,
                      color: '#039BE5',
                      cursor: 'pointer'
                    }}
                  >
                    {t('Cancel')}
                  </SmallText>
                </MuiBox>
              )}
            </MuiBox>

            {isShowChangePassword ? (
              <>
                {/*CHANGE PASSWORD*/}
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  pt={2}
                  width="100%"
                >
                  <Card className={classes.cardRoot} variant="outlined">
                    <MuiBox pb={1} width="100%">
                      <BodyText>
                        {t('Confirm your current password and set a new one')}
                      </BodyText>
                    </MuiBox>
                    <MuiBox className={classes.changePasswordContainer}>
                      <NewPassword
                        handleChangePassword={handleChangePassword}
                        selectedStyles={selectedStyles}
                      />
                    </MuiBox>
                  </Card>
                </MuiBox>
              </>
            ) : (
              <>
                {/*CONTENT*/}
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  py={2}
                  width="100%"
                >
                  <Card className={classes.cardRoot} variant="outlined">
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <BodyText>{t('Password')}</BodyText>
                      <PenIcon
                        color={'#039BE5'}
                        onClick={() => setIsShowChangePassword(true)}
                        style={{ width: 18, height: 18, cursor: 'pointer' }}
                      />
                    </MuiBox>
                    <MuiBox>
                      <SmallText
                        style={{
                          fontWeight: 800
                        }}
                      >
                        ··········
                      </SmallText>
                    </MuiBox>
                  </Card>
                </MuiBox>
              </>
            )}
          </MuiBox>
        </>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(ChangePasswordPage)
