import { Reducer } from 'redux'

import { ShoppingActionTypes, ShoppingState } from './types'

const initialState: ShoppingState = {
  token: {},
  loadingOrderIdSelection: false,
  loadingOrderDetailItem: false,
  loadingPricingDetailItem: false,
  loadingOrderAddresses: false,
  loadingStatus: false,
  loadingOrders: false,
  loadingMeters: null,
  loadingMeterHistory: null,
  loadingTransmitMetersReading: null,
  loadingRecentOrders: false,
  orders: [],
  recentOrders: [],
  currentSelectedOrderId: 0,
  orderDetailItem: {
    symbolId: '',
    packageNames: '',
    status: 0,
    isPriceSubjectToChange: true,
    retailPrices: [],
    productTypes: []
  },
  pricingDetailItem: {
    isPriceSubjectToChange: true,
    packages: [],
    addons: [],
    individualAdjustments: [],
    summaries: []
  },
  orderAddresses: {
    billingAddress: {
      streetName: '',
      streetNumber: '',
      postcode: '',
      city: '',
      country: ''
    },
    deliveryAddress: {
      streetName: '',
      streetNumber: '',
      postcode: '',
      city: '',
      country: ''
    }
  },
  status: [
    {
      status: 0,
      datetime: ''
    }
  ],
  meters: [],
  meterHistories: {},
  errorMessageTMR: null
}

const reducer: Reducer<ShoppingState> = (state = initialState, action) => {
  switch (action.type) {
    case ShoppingActionTypes.LOAD_ACCESS_TOKEN_SUCCESS: {
      return { ...state, token: action.payload }
    }
    case ShoppingActionTypes.LOAD_ORDERS_REQUEST: {
      return {
        ...state,
        loadingOrders: true
      }
    }
    case ShoppingActionTypes.LOAD_RECENT_ORDERS_REQUEST: {
      return {
        ...state,
        loadingRecentOrders: true
      }
    }
    case ShoppingActionTypes.UPDATE_CURRENT_ID_REQUEST: {
      return { ...state, loadingOrderIdSelection: true }
    }
    case ShoppingActionTypes.LOAD_ORDER_DETAIL_REQUEST: {
      return { ...state, loadingOrderDetailItem: true }
    }
    case ShoppingActionTypes.LOAD_STATUS_REQUEST: {
      return { ...state, loadingStatus: true }
    }
    case ShoppingActionTypes.LOAD_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.payload,
        loadingOrders: false
      }
    }
    case ShoppingActionTypes.LOAD_RECENT_ORDERS_SUCCESS: {
      return {
        ...state,
        recentOrders: action.payload,
        loadingRecentOrders: false
      }
    }
    case ShoppingActionTypes.UPDATE_CURRENT_ID_SUCCESS: {
      return {
        ...state,
        currentSelectedOrderId: action.payload,
        loadingOrderIdSelection: false
      }
    }
    case ShoppingActionTypes.LOAD_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        orderDetailItem: action.payload,
        loadingOrderDetailItem: false
      }
    }
    case ShoppingActionTypes.LOAD_STATUS_SUCCESS: {
      return { ...state, status: action.payload, loadingStatus: false }
    }
    case ShoppingActionTypes.LOAD_PRICING_DETAIL_REQUEST: {
      return { ...state, loadingPricingDetailItem: true }
    }
    case ShoppingActionTypes.LOAD_PRICING_DETAIL_SUCCESS: {
      return {
        ...state,
        pricingDetailItem: action.payload,
        loadingPricingDetailItem: false
      }
    }
    case ShoppingActionTypes.LOAD_METERS_REQUEST: {
      return {
        ...state,
        loadingMeters: true
      }
    }
    case ShoppingActionTypes.LOAD_METERS_SUCCESS: {
      return {
        ...state,
        isOrdersPage: false,
        isDashboardPage: false,
        loadingMeters: false,
        isMeterPage: true,
        meters: action.payload
      }
    }
    case ShoppingActionTypes.LOAD_METERS_FAILURE: {
      return {
        ...state,
        loadingMeters: false
      }
    }
    case ShoppingActionTypes.LOAD_METER_HISTORY_REQUEST: {
      return {
        ...state,
        loadingMeterHistory: true
      }
    }
    case ShoppingActionTypes.LOAD_METER_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingMeterHistory: false,
        meterHistories: {
          ...state.meterHistories,
          ...action.payload
        }
      }
    }
    case ShoppingActionTypes.LOAD_METER_HISTORY_FAILURE: {
      return {
        ...state,
        loadingMeterHistory: false
      }
    }
    case ShoppingActionTypes.TRANSMIT_METERS_READING_REQUEST: {
      return {
        ...state,
        loadingTransmitMetersReading: true
      }
    }
    case ShoppingActionTypes.LOAD_RECENT_ORDERS_FAILURE: {
      return {
        ...state,
        loadingRecentOrders: false
      }
    }
    case ShoppingActionTypes.LOAD_ORDERS_FAILURE: {
      return {
        ...state,
        loadingOrders: false
      }
    }
    case ShoppingActionTypes.TRANSMIT_METERS_READING_SUCCESS: {
      return {
        ...state,
        loadingTransmitMetersReading: false,
        errorMessageTMR: null
      }
    }
    case ShoppingActionTypes.TRANSMIT_METERS_READING_FAILURE: {
      return {
        ...state,
        loadingTransmitMetersReading: false,
        errorMessageTMR: action.payload
      }
    }
    case ShoppingActionTypes.LOAD_ORDER_ADDRESSES_REQUEST: {
      return {
        ...state,
        loadingOrderAddresses: true
      }
    }
    case ShoppingActionTypes.LOAD_ORDER_ADDRESSES_SUCCESS: {
      return {
        ...state,
        loadingOrderAddresses: false,
        orderAddresses: action.payload
      }
    }
    case ShoppingActionTypes.LOAD_ORDER_ADDRESSES_FAILURE: {
      return {
        ...state,
        loadingOrderAddresses: false
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as shoppingReducer }
