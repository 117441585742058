import { Input, makeStyles, useTheme } from '@material-ui/core'
import MSelect, { SelectProps as MSelectProps } from '@material-ui/core/Select'
import React from 'react'

export interface SelectProps extends MSelectProps {
  children?: React.ReactElement | React.ReactElement[]
}

const inputStyles = makeStyles({
  root: {
    padding: '0',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0
  }
})

export const Select = (props: SelectProps): React.ReactElement => {
  const { value, children, onOpen } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const theme = useTheme()
  const themeMenuProps = theme.props?.MuiSelect?.MenuProps || {}
  const inputClasses = inputStyles()

  const onMuiOpen = (event: React.ChangeEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    if (onOpen) {
      onOpen(event)
    }
  }

  return (
    <MSelect
      {...props}
      IconComponent={() => (
        <span
          style={{
            display: 'inline-flex',
            paddingRight: '12px !important',
            zIndex: -1,
            position: 'absolute',
            top: '13px',
            right: '12px'
          }}
        >
          <svg
            fill="none"
            height="5"
            viewBox="0 0 8 5"
            width="8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 0.75L4.25 3.75L1.25 0.75"
              stroke="#222222"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
            />
          </svg>
        </span>
      )}
      MenuProps={{
        ...themeMenuProps,
        ...props.MenuProps,
        anchorEl: anchorEl
      }}
      input={<Input classes={inputClasses} />}
      onOpen={(event: unknown): void =>
        onMuiOpen(event as React.ChangeEvent<HTMLElement>)
      }
      value={value}
    >
      {children}
    </MSelect>
  )
}
