import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ListViewIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        data-color="color-2"
        fill="none"
        height="4"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="14"
      />{' '}
      <rect
        fill="none"
        height="4"
        stroke="#222222"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="4"
      />{' '}
      <rect
        fill="none"
        height="4"
        stroke="#222222"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="24"
      />
    </g>
  </>
)
