import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductCarsharingIcon = createSvgIcon(
  <>
    <title>product carsharing</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="carsharing"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <path
          d="M6.807 18.772l1.638 1.366-1.638-1.366c2.127-.95 4.46-1.442 7-1.479 3.28-.047 6.761.913 10.445 2.879 1.416.079 2.781.35 4.097.813 1.316.463 2.037 1.105 2.164 1.927v3.123l-1.99-.064c-.48 1.2-1.307 1.801-2.477 1.801-1.171 0-2.037-.6-2.596-1.8H9.493c-.497 1.2-1.324 1.8-2.481 1.8-1.158 0-2.007-.6-2.547-1.8-1.27-.313-2.069-.589-2.396-.828-.328-.24-.535-.614-.622-1.123v-3.85L5 19.72c.585-.355 1.187-.67 1.807-.947zm7.787-1.46v2.858-2.858zm.469 4.375h.944-.945zm-13.126 0h1.22-1.22zm5.068 6.038c1.385 0 2.507-1.048 2.507-2.34 0-1.293-1.122-2.341-2.507-2.341s-2.508 1.048-2.508 2.34c0 1.293 1.123 2.341 2.508 2.341zm1.48-7.555h15.587H8.485zm17.523 7.599c1.385 0 2.508-1.048 2.508-2.34 0-1.294-1.123-2.341-2.508-2.341s-2.508 1.047-2.508 2.34 1.123 2.34 2.508 2.34zM15.063 12.054v.017-.017zm0 1.75v.017-.017zm0 1.75v.017-.017zm8.808-1.695l-.013.013.013-.013zm-1.325 1.238l-.014.012.014-.012zm-1.326 1.237l-.014.013.014-.013zM6.206 13.86l.014.013-.014-.013zm1.326 1.238l.014.012-.014-.012zm1.326 1.237l.013.013-.013-.013zM19.365 9.79h-6.95v-.794c0-.431.315-.825.817-1.014.585-.22 1.48-.45 2.658-.45 1.179 0 2.073.23 2.658.45.502.189.817.583.817 1.014v.794zM17.636 4.57c0 .857-.812 1.834-1.814 1.834s-1.815-.977-1.815-1.834c0-.858.813-1.553 1.815-1.553s1.814.695 1.814 1.553zm12.042 6.971h-6.95v-.794c0-.431.314-.825.816-1.014.586-.22 1.48-.45 2.659-.45 1.178 0 2.072.23 2.658.45.502.189.817.583.817 1.014v.794zm-1.73-5.221c0 .857-.811 1.834-1.814 1.834-1.002 0-1.814-.977-1.814-1.834 0-.858.812-1.553 1.814-1.553 1.003 0 1.815.695 1.815 1.553zM9.054 11.54h-6.95v-.794c0-.431.314-.825.816-1.014.586-.22 1.48-.45 2.659-.45 1.178 0 2.072.23 2.658.45.502.189.817.583.817 1.014v.794zM7.323 6.32c0 .857-.811 1.834-1.814 1.834-1.002 0-1.814-.977-1.814-1.834 0-.858.812-1.553 1.814-1.553 1.003 0 1.815.695 1.815 1.553z"
          id="Carsharing"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </>
)
