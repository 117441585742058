import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import { useLoading } from '../common/hooks/custom.hook'
import { LoadingComponent } from '../components'
import { ApplicationState } from '../store'
import { authenticate, updateAuthStatus } from '../store/app/actions'
import { SelectedStyles, TermsPrivacyAndEmail } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import { BodyText, ThemeProvider } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#849096',
      padding: '16px 0'
    },
    '.MuiFormLabel-root': {
      letterSpacing: 0,
      fontWeight: 600,
      lineHeight: '16px'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400,
      lineHeight: '24px',
      letterSpacing: 0
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

type LandingProps = {
  selectedStyles?: SelectedStyles
  termsPrivacyAndSupportEmail?: TermsPrivacyAndEmail
  isAuthenticating?: boolean
}

const EndCustomerPortal = (props: LandingProps): React.ReactElement => {
  const { selectedStyles, termsPrivacyAndSupportEmail } = props
  const { isAuthenticating } = useSelector(
    (state: ApplicationState) => state.app
  )
  const { isEmailExistInEpilotSystem } = useSelector(
    (state: ApplicationState) => state.app.auth
  )
  const { language, organizationId } = useSelector(
    (state: ApplicationState) => state.profile.profile
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [values, setValues] = useState<Record<string, unknown>>({
    email: ''
  })
  const [isValidEmailFormat, setIsValidEmailFormat] = useState<boolean | null>(
    null
  )

  const [existSupportEmail, setExistSupportEmail] = useState<boolean>(
    true
  )
  const isLoading = useLoading()
  const [errorMessage, setErrorMessage] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (isValidEmailFormat === false) {
      setErrorMessage('Please enter a valid email')
    } else {
      setErrorMessage('')
    }
  }, [isValidEmailFormat])

  useEffect(() => {
    if (isEmailExistInEpilotSystem !== null && !isEmailExistInEpilotSystem && _.isEmpty(termsPrivacyAndSupportEmail?.support_email)) {
      setExistSupportEmail(false)
    }
    if (isEmailExistInEpilotSystem === false) {
      setErrorMessage('Email not found')
    } else {
      setErrorMessage('')
    }
  }, [isEmailExistInEpilotSystem])

  const handleNextButton = () => {
    if (isEmailExistInEpilotSystem === null ||
    isEmailExistInEpilotSystem === true) {
      dispatch(
        authenticate({
          email: values.email as string,
          orgId: (organizationId as unknown) as string,
          history: history
        })
      )
    } else {
      const mailto_link = 'mailto:' + termsPrivacyAndSupportEmail?.support_email
      window.open(mailto_link, 'emailWindow')
    }
  }

  useEffect(() => {
    if (values.email) {
      const regCheckEmail = /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}/gm

      setIsValidEmailFormat(regCheckEmail.test(values.email as string))
    } else {
      setIsValidEmailFormat(null)
    }
  }, [values])

  const handleChangeValue = _.debounce((values: Record<string, unknown>) => {
    setValues(values)
    setExistSupportEmail(true)
    if (isEmailExistInEpilotSystem !== null) {
      dispatch(
        updateAuthStatus({
          isEmailExistInEpilotSystem: null
        })
      )
    }
  }, 200)

  const onLanguageChange = async (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          {isLoading ? (
            <MuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <Login
              maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
              heightLoginFormOnMobile="93%"
              logoName={selectedStyles?.logo?.name}
              logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
              backgroundColor={selectedStyles?.palette?.primary}
              textColor={selectedStyles?.typography?.primaryColor}
              authEmailLabel={t('Email')}
              authOrganizationLabel="Organization"
              authSigninButtonLabel={
                isEmailExistInEpilotSystem === null ||
                isEmailExistInEpilotSystem === true
                  ? t('Next')
                  : t('Contact us')
              }
              headerText={t('Log in or Sign up')}
              isShowPasswordField={false}
              isShowRememberMe={false}
              isShowSymbolField={false}
              isValidForm={isValidEmailFormat === true}
              existSupportEmail={existSupportEmail}
              isSubmitting={isAuthenticating}
              loadingIndicatorColor={selectedStyles?.palette?.primary}
              languages={[
                { value: 'en', label: 'English' },
                {
                  value: 'de',
                  label: 'Deutsch'
                }
              ]}
              languageValue={language}
              onChangeValues={handleChangeValue}
              onLanguageChange={(event) => onLanguageChange(event)}
              onSubmitForm={handleNextButton}
              subHeaderText={
                <BodyText>
                  {t('Please enter your email to get started')}
                </BodyText>
              }
              customChildContent={
                errorMessage ? (
                  <p
                    style={{
                      textAlign: 'left',
                      color: '#F44336',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: 12,
                      marginTop: 5
                    }}
                  >
                    {t(errorMessage)}
                  </p>
                ) : (
                  <div />
                )
              }
            />
          )}
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles,
    termsPrivacyAndSupportEmail: state.app.termsPrivacyAndSupportEmail
  }
}

export default connect(mapStateToProps)(EndCustomerPortal)
