import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import letter from '../assets/images/letter.svg'
import sendEmail from '../assets/images/sent-email.svg'
import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import { useSetPassword } from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import PasswordRequirement from '../components/password-requirement'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import {
  BodyText,
  Button,
  LoadingIcon,
  SmallText,
  ThemeProvider
} from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#849096',
      padding: '16px 0'
    },
    '.MuiFormLabel-root': {
      letterSpacing: 0,
      fontWeight: 600,
      lineHeight: '16px'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400,
      lineHeight: '24px',
      letterSpacing: 0
    } /* eslint-disable */,
    '@media (min-width: 780px)': {
      '.MuiBox-root div:nth-child(2)': {
        display: 'inherit'
      }
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

type CreateAccountProps = {
  selectedStyles?: SelectedStyles
}

const CreateAccountPage = (props: CreateAccountProps): React.ReactElement => {
  const { t } = useTranslation()
  const { selectedStyles } = props
  const {
    app: { currEmail },
    profile: {
      profile: { language, customerId, organizationId, brandId }
    }
  } = useSelector((state: ApplicationState) => state)
  const [isSignUpSuccess, setIsSignUpSuccess] = useState<boolean>(false)
  const [isResendLinkFailed, setIsResendLinkFailed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    isMinimum8Characters,
    isOneNumber,
    isOneUpperCaseCharacter,
    values,
    isValidateForm,
    onChangePassword
  } = useSetPassword()

  const dispatch = useDispatch()
  const { goToLandingPage } = useNavigation()

  useEffect(() => {
    if (!currEmail) {
      goToLandingPage()
    }
  }, [currEmail])

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      await Auth.signUp({
        username: currEmail,
        password: values.password as string,
        attributes: {
          email: currEmail,
          ['custom:ivy_customer_id']: String(customerId),
          ['custom:ivy_org_id']: organizationId,
          ['custom:ivy_brand_id']: brandId
        }
      })
      setIsSignUpSuccess(true)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const onLanguageChange = (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  const handleResendLink = async () => {
    try {
      setIsLoading(true)
      // throw new Error()
      await Auth.resendSignUp(currEmail)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setIsResendLinkFailed(true)
    }
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          <Login
            maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
            heightLoginFormOnMobile="93%"
            logoName={selectedStyles?.logo?.name}
            logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
            backgroundColor={selectedStyles?.palette?.primary}
            textColor={selectedStyles?.typography?.primaryColor}
            authPasswordLabel={t('Password')}
            authSigninButtonLabel={
              isResendLinkFailed ? t('Try again') : t('Create an account')
            }
            customChildContent={
              <>
                {!isSignUpSuccess && (
                  <PasswordRequirement
                    isMinimum8Characters={isMinimum8Characters}
                    isOneUpperCaseCharacter={isOneUpperCaseCharacter}
                    isOneNumber={isOneNumber}
                  />
                )}
              </>
            }
            headerText={
              isResendLinkFailed
                ? t('Resend link failed')
                : isSignUpSuccess
                ? t('Check your inbox')
                : t('Create an account')
            }
            isShowButtonSubmitForm={!isSignUpSuccess || isResendLinkFailed}
            isShowEmailField={false}
            isShowPasswordField={!isSignUpSuccess}
            isShowRememberMe={false}
            isShowSymbolField={false}
            isValidForm={isValidateForm}
            languages={[
              { value: 'en', label: 'English' },
              {
                value: 'de',
                label: 'Deutsch'
              }
            ]}
            languageValue={language}
            isSubmitting={isLoading}
            onChangeValues={onChangePassword}
            onLanguageChange={(event) => onLanguageChange(event)}
            onSubmitForm={isResendLinkFailed ? goToLandingPage : onSubmit}
            rememberMeLabel="Remember me"
            loadingIndicatorColor={selectedStyles?.palette?.primary}
            subHeaderText={
              <>
                {isResendLinkFailed ? (
                  <BodyText>{t('Resend link failed detail')}</BodyText>
                ) : isSignUpSuccess ? (
                  <BodyText>
                    {t('We have sent an activation link to')}{' '}
                    <strong>{currEmail}</strong>
                    {t(
                      'Simply click the link to continue creating your portal account'
                    )}
                  </BodyText>
                ) : (
                  <BodyText>
                    {t('Please choose a password for your account')}
                  </BodyText>
                )}
              </>
            }
            termsTextInForm={
              <>
                {!isResendLinkFailed && isSignUpSuccess && (
                  <div style={{ paddingTop: '16px' }}>
                    <SmallText style={{ color: '#849096' }}>
                      {t("Didn't receive an email?")}
                    </SmallText>
                    <div style={{ paddingTop: '16px' }}>
                      <Button
                        color="primary"
                        size="large"
                        variant="text"
                        onClick={handleResendLink}
                      >
                        {isLoading ? (
                          <LoadingIcon
                            width={'35px'}
                            height={'35px'}
                            color={selectedStyles?.palette?.primary}
                          />
                        ) : (
                          t('Resend link')
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            }
            titleIcon={
              <>
                {isResendLinkFailed ? (
                  <img
                    alt="No results found"
                    height="96"
                    src={letter}
                    width="96"
                  />
                ) : isSignUpSuccess ? (
                  <img
                    alt="No results found"
                    height="96"
                    src={sendEmail}
                    width="96"
                  />
                ) : null}
              </>
            }
          />
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(CreateAccountPage)
