import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SplitVerticalIcon = createSvgIcon(
  <>
    <title>split vertical</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="30"
        x2="2"
        y1="16"
        y2="16"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="20"
        y2="30"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="2"
        y2="12"
      />{' '}
      <polyline
        fill="none"
        points="10,24 16,30 22,24 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="10,8 16,2 22,8 "
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
