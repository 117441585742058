import { Reducer } from 'redux'

import { AppState, AppActionTypes } from './types'

export const initialState: AppState = {
  loadingSelectedStyles: null,
  loadingPremiumFeature: false,
  currEmail: '',
  isAuthenticating: false,
  isLoggingIn: false,
  loggedIn: false,
  isAlreadyCheckedUserLoggedIn: false,
  isAlreadyLoadedSelectedStyles: false,
  isAlreadyCheckedPremiumFeature: false,
  selectedStyles: {
    id: '',
    styleName: '',
    styleType: 0,
    logo: {
      name: '',
      url: ''
    },
    palette: {
      background: '',
      primary: ''
    },
    typography: {
      primaryColor: ''
    }
  },
  auth: {
    isEmailExistInEpilotSystem: null,
    errorMessage: ''
  },
  isLoadingTermsPrivacyAndSupportEmail: null,
  termsPrivacyAndSupportEmail: {}
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.GET_SELECTED_STYLES_REQUEST: {
      return {
        ...state,
        loadingSelectedStyles: true
      }
    }
    case AppActionTypes.GET_SELECTED_STYLES_SUCCESS: {
      return {
        ...state,
        selectedStyles: action.payload,
        loadingSelectedStyles: false,
        isAlreadyLoadedSelectedStyles: true
      }
    }
    case AppActionTypes.CHECK_USER_LOGGED_IN_REQUEST: {
      return {
        ...state,
        isLoggingIn: true,
        isAlreadyCheckedUserLoggedIn: true
      }
    }
    case AppActionTypes.CHECK_USER_LOGGED_IN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        isLoggingIn: false
      }
    }
    case AppActionTypes.CHECK_USER_LOGGED_IN_FAILURE: {
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: false
      }
    }
    case AppActionTypes.GET_SELECTED_STYLES_FAILURE: {
      return {
        ...state,
        loadingSelectedStyles: false
      }
    }
    case AppActionTypes.CHECK_EMAIL_EXIST_SUCCESS: {
      return {
        ...state,
        currEmail: action.payload.email
      }
    }
    case AppActionTypes.AUTHENTICATION_REQUEST: {
      return {
        ...state,
        isAuthenticating: true
      }
    }
    case AppActionTypes.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isAuthenticating: false
      }
    }
    case AppActionTypes.UPDATE_APP_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case AppActionTypes.AUTHENTICATION_FAILURE:
    case AppActionTypes.UPDATE_AUTHENTICATION_STATUS: {
      return {
        ...state,
        isAuthenticating: false,
        loggedIn: false,
        auth: {
          ...state.auth,
          ...action.payload
        }
      }
    }
    case AppActionTypes.CHECK_PREMIUM_FEATURE_REQUEST: {
      return {
        ...state,
        loadingPremiumFeature: true
      }
    }
    case AppActionTypes.CHECK_PREMIUM_FEATURE_FAILURE: {
      {
        return {
          ...state,
          loadingPremiumFeature: false
        }
      }
    }
    case AppActionTypes.CHECK_PREMIUM_FEATURE_SUCCESS: {
      return {
        ...state,
        loadingPremiumFeature: false,
        isAlreadyCheckedPremiumFeature: true
      }
    }
    case AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_REQUEST: {
      return {
        ...state,
        isLoadingTermsPrivacyAndSupportEmail: true
      }
    }
    case AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_SUCCESS: {
      return {
        ...state,
        termsPrivacyAndSupportEmail: action.payload,
        isLoadingTermsPrivacyAndSupportEmail: false
      }
    }
    case AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_FAILURE: {
      return {
        ...state,
        isLoadingTermsPrivacyAndSupportEmail: false
      }
    }
    // case AppActionTypes.LOG_OUT: {
    //   return { ...initialState, selectedStyles: state.selectedStyles }
    // }

    default: {
      return state
    }
  }
}

export { reducer as appReducer }
