import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import styled from 'styled-components'

import { ThemeProps } from '../themes'

interface CardProps {
  className?: string
  outerChildren?: React.ReactElement
  children?: React.ReactElement | React.ReactElement[]
  leftIcon?: React.ReactElement | null
  rightIcon?: React.ReactElement | null
  direction?: string
  disabled?: boolean
  selected?: boolean | string
  boxed?: boolean
  color?: 'primary' | 'secondary' | 'default'
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  dataComponent?: string
}

interface CardIconProps {
  direction?: string
}

const StyledCardContainer = styled.div`
  flex-direction: ${(props: CardProps): string =>
    props.direction ? props.direction : 'row'};
  text-align: ${(props: CardProps): string =>
    props.direction === 'column' || props.direction === 'column-reverse'
      ? 'center'
      : 'initial'};
  display: flex;
  justify-content: right;
  width: 100%;
`
const LeftIconContainer = styled.div`
  align-self: ${(props: CardIconProps): string =>
    props.direction === 'column' || props.direction === 'column-reverse'
      ? 'center'
      : 'initial'};
  display: flex;
  justify-content: right;
  flex-direction: row;
`
const RightIconContainer = styled.div`
  align-self: ${(props: CardIconProps): string =>
    props.direction === 'column' || props.direction === 'column-reverse'
      ? 'center'
      : 'initial'};
  display: flex;
  justify-content: right;
  flex-direction: row;
  padding-left: 8px;
  margin-right: -8px;
  margin-left: auto;
`

export const Card = (props: CardProps): React.ReactElement => {
  const {
    leftIcon,
    rightIcon,
    children,
    boxed,
    selected,
    disabled,
    onClick,
    outerChildren,
    direction,
    dataComponent
  } = props
  const useStyles = makeStyles((theme: ThemeProps) => ({
    box: {
      border: !boxed
        ? 'none'
        : selected
        ? typeof theme.overrides?.MuiCheckbox?.colorPrimary === 'undefined'
          ? '1px solid ' + theme.palette.primary.main
          : '1px solid ' + theme.overrides?.MuiCheckbox?.colorPrimary
        : '1px solid #d2dee4',
      borderRadius: theme.shape.borderRadius,
      padding: boxed ? '12px 16px' : 'initial',
      textAlign: 'left',
      justifyContent: 'right',
      cursor: disabled ? 'not-allowed' : 'pointer'
    }
  }))
  const classes = useStyles(props)

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classes.box}
      data-component={dataComponent}
      onClick={(e) => {
        if (!disabled && onClick != null) onClick(e)
      }}
    >
      <StyledCardContainer direction={direction}>
        <LeftIconContainer direction={direction}>{leftIcon}</LeftIconContainer>
        <div>{children}</div>
        <RightIconContainer direction={direction}>
          {rightIcon}
        </RightIconContainer>
      </StyledCardContainer>
      {outerChildren}
    </div>
  )
}
export default Card
