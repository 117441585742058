import { Box as MuiBox, Card } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { Profile } from '../store/profile/types'
import { Chip, SmallText } from '../tmp-ui-element'

type AddressProps = {
  currEmail: string
  currProfile: Profile
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const UserAddressPage = (props: AddressProps): React.ReactElement => {
  const { currProfile } = props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false}>
        <MuiBox className={classes.main}>
          <MuiBox
            style={{
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 400
            }}
          >
            {t('Addresses')}
          </MuiBox>

          {/*CONTENT*/}
          {!_.isEmpty(currProfile.addresses) && (
            <>
              {_.map(currProfile.addresses, (item, key) => (
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  key={key}
                  pt={2}
                  width="100%"
                >
                  <Card className={classes.root} variant="outlined">
                    <MuiBox p={2}>
                      <SmallText style={{ fontWeight: 600 }}>
                        {item.name}
                      </SmallText>
                      <MuiBox pt={0.5}>
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#222222'
                          }}
                        >
                          {item.streetName ? item.streetName : ''}
                          {item.streetNumber ? ' ' + item.streetNumber : ''}
                          {(item.streetName || item.streetNumber) &&
                          item.postcode
                            ? ', '
                            : ''}
                          {item.postcode ? item.postcode : ''}
                          {item.city ? ' ' + item.city : ''}
                          {(item.postcode || item.city) && item.country
                            ? ', '
                            : ''}
                          {item.country ? item.country : ''}
                        </span>
                      </MuiBox>
                      {item.is_delivery && (
                        <MuiBox display="inline-flex" pr={1} pt={1.5}>
                          <Chip
                            label={t('Default delivery')}
                            size="small"
                            style={{
                              backgroundColor: '#F9FAFF',
                              border: '1px solid #ACB8FE'
                            }}
                            type="info"
                            variant="outlined"
                          />
                        </MuiBox>
                      )}
                      {item.is_billing && (
                        <MuiBox display="inline-flex" pt={1.5}>
                          <Chip
                            label={t('Default billing')}
                            size="small"
                            style={{
                              backgroundColor: '#F0F8E7',
                              border: '1px solid #A6D873'
                            }}
                            type="info"
                            variant="outlined"
                          />
                        </MuiBox>
                      )}
                    </MuiBox>
                  </Card>
                </MuiBox>
              ))}
            </>
          )}
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    currEmail: state.app.currEmail
  }
}

export default connect(mapStateToProps)(UserAddressPage)
