import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const VoiceRecordIcon = createSvgIcon(
  <>
    <title>voice record</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d=" M20.001,10.355C20.623,11.06,21,11.986,21,13c0,1.014-0.378,1.94-1,2.646"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <path
        d=" M23.001,6.293C24.842,7.941,26,10.335,26,13c0,2.665-1.158,5.06-2.999,6.708"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M19,30H1v-2.242 c0-1.775,1.164-3.334,2.866-3.838C5.46,23.448,7.604,23,10,23c2.356,0,4.514,0.456,6.125,0.932C17.83,24.436,19,25.994,19,27.773V30 z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M5,13c0-2.761,2.239-5,5-5 s5,2.239,5,5s-2.239,6-5,6S5,15.761,5,13z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d=" M26.002,2.278C29.058,4.846,31,8.696,31,13c0,4.305-1.943,8.156-5,10.724"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
