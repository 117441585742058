import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './locales/i18n'

const vh = window.innerHeight * 0.01

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

ReactDOM.render(<App />, document.getElementById('ep-portal-root'))

serviceWorker.unregister()
