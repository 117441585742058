import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const LeaveIcon = createSvgIcon(
  <>
    <title>leave</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="2,21.095 2,2 19,2 19,8 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="2,2 12,8 12,30 2,24 2,2 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="16"
        x2="30"
        y1="12"
        y2="12"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="19,16 19,24 12,24 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 24,6 30,12 24,18 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
