import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SortDownIcon = createSvgIcon(
  <>
    <title>sort down</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="_Icon-/-Others-/-sort-down-/-Black"
        stroke="none"
        strokeWidth="1"
      >
        <g id="sort-up" transform="translate(9 6)">
          <path d="M14 11.667H0L7 20z" fill="#222222" id="Path" />
          <path d="M14 8.333H0L7 0z" fill="#222222" id="Path" opacity=".5" />
        </g>
      </g>
    </g>
  </>
)
