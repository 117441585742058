import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PositionUserIcon = createSvgIcon(
  <>
    <title>position user</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M6,22.273 C2.931,23.189,1,24.519,1,26c0,2.761,6.716,5,15,5s15-2.239,15-5c0-1.48-1.93-2.811-4.998-3.726"
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M26,26H6v-4.284c0-1.129,0.628-2.162,1.634-2.673 C9.364,18.165,12.348,17,16,17s6.636,1.165,8.366,2.043C25.372,19.554,26,20.586,26,21.716V26z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="7"
        fill="none"
        r="6"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
