import { Box as MuiBox, Card, Collapse } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import pin from '../assets/images/pin.svg'
import pin1 from '../assets/images/pin1.svg'
import { useLoading, useLocale } from '../common/hooks/custom.hook'
import { convertDateTime } from '../common/utils'
import { LoadingComponent, PricingDetail } from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import {
  getStatuses,
  getAddresses,
  getPricingDetail
} from '../store/shopping/actions'
import {
  Addresses,
  OrderDetailItem,
  Status,
  SubAddresses
} from '../store/shopping/types'
import {
  Avatar,
  BodyText,
  CurrencyEuroIcon,
  MapPinIcon,
  PropertyLocationIcon,
  SmallText
} from '../tmp-ui-element'

type OrderDetailProps = {
  currentSelectedOrderId: number
  orderDetailItem: OrderDetailItem
  orderAddresses: Addresses
  status: Status[]
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    },

    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto',
      /* eslint-disable */
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    chartLine: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .active': {
        // background: 'dodgerblue',
      },
      '& .active ~ li': {
        // background: '#6BBF16',
        '&:after': {
          background: '#D2DEE4'
        },
        '&:before': {
          background: '#D2DEE4',
          border: '.0625rem solid #D2DEE4'
        }
      },
      '& li': {
        width: '30%', // height: '.0625rem',
        textAlign: 'center',
        display: 'inline-block',
        position: 'relative', // background: 'dodgerblue',

        '&:after': {
          position: 'absolute',
          top: '.5rem',
          left: '-50%',
          width: '100%',
          height: '2px',
          background: '#6BBF16',
          content: "''"
        },
        '&:before': {
          display: 'inline-block',
          verticalAlign: 'middle',
          content: "''", // content: {url(pin1)},
          width: '8px',
          height: '8px',
          margin: 'auto auto .5rem',
          borderTopLeftRadius: '50%',
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
          borderBottomLeftRadius: '50%',
          border: '.0625rem solid #6BBF16',
          position: 'relative',
          background: '#6BBF16',
          zIndex: '99'
        }
      },

      '& li:nth-child(1)': {
        '&:after': {
          display: 'none'
        }
      }
    },
    chartLineCanceled: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& li': {
        width: '30%', // height: '.0625rem',
        textAlign: 'center',
        display: 'inline-block',
        position: 'relative', // background: 'dodgerblue',

        '&:after': {
          position: 'absolute',
          top: '.5rem',
          left: '-50%',
          width: '100%',
          height: '2px',
          background: '#849096',
          content: "''"
        },
        '&:before': {
          display: 'inline-block',
          verticalAlign: 'middle',
          content: "''", // content: {url(pin1)},
          width: '8px',
          height: '8px',
          margin: 'auto auto .5rem',
          borderTopLeftRadius: '50%',
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
          borderBottomLeftRadius: '50%',
          border: '.0625rem solid #849096',
          position: 'relative',
          background: '#849096',
          zIndex: '99'
        }
      },

      '& li:nth-child(1)': {
        '&:after': {
          display: 'none'
        }
      }
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const OrderDetailPage = (props: OrderDetailProps): React.ReactElement => {
  const {
    currentSelectedOrderId,
    orderDetailItem,
    orderAddresses,
    status,
    selectedStyles
  } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const [orderedStatusItem, setOrderedStatusItem] = useState({} as Status)
  const [processingStatusItem, setProcessingStatusItem] = useState({} as Status)
  const [deliveringStatusItem, setDeliveringStatusItem] = useState({} as Status)
  const [completeStatusItem, setCompleteStatusItem] = useState({} as Status)
  const [canceledStatusItem, setCanceledStatusItem] = useState({} as Status)
  const [showPricingDetail, setShowPricingDetail] = useState(false)

  const locale = useLocale()

  const isLoading = useLoading()

  useEffect(() => {
    dispatch(getAddresses(currentSelectedOrderId))
    dispatch(getStatuses(currentSelectedOrderId))
    dispatch(getPricingDetail(currentSelectedOrderId))
  }, [])

  useEffect(() => {
    setOrderedStatusItem(_.find(status, (item) => item.status === 0) as Status)
    setProcessingStatusItem(
      _.find(status, (item) => item.status === 1) as Status
    )
    setDeliveringStatusItem(
      _.find(status, (item) => item.status === 2) as Status
    )
    setCompleteStatusItem(_.find(status, (item) => item.status === 3) as Status)
    setCanceledStatusItem(_.find(status, (item) => item.status === 4) as Status)
  }, [status])

  const formatNumber = (number: number) => {
    return (
      number &&
      number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    )
  }

  const handlePaymentFrequency = (paymentFrequency: number) => {
    switch (paymentFrequency) {
      case 0:
        return t('one-time')
      case 1:
        return t('monthly')
      case 2:
        return t('yearly')
      default:
        return t('Price subject to change')
    }
  }

  const handleShowAddress = (address: SubAddresses) => {
    return (
      !_.isEmpty(address) ? (
      <>
        {address?.streetName}
        {address?.streetNumber && (' ' + address?.streetNumber)}
        {((address?.streetName || address?.streetNumber) && (address?.postcode || address?.city)) && ', '}
        {address?.postcode}
        {address?.city && (' ' + address?.city)}
        {address?.country && (', ' + address?.country)}
      </>
    ) : (
      <BodyText style={{color: '#849096'}}>{t('No info')}</BodyText>
    )
    )
  }

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false} isLoading={isLoading}>
        <MuiBox className={classes.main}>
          {isLoading ? (
            <MuiBox pt={4}>
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <>
              <MuiBox
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 400
                }}
              >
                {orderDetailItem.packageNames}
              </MuiBox>

              <MuiBox pt={2}>
                <BodyText
                  style={{
                    fontWeight: 600,
                    color: '#849096'
                  }}
                >
                  {orderDetailItem.symbolId}
                </BodyText>
              </MuiBox>

              {/*INFORMATION*/}
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                pt={3}
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox pb={3} pt={8}>
                    <MuiBox>
                      {orderDetailItem.status !== 4 && (
                        <MuiBox
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                        >
                          <ul
                            className={classes.chartLine}
                            style={{ width: '100%', padding: 0 }}
                          >
                            <li
                              className={`${
                                orderDetailItem.status === 0 ? 'active' : ''
                              }`}
                            >
                              <MuiBox height="18px">
                                {orderDetailItem.status === 0 && (
                                  <MuiBox
                                    style={{
                                      position: 'absolute',
                                      top: '-47px',
                                      width: '100%'
                                    }}
                                  >
                                    <img alt="No results found" src={pin} />
                                  </MuiBox>
                                )}
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Ordered')}
                                  </span>
                                </MuiBox>
                                {orderedStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          orderedStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>

                            <li
                              className={`${
                                orderDetailItem.status === 1 ? 'active' : ''
                              }`}
                            >
                              <MuiBox height="18px">
                                {orderDetailItem.status === 1 && (
                                  <MuiBox
                                    style={{
                                      position: 'absolute',
                                      top: '-47px',
                                      width: '100%'
                                    }}
                                  >
                                    <img alt="No results found" src={pin} />
                                  </MuiBox>
                                )}
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Processing')}
                                  </span>
                                </MuiBox>
                                {processingStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          processingStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>

                            <li
                              className={`${
                                orderDetailItem.status === 2 ? 'active' : ''
                              }`}
                            >
                              <MuiBox height="18px">
                                {orderDetailItem.status === 2 && (
                                  <MuiBox
                                    style={{
                                      position: 'absolute',
                                      top: '-47px',
                                      width: '100%'
                                    }}
                                  >
                                    <img alt="No results found" src={pin} />
                                  </MuiBox>
                                )}
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Delivery')}
                                  </span>
                                </MuiBox>
                                {deliveringStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          deliveringStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>
                            <li
                              className={`${
                                orderDetailItem.status === 3 ? 'active' : ''
                              }`}
                            >
                              <MuiBox height="18px">
                                {orderDetailItem.status === 3 && (
                                  <MuiBox
                                    style={{
                                      position: 'absolute',
                                      top: '-47px',
                                      width: '100%'
                                    }}
                                  >
                                    <img alt="No results found" src={pin} />
                                  </MuiBox>
                                )}
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Completed')}
                                  </span>
                                </MuiBox>
                                {completeStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          completeStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>
                          </ul>
                        </MuiBox>
                      )}
                      {/*Order canceled*/}
                      {orderDetailItem.status === 4 && (
                        <MuiBox
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                        >
                          <ul
                            className={classes.chartLineCanceled}
                            style={{ width: '100%', padding: 0 }}
                          >
                            <li style={{ width: '50%' }}>
                              <MuiBox height="18px">
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Ordered')}
                                  </span>
                                </MuiBox>
                                {orderedStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          orderedStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>

                            <li style={{ width: '50%' }}>
                              <MuiBox height="18px">
                                <MuiBox
                                  style={{
                                    position: 'absolute',
                                    top: '-47px',
                                    width: '100%'
                                  }}
                                >
                                  <img alt="No results found" src={pin1} />
                                </MuiBox>
                                <MuiBox style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '0.5px'
                                    }}
                                  >
                                    {t('Canceled')}
                                  </span>
                                </MuiBox>
                                {canceledStatusItem?.datetime && (
                                  <MuiBox
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <span>
                                      <SmallText
                                        style={{
                                          color: '#849096'
                                        }}
                                      >
                                        {convertDateTime(
                                          canceledStatusItem.datetime as string,
                                          locale
                                        )}
                                      </SmallText>
                                    </span>
                                  </MuiBox>
                                )}
                              </MuiBox>
                            </li>
                          </ul>
                        </MuiBox>
                      )}
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>

              {/*PRICING CONTENT*/}
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                onClick={() => setShowPricingDetail(!showPricingDetail)}
                pb={2}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        textAlign="left"
                        width="95%"
                      >
                        <MuiBox display="inline-flex">
                          <MuiBox>
                            <Avatar
                              backgroundColor="#E7F8F8"
                              style={{
                                width: '40px',
                                height: '40px',
                                border: 'none'
                              }}
                            >
                              <CurrencyEuroIcon color="#16BFBE" size="small" />
                            </Avatar>
                          </MuiBox>
                          <MuiBox pl={2}>
                            <span
                              style={{
                                fontWeight: 600,
                                fontSize: '14px'
                              }}
                            >
                              {t('Pricing')}
                            </span>
                            <MuiBox>
                              {orderDetailItem.isPriceSubjectToChange ? (
                                <>
                                  <BodyText style={{ color: '#849096' }}>
                                    {t('Price subject to change')}
                                  </BodyText>
                                </>
                              ) : (
                                <>
                                  {_.map(
                                    orderDetailItem.retailPrices,
                                    (item, key) => (
                                      <MuiBox display="flex" key={key} pt={1}>
                                        <span
                                          style={{
                                            fontSize: '14px',
                                            color: '#222222'
                                          }}
                                        >
                                          {formatNumber(item.price)} €{' '}
                                          <span
                                            style={{
                                              color: '#849096',
                                              fontSize: '12px'
                                            }}
                                          >
                                            {handlePaymentFrequency(
                                              item.paymentFrequency
                                            )}
                                          </span>
                                        </span>
                                      </MuiBox>
                                    )
                                  )}
                                </>
                              )}
                            </MuiBox>
                          </MuiBox>
                        </MuiBox>
                      </MuiBox>

                      <MuiBox textAlign="right" width="5%">
                        {!showPricingDetail ? (
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 4.5L8 11.5L1 4.5" stroke="#222222" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        ) : (
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11.5L8 4.5L15 11.5" stroke="#222222" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        )}
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                  <Collapse
                    in={showPricingDetail}
                    timeout="auto"
                    unmountOnExit
                  >
                    <PricingDetail />
                  </Collapse>
                </Card>
              </MuiBox>

              {/*ADDRESS CONTENT*/}
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox display="inline-flex">
                        <MuiBox>
                          <Avatar
                            backgroundColor="#F9FAFF"
                            style={{
                              width: '40px',
                              height: '40px',
                              border: 'none'
                            }}
                          >
                            <PropertyLocationIcon
                              color="#304FFE"
                              size="small"
                            />
                          </Avatar>
                        </MuiBox>
                        <MuiBox pl={2}>
                          <span style={{ fontWeight: 600, fontSize: '14px' }}>
                            {t('Delivery address')}
                          </span>
                          <MuiBox display="flex" justifyContent="left" pt={0.5}>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#222222'
                              }}
                            >
                              {handleShowAddress(orderAddresses?.deliveryAddress)}
                            </span>
                          </MuiBox>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>


              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                pb={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2}>
                    <MuiBox alignItems="center" display="flex">
                      <MuiBox display="inline-flex">
                        <MuiBox>
                          <Avatar
                            backgroundColor="#F0F8E7"
                            style={{
                              width: '40px',
                              height: '40px',
                              border: 'none'
                            }}
                          >
                            <MapPinIcon color="#4E801B" size="small" />
                          </Avatar>
                        </MuiBox>
                        <MuiBox pl={2}>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '24px'
                          }}
                        >
                          {t('Billing address')}
                        </span>
                          <MuiBox display="flex" justifyContent="left" pt={0.5}>
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px',
                              color: '#222222'
                            }}
                          >
                            {handleShowAddress(orderAddresses?.billingAddress)}
                          </span>
                          </MuiBox>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </>
          )}
        </MuiBox>
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currentSelectedOrderId: state.shopping.currentSelectedOrderId,
    orderDetailItem: state.shopping.orderDetailItem,
    orderAddresses: state.shopping.orderAddresses,
    status: state.shopping.status,
    currEmail: state.app.currEmail,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(OrderDetailPage)
