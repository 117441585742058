import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SmallCheckIcon = createSvgIcon(
  <>
    <title>small check</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points=" 9,17 13,21 23,11 "
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
