import { ThemeProps } from './theme-provider'

export default ({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '6px'
        },

        '*::-webkit-scrollbar-track': {
          padding: '2px'
        },

        '*::-webkit-scrollbar-thumb': {
          background: 'rgba(132, 144, 150, 0.25)',
          borderRadius: '3px'
        }
      }
    }
  }
} as unknown) as ThemeProps
