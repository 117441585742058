import { Box as MuiBox } from '@material-ui/core'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { useQuery } from '../common/hooks/custom.hook'
import { ApplicationState } from '../store'
import { checkUserLoggedIn } from '../store/app/actions'

import { LoadingComponent } from './index'

type UserAuthenticationProps = {
  children: ReactElement
}

const UserAuthentication = ({ children }: UserAuthenticationProps) => {
  const { isLoggingIn, isAlreadyCheckedUserLoggedIn } = useSelector(
    (state: ApplicationState) => state.app
  )
  const { orgId: orgIdFromUrl } = useQuery() as { orgId: string }
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!isAlreadyCheckedUserLoggedIn) {
      // console.log('orgIdFromUrl', orgIdFromUrl)
      dispatch(checkUserLoggedIn({ orgIdFromUrl, history, location }))
    }
  }, [isAlreadyCheckedUserLoggedIn, location, history, dispatch, orgIdFromUrl])

  if (isLoggingIn) {
    return (
      <MuiBox
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
      >
        <LoadingComponent />
      </MuiBox>
    )
  }

  return <>{!isLoggingIn && children}</>
}

export default UserAuthentication
