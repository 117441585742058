import { makeStyles, useTheme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import React from 'react'

export interface ListItemTextProps {
  primaryTypography?: React.ReactElement | string
  secondaryTypography?: React.ReactElement | string
  noWrap?: boolean
}

const useStyle = makeStyles({
  fullWidth: {
    width: '100%'
  },
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  primaryContent: (props: Overrides) => ({
    ...props.MuiListItemText?.primary,
    ...props.MuiTypography?.body2
  }),
  secondaryContent: (props: Overrides) => ({
    ...props.MuiListItemText?.secondary,
    ...props.MuiTypography?.caption, //SmallText
    fontWeight: 600
  })
})

const getContent = ({
  typography,
  contentType,
  classes,
  noWrap
}: {
  typography?: React.ReactElement | string
  contentType?: 'primary' | 'secondary'
  classes: Record<string, unknown>
  noWrap?: boolean
}): React.ReactElement | undefined => {
  if (typeof typography !== 'string') {
    return typography
  }
  if (contentType === 'primary') {
    return (
      <span className={`${classes.primaryContent} ${noWrap && classes.noWrap}`}>
        {typography}
      </span>
    )
  }

  return (
    <span className={`${classes.secondaryContent} ${noWrap && classes.noWrap}`}>
      {typography}
    </span>
  )
}

/**
 * If `primaryTypography` or `secondaryTypography` is string, It will take the default style
 *
 * The props `noWrap` only effect when `primaryTypography` or `secondaryTypography` is string
 */
export const ListItemText = (props: ListItemTextProps): React.ReactElement => {
  const { primaryTypography, secondaryTypography, noWrap } = props
  const theme = useTheme()
  const classes = useStyle(theme.overrides)

  return (
    <div className={classes.fullWidth}>
      {getContent({
        typography: primaryTypography,
        contentType: 'primary',
        classes,
        noWrap
      })}
      {getContent({
        typography: secondaryTypography,
        contentType: 'secondary',
        classes,
        noWrap
      })}
    </div>
  )
}
