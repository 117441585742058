import { makeStyles } from '@material-ui/core'
import MuiAvatar, {
  AvatarProps as MuiAvatarProps
} from '@material-ui/core/Avatar'
import React from 'react'

export interface AvatarProps extends MuiAvatarProps {
  clickable?: boolean
  size?: 'small' | 'normal'
  borderColor?: string
  backgroundColor?: string
}

const useStyle = makeStyles({
  clickable: {
    cursor: 'pointer'
  },
  border: (props: { borderColor?: string }) =>
    props.borderColor ? { borderColor: props.borderColor } : {},
  backgroundColor: (props: { backgroundColor?: string }) =>
    props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}
})

export const Avatar = (props: AvatarProps): React.ReactElement => {
  const {
    children,
    clickable,
    className,
    size,
    borderColor,
    backgroundColor,
    ...restProps
  } = props
  const classes = useStyle({ borderColor, backgroundColor })
  const classNames = `${className ? className : ''} ${
    clickable ? classes.clickable : ''
  } ${size === 'small' ? 'MuiAvatar-small' : ''} ${
    borderColor ? classes.border : ''
  } ${backgroundColor ? classes.backgroundColor : ''}`

  return (
    <MuiAvatar {...restProps} className={classNames}>
      {children}
    </MuiAvatar>
  )
}
