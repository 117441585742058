import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHeatpumpIcon = createSvgIcon(
  <>
    <title>product heatpump</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="heatpump"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <rect height="24" id="Rectangle" rx="2" width="30" x="0" y="3.3" />
          <path d="M23 24.3h4" id="Line-4" strokeLinecap="round" />
          <path d="M23 22.8h4" id="Line-4-Copy-2" strokeLinecap="round" />
          <path
            d="M24.25 3.3v-.709a1 1 0 0 1 1-1H30"
            id="Line-4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.7 3.3V2a2 2 0 0 1 2-2H30"
            id="Line-4-Copy"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="12.5" cy="14.8" id="Oval" r="9.5" />
          <path d="M5 9.3v11" id="Line-3" strokeLinecap="round" />
          <path d="M7.5 7.14V22.3" id="Line-3-Copy" strokeLinecap="round" />
          <path d="M10 6.05V23.8" id="Line-3-Copy-2" strokeLinecap="round" />
          <path d="M12.5 5.3v19" id="Line-3-Copy-3" strokeLinecap="round" />
          <path d="M15 6.05V23.8" id="Line-3-Copy-4" strokeLinecap="round" />
          <path d="M17.5 7.14V22.3" id="Line-3-Copy-5" strokeLinecap="round" />
          <path d="M20 9.3v11" id="Line-3-Copy-6" strokeLinecap="round" />
        </g>
      </g>
    </g>
  </>
)
