import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import money from '../assets/images/money.svg'
import { useLoading } from '../common/hooks/custom.hook'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { PricingDetailItem } from '../store/shopping/types'
import { BodyText, H2, H3, SmallText } from '../tmp-ui-element'

import { LoadingComponent } from './index'

type PricingDetailProps = {
  currentSelectedOrderId: number
  currEmail: string
  pricingDetailItem: PricingDetailItem
  selectedStyles?: SelectedStyles
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtPackageName: {
      width: '100%',
      overflow: 'hidden'
    },
    size: {
      width: '80px',
      height: '80px'
    },
    collapsible: {
      cursor: 'pointer',
      backgroundColor: 'red'
    },
    contentCollapse: {
      display: 'none',
      overflow: 'hidden'
    }
  })
)

const PricingDetail = (props: PricingDetailProps): React.ReactElement => {
  const { pricingDetailItem, selectedStyles } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const isLoading = useLoading()

  const handlePaymentFrequency = (paymentFrequency: number | any) => {
    switch (paymentFrequency) {
      case 0:
        return t('one-time')
      case 1:
        return t('monthly')
      case 2:
        return t('yearly')
      default:
        return t('Price subject to change')
    }
  }

  const handleTitlePaymentFrequency = (paymentFrequency: number | any) => {
    switch (paymentFrequency) {
      case 0:
        return t('one-time')
      case 1:
        return t('monthly')
      case 2:
        return t('yearly')
      default:
        return ''
    }
  }

  const showContentTotalDetail = (item: any, key: any) => {
    return (
      <>
        <MuiBox key={key}>
          <MuiBox>
            <MuiBox
              display="flex"
              flexDirection="row"
              pb={1}
              pt={3}
              style={{ cursor: 'pointer' }}
              width="100%"
            >
              <MuiBox textAlign="left" width="50%">
                <H3
                  style={{
                    color: '#849096',
                    textTransform: 'uppercase'
                  }}
                >
                  {handleTitlePaymentFrequency(item.paymentFrequency)}
                </H3>
              </MuiBox>
            </MuiBox>

            {/*Price*/}
            <MuiBox>
              {/*SUBTOTAL*/}
              <MuiBox display="flex" flexDirection="row" width="100%">
                <MuiBox textAlign="left" width="50%">
                  <MuiBox pb={1}>
                    <MuiBox className={classes.txtPackageName}>
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px'
                        }}
                      >
                        {t('Subtotal')}
                      </span>
                    </MuiBox>
                  </MuiBox>
                </MuiBox>
                <MuiBox textAlign="right" width="50%">
                  <MuiBox>
                    <MuiBox
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px'
                        }}
                      >
                        {formatNumber(item.subtotal)}
                        <span
                          style={{
                            paddingLeft: '4px'
                          }}
                        >
                          €{' '}
                        </span>
                      </span>
                    </MuiBox>
                  </MuiBox>
                </MuiBox>
              </MuiBox>

              {/*TAX*/}
              {_.isEmpty(item.taxes) ? (
                <MuiBox display="flex" flexDirection="row" width="100%">
                  <MuiBox textAlign="left" width="50%">
                    <MuiBox pb={1}>
                      <MuiBox className={classes.txtPackageName}>
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: '24px'
                          }}
                        >
                          {t('Tax')}
                        </span>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                  <MuiBox textAlign="right" width="50%">
                    <MuiBox>
                      <MuiBox
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: '24px'
                          }}
                        >
                          0
                          <span
                            style={{
                              paddingLeft: '4px'
                            }}
                          >
                            €{' '}
                          </span>
                        </span>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                </MuiBox>
              ) : (
                _.map(item.taxes, (tax, index) => (
                  <MuiBox
                    display="flex"
                    flexDirection="row"
                    key={index}
                    width="100%"
                  >
                    <MuiBox textAlign="left" width="50%">
                      <MuiBox pb={1}>
                        <MuiBox className={classes.txtPackageName}>
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px'
                            }}
                          >
                            {tax.name} {tax.rate}%
                          </span>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                    <MuiBox textAlign="right" width="50%">
                      <MuiBox>
                        <MuiBox
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-end"
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '24px'
                            }}
                          >
                            {formatNumber(tax.amount)}
                            <span
                              style={{
                                paddingLeft: '4px'
                              }}
                            >
                              €{' '}
                            </span>
                          </span>
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>
                  </MuiBox>
                ))
              )}
            </MuiBox>
            {/*TOTAL*/}
            <MuiBox display="flex" flexDirection="row" width="100%">
              <MuiBox textAlign="left" width="50%">
                <MuiBox>
                  <MuiBox className={classes.txtPackageName}>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '24px'
                      }}
                    >
                      {t('Total')}
                    </span>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
              <MuiBox textAlign="right" width="50%">
                <MuiBox>
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '24px'
                      }}
                    >
                      {formatNumber(item.total)}
                      <span
                        style={{
                          paddingLeft: '4px'
                        }}
                      >
                        €{' '}
                      </span>
                    </span>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </>
    )
  }

  const formatNumber = (number: number) => {
    return (
      number &&
      number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    )
  }

  return (
    <>
      <MuiBox p={2} pt={1}>
        {isLoading ? (
          <MuiBox pt={4}>
            <LoadingComponent color={selectedStyles?.palette?.primary} />
          </MuiBox>
        ) : (
          <MuiBox>
            {pricingDetailItem.isPriceSubjectToChange ? (
              <MuiBox p={3}>
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <img alt="No results found" src={money} />
                </MuiBox>
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  pt={3}
                >
                  <BodyText>{t('Price subject to change')}</BodyText>
                </MuiBox>
                <MuiBox
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  pt={2}
                >
                  <SmallText
                    style={{
                      color: '#849096',
                      textAlign: 'center'
                    }}
                  >
                    {t('The price will be displayed when it is available')}
                  </SmallText>
                </MuiBox>
              </MuiBox>
            ) : (
              <>
                {/*PACKAGES*/}
                {_.map(pricingDetailItem.packages, (item, packageKey) => (
                  <MuiBox key={packageKey}>
                    <MuiBox>
                      <MuiBox display="flex" flexDirection="row" width="100%">
                        <MuiBox textAlign="left" width="45%">
                          <MuiBox>
                            <MuiBox className={classes.txtPackageName}>
                              <span>{item.name}</span>
                            </MuiBox>
                          </MuiBox>
                        </MuiBox>

                        {/*Price*/}
                        <MuiBox textAlign="right" width="55%">
                          {_.map(item.purchasePrices, (price, key) => (
                            <MuiBox
                              display="flex"
                              flexDirection="row"
                              key={key}
                            >
                              {key === 0 ? (
                                <MuiBox
                                  textAlign="right"
                                  width="calc(100% - 75px)"
                                >
                                  {formatNumber(price.price)}
                                </MuiBox>
                              ) : (
                                <MuiBox
                                  textAlign="right"
                                  width="calc(100% - 75px)"
                                >
                                  + {formatNumber(price.price)}
                                </MuiBox>
                              )}

                              <MuiBox
                                height="16px"
                                pl={0.5}
                                textAlign="left"
                                width="75px"
                              >
                                <span style={{ width: '10px' }}>€ </span>
                                <span
                                  style={{
                                    fontSize: '12px',
                                    color: '#849096',
                                    lineHeight: '16px'
                                  }}
                                >
                                  {handlePaymentFrequency(
                                    price.paymentFrequency
                                  )}
                                </span>
                              </MuiBox>
                            </MuiBox>
                          ))}
                        </MuiBox>
                      </MuiBox>

                      {/*add-ons*/}
                      {!_.isEmpty(item.addons) &&
                        _.map(item.addons, (addon, key) => (
                          <MuiBox
                            display="flex"
                            flexDirection="row"
                            key={key}
                            pt={2}
                            width="100%"
                          >
                            <MuiBox textAlign="left" width="45%">
                              <MuiBox>
                                <MuiBox className={classes.txtPackageName}>
                                  <span>{addon.name}</span>
                                </MuiBox>
                                <MuiBox className={classes.txtPackageName}>
                                  <SmallText style={{ color: '#849096' }}>
                                    {t('Add-on')}
                                  </SmallText>
                                </MuiBox>
                              </MuiBox>
                            </MuiBox>

                            {/*Price*/}
                            <MuiBox textAlign="right" width="55%">
                              {_.map(
                                addon.purchasePrices,
                                (retail_price_addon, key) => (
                                  <MuiBox
                                    display="flex"
                                    flexDirection="row"
                                    key={key}
                                  >
                                    {key === 0 ? (
                                      <MuiBox
                                        textAlign="right"
                                        width="calc(100% - 75px)"
                                      >
                                        {formatNumber(retail_price_addon.price)}
                                      </MuiBox>
                                    ) : (
                                      <MuiBox
                                        textAlign="right"
                                        width="calc(100% - 75px)"
                                      >
                                        +{' '}
                                        {formatNumber(retail_price_addon.price)}
                                      </MuiBox>
                                    )}

                                    <MuiBox
                                      pl={0.5}
                                      textAlign="left"
                                      width="75px"
                                    >
                                      <span style={{ width: '10px' }}>€ </span>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          color: '#849096',
                                          lineHeight: '16px'
                                        }}
                                      >
                                        {handlePaymentFrequency(
                                          retail_price_addon.paymentFrequency
                                        )}
                                      </span>
                                    </MuiBox>
                                  </MuiBox>
                                )
                              )}
                            </MuiBox>
                          </MuiBox>
                        ))}

                      {/*individualAdjustments*/}
                      {!_.isEmpty(item.individualAdjustments) &&
                        _.map(
                          item.individualAdjustments,
                          (individual_adjustment, key) => (
                            <MuiBox
                              display="flex"
                              flexDirection="row"
                              key={key}
                              pt={2}
                              width="100%"
                            >
                              <MuiBox textAlign="left" width="45%">
                                <MuiBox>
                                  <MuiBox className={classes.txtPackageName}>
                                    <span>{individual_adjustment.name}</span>
                                  </MuiBox>
                                  <MuiBox className={classes.txtPackageName}>
                                    <SmallText style={{ color: '#849096' }}>
                                      {t('Other')}
                                    </SmallText>
                                  </MuiBox>
                                </MuiBox>
                              </MuiBox>

                              {/*Price*/}
                              <MuiBox textAlign="right" width="55%">
                                {_.map(
                                  individual_adjustment.purchasePrices,
                                  (retail_price_ad, key) => (
                                    <MuiBox
                                      display="flex"
                                      flexDirection="row"
                                      key={key}
                                    >
                                      {key === 0 ? (
                                        <MuiBox
                                          textAlign="right"
                                          width="calc(100% - 75px)"
                                        >
                                          {formatNumber(retail_price_ad.price)}
                                        </MuiBox>
                                      ) : (
                                        <MuiBox
                                          textAlign="right"
                                          width="calc(100% - 75px)"
                                        >
                                          +{' '}
                                          {formatNumber(retail_price_ad.price)}
                                        </MuiBox>
                                      )}

                                      <MuiBox
                                        pl={0.5}
                                        textAlign="left"
                                        width="75px"
                                      >
                                        <span style={{ width: '10px' }}>
                                          €{' '}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            color: '#849096',
                                            lineHeight: '16px'
                                          }}
                                        >
                                          {handlePaymentFrequency(
                                            retail_price_ad.paymentFrequency
                                          )}
                                        </span>
                                      </MuiBox>
                                    </MuiBox>
                                  )
                                )}
                              </MuiBox>
                            </MuiBox>
                          )
                        )}
                    </MuiBox>
                    {!(
                      pricingDetailItem.packages &&
                      pricingDetailItem.packages.length - 1 === packageKey
                    ) && (
                      <MuiBox py={2}>
                        <div className={classes.line} />
                      </MuiBox>
                    )}
                  </MuiBox>
                ))}

                {/*ADD-ONS*/}
                {!_.isEmpty(pricingDetailItem.addons) && (
                  <>
                    <MuiBox py={2}>
                      <div className={classes.line} />
                    </MuiBox>
                    <MuiBox>
                      <H3>
                        <span style={{ color: '#849096' }}>{t('ADD-ONS')}</span>
                      </H3>
                    </MuiBox>
                    {_.map(pricingDetailItem.addons, (item, addonKey) => (
                      <MuiBox key={addonKey} pt={2}>
                        <MuiBox>
                          <MuiBox
                            display="flex"
                            flexDirection="row"
                            width="100%"
                          >
                            <MuiBox textAlign="left" width="45%">
                              <MuiBox>
                                <MuiBox className={classes.txtPackageName}>
                                  <span>{item.name}</span>
                                </MuiBox>
                              </MuiBox>
                            </MuiBox>

                            {/*Price*/}
                            <MuiBox textAlign="right" width="55%">
                              {!_.isEmpty(item.purchasePrices) &&
                                _.map(item.purchasePrices, (price, key) => (
                                  <MuiBox
                                    display="flex"
                                    flexDirection="row"
                                    key={key}
                                  >
                                    {key === 0 ? (
                                      <MuiBox
                                        textAlign="right"
                                        width="calc(100% - 75px)"
                                      >
                                        {formatNumber(price.price)}
                                      </MuiBox>
                                    ) : (
                                      <MuiBox
                                        textAlign="right"
                                        width="calc(100% - 75px)"
                                      >
                                        + {formatNumber(price.price)}
                                      </MuiBox>
                                    )}
                                    <MuiBox
                                      height="16px"
                                      pl={0.5}
                                      textAlign="left"
                                      width="75px"
                                    >
                                      <span
                                        style={{
                                          width: '10px'
                                        }}
                                      >
                                        €{' '}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          color: '#849096',
                                          lineHeight: '16px'
                                        }}
                                      >
                                        {handlePaymentFrequency(
                                          price.paymentFrequency
                                        )}
                                      </span>
                                    </MuiBox>
                                  </MuiBox>
                                ))}
                            </MuiBox>
                          </MuiBox>
                        </MuiBox>
                      </MuiBox>
                    ))}
                  </>
                )}

                {/*OTHERS*/}
                {!_.isEmpty(pricingDetailItem.individualAdjustments) && (
                  <>
                    <MuiBox py={2}>
                      <div className={classes.line} />
                    </MuiBox>
                    <MuiBox>
                      <H3>
                        <span style={{ color: '#849096' }}>{t('OTHERS')}</span>
                      </H3>
                    </MuiBox>
                    {_.map(
                      pricingDetailItem.individualAdjustments,
                      (item, key) => (
                        <MuiBox key={key} pt={2}>
                          <MuiBox>
                            <MuiBox
                              display="flex"
                              flexDirection="row"
                              width="100%"
                            >
                              <MuiBox textAlign="left" width="45%">
                                <MuiBox>
                                  <MuiBox className={classes.txtPackageName}>
                                    <span>{item.name}</span>
                                  </MuiBox>
                                </MuiBox>
                              </MuiBox>

                              {/*Price*/}
                              <MuiBox textAlign="right" width="55%">
                                {!_.isEmpty(item.purchasePrices) &&
                                  _.map(
                                    item.purchasePrices,
                                    (purchase_price, key) => (
                                      <MuiBox
                                        display="flex"
                                        flexDirection="row"
                                        key={key}
                                      >
                                        {key === 0 ? (
                                          <MuiBox
                                            textAlign="right"
                                            width="calc(100% - 75px)"
                                          >
                                            {formatNumber(purchase_price.price)}
                                          </MuiBox>
                                        ) : (
                                          <MuiBox
                                            textAlign="right"
                                            width="calc(100% - 75px)"
                                          >
                                            +{' '}
                                            {formatNumber(purchase_price.price)}
                                          </MuiBox>
                                        )}
                                        <MuiBox
                                          height="16px"
                                          pl={0.5}
                                          textAlign="left"
                                          width="75px"
                                        >
                                          <span
                                            style={{
                                              width: '10px'
                                            }}
                                          >
                                            €{' '}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: '12px',
                                              color: '#849096',
                                              lineHeight: '16px'
                                            }}
                                          >
                                            {handlePaymentFrequency(
                                              purchase_price.paymentFrequency
                                            )}
                                          </span>
                                        </MuiBox>
                                      </MuiBox>
                                    )
                                  )}
                              </MuiBox>
                            </MuiBox>
                          </MuiBox>
                        </MuiBox>
                      )
                    )}
                  </>
                )}

                {/*Summary*/}
                {!_.isEmpty(pricingDetailItem.summaries) && (
                  <>
                    <MuiBox py={2}>
                      <div className={classes.line} />
                    </MuiBox>
                    <MuiBox>
                      <MuiBox display="flex" flexDirection="row" width="100%">
                        <MuiBox textAlign="left" width="50%">
                          <H2>{t('Summary')}</H2>
                        </MuiBox>
                        <MuiBox height="16px" textAlign="right" width="50%">
                          <H2 />
                          {/*<H2>{t('items')}</H2>*/}
                        </MuiBox>
                      </MuiBox>
                    </MuiBox>

                    {_.map(pricingDetailItem.summaries, (item, key: any) => (
                      <>
                        {item.paymentFrequency === 0 &&
                          showContentTotalDetail(item, key)}
                      </>
                    ))}

                    {_.map(pricingDetailItem.summaries, (item, key: any) => (
                      <>
                        {item.paymentFrequency === 1 &&
                          showContentTotalDetail(item, key)}
                      </>
                    ))}

                    {_.map(pricingDetailItem.summaries, (item, key: any) => (
                      <>
                        {item.paymentFrequency === 2 &&
                          showContentTotalDetail(item, key)}
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </MuiBox>
        )}
      </MuiBox>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    currentSelectedOrderId: state.shopping.currentSelectedOrderId,
    pricingDetailItem: state.shopping.pricingDetailItem,
    currEmail: state.app.currEmail,
    selectedStyles: state.app.selectedStyles
  }
}

export default connect(mapStateToProps)(PricingDetail)
