import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { Login, ResetPassword } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import { useLoading, useLocale } from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { LoadingComponent } from '../components'
import { ApplicationState } from '../store'
import { updateAppState } from '../store/app/actions'
import { SelectedStyles } from '../store/app/types'
import { getProfile, updateProfile } from '../store/profile/actions'
import { BodyText, SmallText, ThemeProvider } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#849096',
      padding: '16px 0'
    },
    '.MuiFormLabel-root': {
      letterSpacing: 0,
      fontWeight: 600,
      lineHeight: '16px'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400,
      lineHeight: '24px',
      letterSpacing: 0
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

type SigninProps = {
  currEmail: string
  isAuthenticating?: boolean
  language?: string
  selectedStyles?: SelectedStyles
}

const SignInPage = (props: SigninProps): React.ReactElement => {
  const { selectedStyles, currEmail } = props
  const { t } = useTranslation()
  const {
    profile: {
      profile: { language, customerId }
    },
    app: { isAuthenticating }
  } = useSelector((state: ApplicationState) => state)

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false)
  const [isFailure, setIsFailure] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isSubmitBtn, setIsSubmitBtn] = useState<boolean>(false)
  const [values, setValues] = useState<Record<string, unknown>>({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState<string>('')
  const dispatch = useDispatch()
  const {
    goToDashboardPage,
    goToForgotPasswordPage,
    goToLandingPage
  } = useNavigation()
  const isLoading = useLoading()

  useEffect(() => {
    if (!currEmail) {
      goToLandingPage()
    }
  }, [currEmail])

  const onChangeValues = _.debounce((values: Record<string, unknown>) => {
    setValues(values)
  }, 200)

  const onLanguageChange = (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  if (showResetPassword) {
    return (
      <ResetPassword
        isFailure={isFailure}
        isSuccess={isSuccess}
        onBackToSendReset={() => {
          setShowResetPassword(false)
        }}
        onFailure={() => {
          setIsFailure(true)
        }}
        onNavigateBack={() => {
          setShowResetPassword(false)
          setIsFailure(false)
          setIsSuccess(false)
        }}
        onSuccess={() => {
          setIsSuccess(true)
        }}
      />
    )
  }

  const handleNextButton = async () => {
    dispatch(
      updateAppState({
        isAuthenticating: true
      })
    )
    Auth.signIn(currEmail, values.password as string)
      .then((user) => {
        setErrors('')
        goToDashboardPage()
        dispatch(getProfile(customerId as number))
        dispatch(
          updateAppState({
            isAuthenticating: false,
            loggedIn: true
          })
        )
      })
      .catch((err) => {
        const passwordFieldErrorMessage = t('The entered password is incorrect')
        setErrors(passwordFieldErrorMessage)
        dispatch(
          updateAppState({
            isAuthenticating: false,
            loggedIn: false
          })
        )
      })
  }

  if (isLoading) {
    return (
      <MuiBox
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
      >
        <LoadingComponent color={selectedStyles?.palette?.primary} />
      </MuiBox>
    )
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          <Login
            maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
            heightLoginFormOnMobile="93%"
            logoName={selectedStyles?.logo?.name}
            logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
            backgroundColor={selectedStyles?.palette?.primary}
            textColor={selectedStyles?.typography?.primaryColor}
            authEmailLabel="Email"
            authForgotPasswordLink="Forgot password?"
            authOrganizationLabel="Organization"
            authPasswordLabel={t('Password')}
            authSigninButtonLabel={t('Log in')}
            authTermOfServiceLink="Terms of service"
            customChildContent={
              <>
                <MuiBox pt={0.5} textAlign="left">
                  {isSubmitBtn && (
                    <>
                      <div>
                        <SmallText style={{ color: '#F44336' }}>
                          {errors}
                        </SmallText>
                      </div>
                    </>
                  )}
                </MuiBox>
                <MuiBox pt={2}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <a
                    onClick={goToForgotPasswordPage}
                    style={{
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#01579B',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                  >
                    {t('Forgot password?')}
                  </a>
                </MuiBox>
              </>
            }
            headerText={t('Log in')}
            isShowEmailField={false}
            isShowRememberMe={false}
            isShowSymbolField={false}
            isValidForm={true}
            isSubmitting={isAuthenticating}
            loadingIndicatorColor={selectedStyles?.palette?.primary}
            languages={[
              { value: 'en', label: 'English' },
              {
                value: 'de',
                label: 'Deutsch'
              }
            ]}
            languageValue={language}
            onChangeValues={onChangeValues}
            onLanguageChange={(event) => onLanguageChange(event)}
            onResetPassword={() => {
              setShowResetPassword(true)
            }}
            onSubmitForm={() => {
              setIsSubmitBtn(true)
              handleNextButton().then()
            }}
            rememberMeLabel="Remember me"
            subHeaderText={
              <BodyText>{t('Please enter your password')}</BodyText>
            }
          />
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles,
    currEmail: state.app.currEmail
  }
}

export default connect(mapStateToProps)(SignInPage)
