import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const HomeIcon = createSvgIcon(
  <>
    <title>home</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="1 14 16 2 31 14"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="13 30 13 22 19 22 19 30"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="5 16 5 30 27 30 27 16"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
