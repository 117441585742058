import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import letter from '../assets/images/letter.svg'
import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import useNavigation from '../common/hooks/navigation.hook'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import { BodyText, ThemeProvider } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222222',
      padding: '16px 0'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

type ActivateAccountProps = {
  selectedStyles?: SelectedStyles
  currEmail: string
}

const ActivationPending = (props: ActivateAccountProps): React.ReactElement => {
  const { selectedStyles } = props
  const {
    profile: {
      profile: { language }
    },
    app: { currEmail }
  } = useSelector((state: ApplicationState) => state)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isResendLinkFailed, setIsResendLinkFailed] = useState(false)
  const { goToLandingPage } = useNavigation()

  useEffect(() => {
    if (!currEmail) {
      goToLandingPage()
    }
  }, [currEmail])

  const onLanguageChange = async (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  const handleSubmitButton = async () => {
    try {
      setIsLoading(true)
      await Auth.resendSignUp(currEmail)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setIsResendLinkFailed(true)
      console.log(e)
    }
  }

  const onChangeValues = (values: Record<string, unknown>) => {}

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          <Login
            maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
            heightLoginFormOnMobile="93%"
            logoName={selectedStyles?.logo?.name}
            logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
            backgroundColor={selectedStyles?.palette?.primary}
            textColor={selectedStyles?.typography?.primaryColor}
            authSigninButtonLabel={
              isResendLinkFailed ? t('Try again') : t('Resend link')
            }
            headerText={
              isResendLinkFailed
                ? t('Resend link failed')
                : t('Activation pending')
            }
            isShowButtonSubmitForm={true}
            isShowEmailField={false}
            isShowPasswordField={false}
            isShowRememberMe={false}
            isShowSymbolField={false}
            isSubmitting={isLoading}
            isValidForm={true}
            languages={[
              { value: 'en', label: 'English' },
              {
                value: 'de',
                label: 'Deutsch'
              }
            ]}
            languageValue={language}
            loadingIndicatorColor={selectedStyles?.palette?.primary}
            onChangeValues={onChangeValues}
            onLanguageChange={(event) => onLanguageChange(event)}
            onSubmitForm={
              isResendLinkFailed ? goToLandingPage : handleSubmitButton
            }
            subHeaderText={
              isResendLinkFailed ? (
                <BodyText>{t('Resend link failed detail')}</BodyText>
              ) : (
                <BodyText>{t('Activation detail')}</BodyText>
              )
            }
            titleIcon={
              <img alt="No results found" height="96" src={letter} width="96" />
            }
          />
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles,
    currEmail: state.app.currEmail
  }
}

export default connect(mapStateToProps)(ActivationPending)
