import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import signup from '../assets/images/signup.svg'
import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import { useLoading } from '../common/hooks/custom.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { LoadingComponent } from '../components'
import { ApplicationState } from '../store'
import { SelectedStyles, TermsPrivacyAndEmail } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import { BodyText, SmallText, ThemeProvider } from '../tmp-ui-element'

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222222',
      padding: '16px 0'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    termsTextInForm: {
      '& p': {
        color: '#849096',
        fontSize: '12px',
        letterSpacing: '0.5px',
        '& strong': {
          fontWeight: 600,
          fontSize: '12px',
        },
        '& a': {
          textDecoration: 'none',
          color: '#01579B',
          fontSize: '12px',
          '& strong': {
            fontSize: '12px',
            color: '#01579B',
            fontWeight: 600
          }
        }
      }
    }
  })
)

type SignupProps = {
  selectedStyles?: SelectedStyles
  currEmail: string,
  termsPrivacyAndSupportEmail?: TermsPrivacyAndEmail
}

const SignUpPage = (props: SignupProps): React.ReactElement => {
  const classes = useStyles()
  const { selectedStyles, currEmail, termsPrivacyAndSupportEmail } = props
  const {
    profile: {
      profile: { language }
    }
  } = useSelector((state: ApplicationState) => state)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useLoading()

  const { goToLandingPage, goToCreateAccountPage } = useNavigation()

  useEffect(() => {
    if (!currEmail) {
      goToLandingPage()
    }
  }, [currEmail])

  const validateSchema = () => {
    return Yup.object().shape({})
  }

  const onLanguageChange = async (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  console.log('termsPrivacyAndSupportEmail?.terms_privacy: ', termsPrivacyAndSupportEmail?.terms_privacy)

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          {isLoading ? (
            <MuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <LoadingComponent color={selectedStyles?.palette?.primary} />
            </MuiBox>
          ) : (
            <Login
              maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
              heightLoginFormOnMobile="93%"
              logoName={selectedStyles?.logo?.name}
              logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
              backgroundColor={selectedStyles?.palette?.primary}
              textColor={selectedStyles?.typography?.primaryColor}
              authForgotPasswordLink={t('Forgot password?')}
              authSigninButtonLabel={t('Create an account')}
              authTermOfServiceLink={t('Terms of service')}
              headerText={t('Create a portal account')}
              isShowEmailField={false}
              isShowPasswordField={false}
              isShowRememberMe={false}
              isShowSymbolField={false}
              isValidForm={true}
              languages={[
                { value: 'en', label: 'English' },
                {
                  value: 'de',
                  label: 'Deutsch'
                }
              ]}
              languageValue={language}
              onLanguageChange={(event) => onLanguageChange(event)}
              onResetPassword={() => true}
              onSubmitForm={goToCreateAccountPage}
              rememberMeLabel="Remember me"
              subHeaderText={
                <BodyText color={'secondary'}>
                  {t('Your email has been used to')}
                </BodyText>
              }
              termsTextInForm={
                <div className={classes.termsTextInForm}>
                  {ReactHtmlParser(
                    termsPrivacyAndSupportEmail?.terms_privacy
                      ? termsPrivacyAndSupportEmail?.terms_privacy
                      : ''
                  )}
                </div>
              }
              titleIcon={
                <img
                  alt="No results found"
                  height="96"
                  src={signup}
                  width="96"
                />
              }
              validateSchema={validateSchema}
            />
          )}
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    selectedStyles: state.app.selectedStyles,
    currEmail: state.app.currEmail,
    termsPrivacyAndSupportEmail: state.app.termsPrivacyAndSupportEmail
  }
}

export default connect(mapStateToProps)(SignUpPage)
