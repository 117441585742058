import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const NewsletterIcon = createSvgIcon(
  <>
    <title>newsletter</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="6,11 1,13.9 1,31 31,31 31,13.9 26,11 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="6,16.7 6,1 26,1 26,16.6 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="1"
        x2="31"
        y1="13.9"
        y2="31"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="31"
        x2="15.6"
        y1="13.9"
        y2="22.2"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="21"
        y1="7"
        y2="7"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="21"
        y1="12"
        y2="12"
      />
    </g>
  </>
)
