import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const NetworkIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="4"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="4"
        cy="9"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="28"
        cy="9"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="17"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M6,29.356 c0-2.025,1.507-3.722,3.517-3.972C11.17,25.178,13.362,25,16,25s4.83,0.178,6.483,0.384C24.493,25.634,26,27.331,26,29.356V31H6 V29.356z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
