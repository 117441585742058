import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const OfficeIcon = createSvgIcon(
  <>
    <title>office</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-color="color-2"
        fill="none"
        points=" 23,11 23,4 9,4 9,15 "
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="14"
        x2="17.938"
        y1="31"
        y2="31"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="1"
        y2="4"
      />{' '}
      <rect
        fill="none"
        height="12"
        stroke="#222222"
        strokeMiterlimit="10"
        width="13"
        x="1"
        y="19"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="6"
        x2="9"
        y1="23"
        y2="23"
      />{' '}
      <rect
        fill="none"
        height="16"
        stroke="#222222"
        strokeMiterlimit="10"
        width="13"
        x="18"
        y="15"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="23"
        x2="26"
        y1="23"
        y2="23"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="23"
        x2="26"
        y1="19"
        y2="19"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="6"
        x2="9"
        y1="27"
        y2="27"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="23"
        x2="26"
        y1="27"
        y2="27"
      />
    </g>
  </>
)
