import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ReplyAllIcon = createSvgIcon(
  <>
    <title>reply all</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M18,4v7c8,0,13,8,13,14 v1.947c0,0-4-6.947-13-6.947v6.947L7,15L18,4z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 14,28 2,15 14,3 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
