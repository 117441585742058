import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHouseWaterIcon = createSvgIcon(
  <>
    <title>product house water</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="house-water"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <path
            d="M15 9c-.167 0-.207 1-.367 1.469-.106.313-.286.73-.54 1.25-.263.516-.494.94-.693 1.27-.199.33-.52.812-.965 1.444-.403.617-.7 1.082-.894 1.396-.29.472-.547.937-.682 1.212-.136.275-.859 1.33-.859 2.792 0 1.463.482 2.27.947 2.939.466.669 1.488 1.555 2.059 1.834.57.28 1.121.394 1.994.394s1.426-.13 1.96-.394c.535-.264 1.403-.92 2.081-1.834.679-.915 1.047-1.908.941-3.095a6.154 6.154 0 0 0-.833-2.636c-.375-.664-.552-.986-.858-1.451a781.2 781.2 0 0 1-.758-1.157 24.978 24.978 0 0 1-.897-1.354c-.182-.305-.44-.759-.774-1.36-.241-.506-.41-.913-.509-1.222C15.206 10.034 15.167 9 15 9z"
            id="Path-24"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.012 20.597s-.26.637-.614 1.158a3.12 3.12 0 0 1-.96.896"
            id="Line-47"
            strokeLinecap="round"
          />
          <path
            d="M14.318.637L1.402 12.69A.756.756 0 0 0 1.918 14h1.719c.11 0 .2.09.2.2V26a2 2 0 0 0 2 2h18.316a2 2 0 0 0 2-2V14.2c0-.11.09-.2.2-.2h1.723a.758.758 0 0 0 .518-1.313L15.682.637a1 1 0 0 0-1.364 0z"
            id="Shape"
            strokeLinecap="square"
          />
        </g>
      </g>
    </g>
  </>
)
