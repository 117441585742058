import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const QuickviewIcon = createSvgIcon(
  <>
    <title>quickview</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="11"
        x2="2"
        y1="12"
        y2="12"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="11"
        x2="2"
        y1="20"
        y2="20"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="21"
        x2="2"
        y1="28"
        y2="28"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="21"
        x2="2"
        y1="4"
        y2="4"
      />{' '}
      <circle
        cx="21"
        cy="16"
        data-color="color-2"
        fill="none"
        r="6"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="30"
        x2="25.243"
        y1="25"
        y2="20.243"
      />
    </g>
  </>
)
