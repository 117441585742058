import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const WidgetIcon = createSvgIcon(
  <>
    <title>widget</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="1"
        x2="31"
        y1="11"
        y2="11"
      />{' '}
      <rect
        fill="none"
        height="26"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="3"
      />{' '}
      <circle
        cx="5.25"
        cy="7.25"
        data-stroke="none"
        fill="#222222"
        r="1.25"
        stroke="none"
      />{' '}
      <circle
        cx="9.75"
        cy="7.25"
        data-stroke="none"
        fill="#222222"
        r="1.25"
        stroke="none"
      />
    </g>
  </>
)
