import {
  AppBar,
  Box as MuiBox,
  Breadcrumbs,
  Link,
  Toolbar
} from '@material-ui/core'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  ADDRESS_PAGE,
  CHANGE_PASSWORD_PAGE,
  DASHBOARD_PAGE,
  IVY_SERVER_API_URL,
  LANGUAGE_PAGE,
  METERS_PAGE,
  ORDER_DETAIL_PAGE,
  ORDERS_PAGE,
  PROFILE_DETAIL_PAGE,
  PROFILE_PAGE,
  SUBSCRIPTION_PAGE
} from '../common/constants'
import useCurrentScreen from '../common/hooks/current-screen.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { logOut } from '../store/app/actions'
import { SelectedStyles } from '../store/app/types'
import { Profile } from '../store/profile/types'
import {
  ArrowLeftIcon,
  Avatar,
  CustomerIcon,
  EpilotWhiteLogoIcon,
  MenuIcon,
  SmallRightIcon
} from '../tmp-ui-element'

type HeaderProps = {
  openSidebar?: () => void
  currProfile?: Profile
  isLoading?: boolean
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    '.MuiLink-underlineHover:hover': {
      textDecoration: 'none'
    }
  }
})(() => null)

type PropsStyle = {
  primaryColor: string | undefined
}
const useStyles = makeStyles((props) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: '22px'
      }
    },
    breadcrumb: {
      color: '#222222',
      cursor: 'text',
      fontSize: '16px',
      '&:hover': {
        color: '#222222'
      }
    },
    breadcrumbActive: {
      color: '#01579B',
      fontSize: '16px',
      cursor: 'pointer',
      '&:hover': {
        color: '#01579B'
      }
    },
    headerWrapper: {
      width: 'calc(100vw - 240px)',
      display: 'flex',
      borderBottom: '1px solid #D2DEE4 !important',
      boxShadow: 'none',
      zIndex: 3,
      '& .MuiToolbar-regular': {
        minHeight: '56px !important'
      } /* eslint-disable */,
      ['@media (max-width:780px)']: {
        minHeight: '56px !important',
        width: '100%'
      }
    },
    profileSettingWrapper: {
      display: 'flex' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'none'
      }
    },
    titleStyle: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex',
        width: '100%',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px'
      }
    },
    menuButton: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex'
      }
    },
    logoButton: {
      display: 'none' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        display: 'flex'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    '& ul': {
      padding: '8px 0',
      minWidth: '192px',
      backgroundColor: '#FFFFFF',
      '& li': {
        cursor: 'pointer',
        '&:focus': {
          backgroundColor: 'transparent'
        },
        '&:hover': {
          backgroundColor: '#EFF2F3'
        }
      }
    }
  }
})((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

type AppBarProps = {
  color: string | undefined
}

export const AppBarContainer = styled.div<AppBarProps>`
  header {
    background-color: #FFFFFF;
    z-index: 9999;
  };
  @media (max-width:780px) {
    header {
      background-color: ${(props) => props.color ? props.color : '#039BE5'};
    },
  },
`

const ORDER_CHILDREN_PAGES = [ORDER_DETAIL_PAGE]
const PROFILE_CHILDREN_PAGES = [
  PROFILE_DETAIL_PAGE,
  ADDRESS_PAGE,
  CHANGE_PASSWORD_PAGE,
  SUBSCRIPTION_PAGE,
  LANGUAGE_PAGE
]
const MAIN_PAGES = [DASHBOARD_PAGE, METERS_PAGE, PROFILE_PAGE, ORDERS_PAGE]

const Header = (props: HeaderProps): React.ReactElement => {
  const { t } = useTranslation()
  const { openSidebar, currProfile, isLoading, selectedStyles } = props
  const [isShowBackButton, setIsShowBackButton] = useState(false)
  const [isShowMenuButton, setIsShowMenuButton] = useState(false)
  const [backButtonText, setBackButtonText] = useState('')
  const location = useLocation()
  const history = useHistory()
  const { pathname: currentPath } = location
  const { goBack } = useNavigation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    isDashboardPage,
    isOrdersPage,
    isMetersPage,
    isOrderDetailPage,
    isProfileDetailPage,
    isProfilePage,
    isAddressPage,
    isChangePasswordPage,
    isSubscriptionPage,
    isLanguagePage
  } = useCurrentScreen()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const {
    goToDashboardPage,
    goToOrdersPage,
    goToProfilePage
  } = useNavigation()

  useEffect(() => {
    if (MAIN_PAGES.includes(currentPath)) {
      setIsShowBackButton(false)
      setIsShowMenuButton(true)
    } else {
      switch (currentPath) {
        case ORDER_DETAIL_PAGE:
          setIsShowBackButton(true)
          setIsShowMenuButton(false)
          setBackButtonText('Orders')
          break
        // case PRICING_DETAIL_PAGE:
        //   setIsShowBackButton(true)
        //   setIsShowMenuButton(false)
        //   setBackButtonText('Order details')
        //   break
        case PROFILE_DETAIL_PAGE:
        case LANGUAGE_PAGE:
        case ADDRESS_PAGE:
        case CHANGE_PASSWORD_PAGE:
        case SUBSCRIPTION_PAGE:
          setIsShowBackButton(true)
          setIsShowMenuButton(false)
          setBackButtonText('My profile')
          break
      }
    }
  }, [currentPath])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    handleClose()
    dispatch(logOut({ location, history }))
  }

  const showDashboard = () => {
    if (!isLoading) {
      goToDashboardPage()
    }
  }

  const showOrders = () => {
    if (!isLoading) {
      goToOrdersPage()
    }
  }

  const showProfile = () => {
    if (!isLoading) {
      goToProfilePage()
    }
    handleClose()
  }

  return (
    <>
      <GlobalCss />
      <AppBarContainer color={selectedStyles?.palette?.primary}>
        <AppBar
          className={classes.headerWrapper}
          position="fixed"
          // color='primary'
        >
          <Toolbar>
            <>
              {/*Desktop*/}
              {!isLoading && (
                <MuiBox
                  alignItems="center"
                  className={classes.profileSettingWrapper}
                  display="flex"
                  flexDirection="row"
                  width="100%"
                >
                  {/*line*/}
                  <MuiBox
                    alignItems="flex-start"
                    display="flex"
                    flexDirection="row"
                    width="100%"
                  >
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<SmallRightIcon />}
                    >
                      {!isDashboardPage() && (
                        <Link
                          className={classes.breadcrumbActive}
                          onClick={() => showDashboard()}
                        >
                          {t('Home')}
                        </Link>
                      )}
                      {isMetersPage() && (
                        <Link className={classes.breadcrumb}>{t('Meters')}</Link>
                      )}
                      {(isOrdersPage() ||
                        ORDER_CHILDREN_PAGES.includes(currentPath)) && (
                        <Link
                          className={`${
                            isOrderDetailPage()
                              ? classes.breadcrumbActive
                              : classes.breadcrumb
                          }`}
                          onClick={() => {
                            if (isOrderDetailPage()) {
                              showOrders()
                            }
                          }}
                        >
                          {t('Orders')}
                        </Link>
                      )}
                      {(isOrderDetailPage() ||
                        ORDER_CHILDREN_PAGES.includes(currentPath)) && (
                        <Link className={classes.breadcrumb}>
                          {t('Order details')}
                        </Link>
                      )}
                      {(isProfilePage() ||
                        PROFILE_CHILDREN_PAGES.includes(currentPath)) && (
                        <Link
                          className={`${
                            PROFILE_CHILDREN_PAGES.includes(currentPath)
                              ? classes.breadcrumbActive
                              : classes.breadcrumb
                          }`}
                          onClick={() => {
                            if (PROFILE_CHILDREN_PAGES.includes(currentPath)) {
                              showProfile()
                            }
                          }}
                        >
                          {t('My profile')}
                        </Link>
                      )}
                      {isProfileDetailPage() && (
                        <Link className={classes.breadcrumb}>
                          {t('Personal info')}
                        </Link>
                      )}
                      {isAddressPage() && (
                        <Link className={classes.breadcrumb}>
                          {t('Addresses')}
                        </Link>
                      )}
                      {isChangePasswordPage() && (
                        <Link className={classes.breadcrumb}>
                          {t('Password')}
                        </Link>
                      )}
                      {isSubscriptionPage() && (
                        <Link className={classes.breadcrumb}>
                          {t('Subscription')}
                        </Link>
                      )}
                      {isLanguagePage() && (
                        <Link className={classes.breadcrumb}>
                          {t('Language')}
                        </Link>
                      )}
                    </Breadcrumbs>
                  </MuiBox>

                  {/*Profile setting*/}
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                    width="32px"
                  >
                    {currProfile && currProfile.avatar ? (
                      <>
                        <Avatar
                          backgroundColor="#FFFFFF"
                          style={{ width: '32px', height: '32' }}
                        >
                          <img
                            alt=""
                            src={currProfile && currProfile.avatar}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </Avatar>
                      </>
                    ) : (
                      <Avatar
                        backgroundColor="#FFFFFF"
                        style={{ width: '32px', height: '32px' }}
                      >
                        <CustomerIcon color="#849096" size="smallest" />
                      </Avatar>
                    )}
                  </MuiBox>

                  <StyledMenu
                    anchorEl={anchorEl}
                    id="customized-menu"
                    keepMounted
                    onClose={handleClose}
                    open={Boolean(anchorEl)}
                  >
                    <StyledMenuItem onClick={showProfile} style={{backgroundColor: `${(isProfilePage() && '#EFF2F3') || ''}`}}>
                      {t('My profile')}
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleLogOut}>
                      {t('Logout')}
                    </StyledMenuItem>
                  </StyledMenu>
                </MuiBox>
              )}

              {/*Mobile*/}
              {isShowMenuButton && (
                <>
                  <MuiBox
                    aria-label="menu"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={openSidebar}
                    style={{position: 'absolute'}}
                  >
                    <MenuIcon color={selectedStyles?.typography.primaryColor} />
                  </MuiBox>
                  <MuiBox
                    alignItems="center"
                    className={classes.logoButton}
                    flexDirection="row"
                    justifyContent="center"
                    style={{ width: '100%' }}
                  >
                    {selectedStyles?.logo?.url ? (
                      <img
                        alt={selectedStyles?.logo?.name}
                        src={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
                        style={{ maxHeight: '30px', maxWidth: '100px' }}
                        onClick={goToDashboardPage}
                      />
                    ) : (
                      <EpilotWhiteLogoIcon
                        style={{ height: '56px', width: '120px' }}
                        viewBox="0 0 340 81"
                      />
                    )}
                  </MuiBox>
                </>
              )}
              {isShowBackButton && (
                <>
                  <MuiBox
                    aria-label="menu"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={goBack}
                  >
                    <ArrowLeftIcon color="#ffffff" />
                  </MuiBox>
                  <MuiBox
                    alignItems="center"
                    className={classes.titleStyle}
                    display="flex"
                    flexDirection="row"
                    justifyContent="left"
                  >
                    {t(backButtonText)}
                  </MuiBox>
                </>
              )}
            </>
          </Toolbar>
        </AppBar>
      </AppBarContainer>
    </>
  )
}

export default Header
