import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PaymentIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M8,4V3c0-1.105,0.895-2,2-2 h0c1.105,0,2,0.895,2,2v1"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M12,4V3 c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v1"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M16,4V3 c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v1"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M20,4V3 c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v1"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="18.244,21 1,21 1,7 31,7 31,21 27,21 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="14"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M8,21v0.5 c0,5.247,4.253,9.5,9.5,9.5h0c5.247,0,9.5-4.253,9.5-9.5V21v-9c-2.259,0-4,1.741-4,4v3c-3.083,0-6,2.167-6,5"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
    </g>
  </>
)
