export enum ShoppingActionTypes {
  LOAD_ACCESS_TOKEN_REQUEST = '@@shopping/LOAD_ACCESS_TOKEN_REQUEST',
  LOAD_ACCESS_TOKEN_SUCCESS = '@@shopping/LOAD_ACCESS_TOKEN_SUCCESS',
  LOAD_ACCESS_TOKEN_FAILURE = '@@shopping/LOAD_ACCESS_TOKEN_FAILURE',

  LOAD_ORDERS_REQUEST = '@@shopping/LOAD_ORDERS_REQUEST',
  LOAD_ORDERS_SUCCESS = '@@shopping/LOAD_ORDERS_SUCCESS',
  LOAD_ORDERS_FAILURE = '@@shopping/LOAD_ORDERS_FAILURE',

  LOAD_RECENT_ORDERS_REQUEST = '@@shopping/LOAD_RECENT_ORDERS_REQUEST',
  LOAD_RECENT_ORDERS_SUCCESS = '@@shopping/LOAD_RECENT_ORDERS_SUCCESS',
  LOAD_RECENT_ORDERS_FAILURE = '@@shopping/LOAD_RECENT_ORDERS_FAILURE',

  UPDATE_CURRENT_ID_REQUEST = '@@shopping/UPDATE_CURRENT_ID_REQUEST',
  UPDATE_CURRENT_ID_SUCCESS = '@@shopping/UPDATE_CURRENT_ID_SUCCESS',
  UPDATE_CURRENT_ID_FAILURE = '@@shopping/UPDATE_CURRENT_ID_FAILURE',

  LOAD_ORDER_DETAIL_REQUEST = '@@shopping/LOAD_ORDER_DETAIL_REQUEST',
  LOAD_ORDER_DETAIL_SUCCESS = '@@shopping/LOAD_ORDER_DETAIL_SUCCESS',
  LOAD_ORDER_DETAIL_FAILURE = '@@shopping/LOAD_ORDER_DETAIL_FAILURE',

  LOAD_PRICING_DETAIL_REQUEST = '@@shopping/LOAD_PRICING_DETAIL_REQUEST',
  LOAD_PRICING_DETAIL_SUCCESS = '@@shopping/LOAD_PRICING_DETAIL_SUCCESS',
  LOAD_PRICING_DETAIL_FAILURE = '@@shopping/LOAD_PRICING_DETAIL_FAILURE',

  LOAD_STATUS_REQUEST = '@@shopping/LOAD_STATUS_REQUEST',
  LOAD_STATUS_SUCCESS = '@@shopping/LOAD_STATUS_SUCCESS',
  LOAD_STATUS_FAILURE = '@@shopping/LOAD_STATUS_FAILURE',

  LOAD_METERS_REQUEST = '@@shopping/LOAD_METERS_REQUEST',
  LOAD_METERS_SUCCESS = '@@shopping/LOAD_METERS_SUCCESS',
  LOAD_METERS_FAILURE = '@@shopping/LOAD_METERS_FAILURE',

  LOAD_METER_HISTORY_REQUEST = '@@shopping/LOAD_METER_HISTORY_REQUEST',
  LOAD_METER_HISTORY_SUCCESS = '@@shopping/LOAD_METER_HISTORY_SUCCESS',
  LOAD_METER_HISTORY_FAILURE = '@@shopping/LOAD_METER_HISTORY_FAILURE',

  TRANSMIT_METERS_READING_REQUEST = '@@shopping/TRANSMIT_METERS_READING_REQUEST',
  TRANSMIT_METERS_READING_SUCCESS = '@@shopping/TRANSMIT_METERS_READING_SUCCESS',
  TRANSMIT_METERS_READING_FAILURE = '@@shopping/TRANSMIT_METERS_READING_FAILURE',

  LOAD_ORDER_ADDRESSES_REQUEST = '@@shopping/LOAD_ORDER_ADDRESSES_REQUEST',
  LOAD_ORDER_ADDRESSES_SUCCESS = '@@shopping/LOAD_ORDER_ADDRESSES_SUCCESS',
  LOAD_ORDER_ADDRESSES_FAILURE = '@@shopping/LOAD_ORDER_ADDRESSES_FAILURE'
}

/*
 ** REDUX STATE
 */
type RetailPrice = {
  paymentFrequency: number
  price: number
}

type PurchasePrice = {
  paymentFrequency?: number
  price: number
}

type Addon = {
  name?: string
  purchasePrices: PurchasePrice[]
}

type IndividualAdjustment = {
  name?: string
  purchasePrices: PurchasePrice[]
}

type ProductType = {
  type: number
  quantity: number
}

type Tax = {
  name?: string
  rate?: string
  amount: number
}

type Summary = {
  paymentFrequency?: number
  subtotal: number
  taxes?: Tax[]
  total: number
}

type Package = {
  name?: string
  purchasePrices: PurchasePrice[]
  addons?: Addon[]
  individualAdjustments?: IndividualAdjustment[]
}

export type Order = {
  id: number
  symbolId: string
  packageNames: string
  status: number
  isPriceSubjectToChange: boolean
  retailPrices: RetailPrice[]
  productTypes: ProductType[]
}

export type OrderDetailItem = {
  id?: number
  symbolId: string
  packageNames: string
  status: number
  isPriceSubjectToChange: boolean
  retailPrices: RetailPrice[]
  productTypes: ProductType[]
}

export type PricingDetailItem = {
  isPriceSubjectToChange: boolean
  packages?: Package[]
  addons?: Addon[]
  individualAdjustments?: IndividualAdjustment[]
  summaries?: Summary[]
}

export type SubAddresses = {
  id?: number
  streetName?: string
  streetNumber?: string
  postcode?: string
  city?: string
  country?: string
}
export type Addresses = {
  billingAddress: SubAddresses
  deliveryAddress: SubAddresses
}

export type Status = {
  status?: number
  datetime?: string
}

export type Sector = {
  id?: number
  name?: string
  source?: number
}

export type MeterAddress = {
  street?: string
  streetNumber?: string
  postcode?: string
  city?: string
}

export type MeterUnit = {
  id?: string
  value?: string
  source?: number
}

export type LastMeterReading = {
  value?: string
  readingDate?: string
  unit?: MeterUnit
  author?: string
}

export type Meter = {
  id: number
  meterNumber?: string
  sector?: Sector
  address?: MeterAddress
  package?: string
  lastMeterReading?: LastMeterReading
  metersHistory?: MeterHistory[]
}

export type MeterHistory = {
  value?: string
  readingDate?: string
  author?: string
  unit?: MeterUnit
  difference?: number
}

export interface ShoppingState {
  token: Record<string, unknown>
  loadingOrderIdSelection: boolean
  loadingOrderDetailItem: boolean
  loadingPricingDetailItem: boolean
  loadingOrderAddresses: boolean
  loadingStatus: boolean
  loadingOrders: boolean
  loadingMeters: boolean | null
  loadingMeterHistory: boolean | null
  loadingTransmitMetersReading: boolean | null
  loadingRecentOrders: boolean
  currentSelectedOrderId: number
  orderDetailItem: OrderDetailItem
  pricingDetailItem: PricingDetailItem
  orderAddresses: Addresses
  status: Status[]
  orders: Order[]
  recentOrders: OrderDetailItem[]
  meters: Meter[]
  meterHistories: Record<number, MeterHistory[]>
  errorMessageTMR: string | null
}
