import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const SmallRemoveIcon = createSvgIcon(
  <>
    <title>small remove</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="22"
        x2="10"
        y1="10"
        y2="22"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="22"
        x2="10"
        y1="22"
        y2="10"
      />
    </g>
  </>
)
