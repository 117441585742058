import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductHeatingIcon = createSvgIcon(
  <>
    <title>product heating</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="heating"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <path
            d="M25 2.5h2.5v4H25"
            id="Line"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M27.5 4.5h2" id="Line-2" strokeLinecap="round" />
          <path
            d="M5 22H2.5v4H5"
            id="Line"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M.5 24h2" id="Line-2" strokeLinecap="round" />
          <rect height="27" id="Rectangle" rx="2.5" width="5" x="5" y=".5" />
          <rect
            height="27"
            id="Rectangle-Copy-135"
            rx="2.5"
            width="5"
            x="10"
            y=".5"
          />
          <rect
            height="27"
            id="Rectangle-Copy-136"
            rx="2.5"
            width="5"
            x="15"
            y=".5"
          />
          <rect
            height="27"
            id="Rectangle-Copy-137"
            rx="2.5"
            width="5"
            x="20"
            y=".5"
          />
        </g>
      </g>
    </g>
  </>
)
