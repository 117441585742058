import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const TriangleDownIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <path
        d="M16,26c0.3,0,0.6-0.2,0.8-0.4l13-18c0.2-0.3,0.3-0.7,0.1-1C29.7,6.2,29.4,6,29,6H3C2.6,6,2.3,6.2,2.1,6.5 C1.9,6.9,2,7.3,2.2,7.6l13,18C15.4,25.8,15.7,26,16,26z"
        fill="#222222"
      />
    </g>
  </>
)
