import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const PreviewIcon = createSvgIcon(
  <>
    <title>preferences</title>
    <g
      className="nc-icon-wrapper"
      fill="#ffffff"
      stroke="#ffffff"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M1.40904 17.182C1.14533 16.8437 1.00146 16.4273 1.00001 15.9984C0.998563 15.5694 1.13962 15.1521 1.40104 14.812C3.42204 12.162 8.88604 6 16 6C23.02 6 28.536 12.158 30.585 14.81C30.8499 15.1501 30.9937 15.5689 30.9937 16C30.9937 16.4311 30.8499 16.8499 30.585 17.19C28.536 19.842 23.02 26 16 26C8.98004 26 3.45304 19.828 1.40904 17.182Z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
        stroke="#222222"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
