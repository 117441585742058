import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { Button } from '../button'
import { SmallCheckIcon, SmallRemoveIcon } from '../icons'

export interface InlineEditButtonsProps {
  onCancelEditClick?: React.MouseEventHandler
  onEditClick?: React.MouseEventHandler
}

export const InlineEditButtons = (
  props: InlineEditButtonsProps
): React.ReactElement => {
  const { onCancelEditClick, onEditClick } = props
  const useStyles = makeStyles({
    button: {
      padding: '0',
      height: '24px',
      minWidth: '24px',
      marginLeft: '4px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer'
    },
    buttonContainer: {
      position: 'absolute',
      right: '0',
      bottom: '-12px'
    }
  })
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer} id="modify-buttons">
      <Button
        className={classes.button}
        color="secondary"
        onMouseDown={onCancelEditClick}
        variant="outlined"
      >
        <SmallRemoveIcon size="small"></SmallRemoveIcon>
      </Button>
      <Button
        className={classes.button}
        color="primary"
        onMouseDown={onEditClick}
        variant="outlined"
      >
        <SmallCheckIcon color="#039BE5" size="small"></SmallCheckIcon>
      </Button>
    </div>
  )
}
