import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductSolarIcon = createSvgIcon(
  <>
    <title>product solar</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="solar"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(1 2)">
          <path d="M5 27h20" id="Line-5" strokeLinecap="round" />
          <path
            d="M10 27v-3h10v3"
            id="Line-7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M15 19v5" id="Line-8" strokeLinecap="round" />
          <path
            d="M4.709 0h20.582a2 2 0 0 1 1.976 1.688l2.368 15A2 2 0 0 1 27.659 19H2.341a2 2 0 0 1-1.976-2.312l2.368-15A2 2 0 0 1 4.71 0z"
            id="Rectangle"
          />
          <path d="M.778 16H29" id="Line-5" strokeLinecap="round" />
          <path d="M2.5 5h25" id="Line-5-Copy" strokeLinecap="round" />
          <path d="M1.5 10.5h27" id="Line-5-Copy-2" strokeLinecap="round" />
          <path d="M11.692.5l-1.579 14.917" id="Line-6" strokeLinecap="round" />
          <path
            d="M19.887.5l-1.58 14.917"
            id="Line-6-Copy"
            strokeLinecap="round"
            transform="matrix(-1 0 0 1 38.194 0)"
          />
        </g>
      </g>
    </g>
  </>
)
