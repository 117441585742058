import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useCurrentScreen from '../common/hooks/current-screen.hook'
import useNavigation from '../common/hooks/navigation.hook'
import { logOut } from '../store/app/actions'
import { AppState, SelectedStyles } from '../store/app/types'
import { Profile } from '../store/profile/types'
import {
  Avatar,
  BulletTwoIcon,
  Cart,
  DashboardIcon,
  Drawer,
  H2,
  LeaveIcon,
  MeterIcon,
  SmallRightIcon
} from '../tmp-ui-element'
import { Anchor } from '../types'

type SidebarOnMobileProps = {
  onClose?: () => void
  anchor: Anchor
  open: boolean
  currProfile?: Profile
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    /* eslint-disable */
    '@media only screen and (max-width: 780px)': {
      body: {}
    },
    '.MuiPaper-outlined': {
      border: '1px solid #D2DEE4'
    },
    '.MuiBackdrop-root': {
      opacity: '0.7 !important'
    },
    '.MuiDrawer-paperAnchorLeft': {
      backgroundColor: '#F9FAFB',
      width: '82vw'
    },
    '.MuiDialogContent-root': {
      padding: '16px 0'
    },
    // '.MuiDialogTitle-root': {
    //   backgroundColor: '#039BE5'
    // },
    '.MuiSvgIcon-root': {
      color: '#ffffff',
      position: 'relative'
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    comingSoon: {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    size: {
      width: '80px',
      height: '80px'
    },
    rightIcon: {
      position: 'absolute',
      right: '10px'
    }
  })
)

type DrawerProps = {
  bgColor: string | undefined
}

export const DrawerContainer = styled.div<DrawerProps>`
  div:nth-child(1) {
    max-height: 100%;
  };
  .MuiDialogTitle-root {
    background-color: ${(props) => props.bgColor ? props.bgColor : 'red'};
  };
`

const SidebarMobile = (props: SidebarOnMobileProps): React.ReactElement => {
  const { t } = useTranslation()
  const { onClose = () => ({}), anchor, open, currProfile, selectedStyles } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const classes = useStyles()

  const {
    goToDashboardPage,
    goToProfilePage,
    goToOrdersPage,
    goToMetersPage
  } = useNavigation()

  const {
    isDashboardPage,
    isOrdersPage,
    isMetersPage,
    isProfilePage
  } = useCurrentScreen()

  const showDashboard = () => {
    goToDashboardPage()
    isDashboardPage() && onClose()
  }

  const showProfile = () => {
    goToProfilePage()
    isProfilePage() && onClose()
  }

  const showOrder = () => {
    goToOrdersPage()
    isOrdersPage() && onClose()
  }

  const showMeters = () => {
    goToMetersPage()
    isMetersPage() && onClose()
  }

  const handleLogOut = async () => {
    dispatch(logOut({ history, location }))
  }

  return (
    <>
      <GlobalCss />
      <Drawer anchor={anchor} onClose={onClose} open={open} style={{zIndex: 99999}}>
        <DrawerContainer bgColor={selectedStyles?.palette.primary}>
          <Cart
            footerContent={
              <MuiBox
                alignItems="center"
                display="flex"
                onClick={handleLogOut}
                py={2}
              >
                <LeaveIcon color="#222222" size={'small'} />
                <span style={{ paddingLeft: '14px', fontWeight: 600 }}>
                {t('Logout')}
              </span>
              </MuiBox>
            }
            onClose={onClose}
            title={
              <MuiBox mt={3} style={{ width: '100%' }}>
                <MuiBox>
                  {currProfile?.avatar ? (
                    <>
                      <Avatar
                        backgroundColor="#FFFFFF"
                        style={{ width: '80px', height: '80px' }}
                      >
                        <img
                          alt=""
                          src={currProfile.avatar}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </Avatar>
                    </>
                  ) : (
                    <Avatar
                      backgroundColor="#FFFFFF"
                      style={{ width: '80px', height: '80px' }}
                    >
                      <Avatar backgroundColor={selectedStyles?.palette.primary} className={classes.size} />
                    </Avatar>
                  )}
                </MuiBox>
                <MuiBox onClick={showProfile}>
                  <MuiBox
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    mt={2}
                  >
                    <MuiBox textAlign="left" width="100%">
                      <H2
                        style={{
                          fontSize: '24px',
                          fontWeight: 400,
                          color: `${selectedStyles?.typography.primaryColor}`
                        }}
                      >
                        {' '}
                        {currProfile && currProfile.firstName}{' '}
                        {currProfile && currProfile.lastName}{' '}
                      </H2>
                      <H2
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          paddingTop: '8px',
                          color: `${selectedStyles?.typography.primaryColor}`
                        }}
                      >
                        {currProfile && currProfile.email}
                      </H2>
                    </MuiBox>
                    <MuiBox
                      alignItems="center"
                      className={classes.rightIcon}
                      display="flex"
                      textAlign="right"
                    >
                      <SmallRightIcon color="#FFFFFFF" />
                    </MuiBox>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            }
          >
            <MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                onClick={() => showDashboard()}
                p={2}
              >
                <DashboardIcon
                  color={isDashboardPage() ? '#039BE5' : '#222222'}
                  size={'small'}
                />
                <span
                  style={{
                    paddingLeft: '14px',
                    fontWeight: 600,
                    color: isDashboardPage() ? '#039BE5' : '#222222'
                  }}
                >
                {t('Dashboard')}
              </span>
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                onClick={() => showOrder()}
                p={2}
              >
                <BulletTwoIcon
                  color={isOrdersPage() ? '#039BE5' : '#222222'}
                  size={'small'}
                />
                <span
                  style={{
                    paddingLeft: '14px',
                    fontWeight: 600,
                    color: isOrdersPage() ? '#039BE5' : '#222222'
                  }}
                >
                {t('Orders')}
              </span>
              </MuiBox>
              <MuiBox
                alignItems="center"
                display="flex"
                onClick={() => showMeters()}
                p={2}
              >
                <MeterIcon
                  color={isMetersPage() ? '#039BE5' : '#222222'}
                  size={'small'}
                />
                <span
                  style={{
                    paddingLeft: '14px',
                    fontWeight: 600,
                    color: isMetersPage() ? '#039BE5' : '#222222'
                  }}
                >
                {t('Meters')}
                </span>
              </MuiBox>
            </MuiBox>
          </Cart>
        </DrawerContainer>
      </Drawer>
    </>
  )
}

export default SidebarMobile
