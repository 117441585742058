import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const TodoIcon = createSvgIcon(
  <>
    <title>todo</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-color="color-2"
        fill="none"
        points=" 12,19 15,22 21,16 "
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M19,4c0-1.657-1.343-3-3-3 s-3,1.343-3,3h-3v4h12V4H19z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="25,5 28,5 28,31 4,31 4,5 7,5 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
