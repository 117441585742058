import { Box as MuiBox, Card, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { LoadingComponent, Success } from '../components'
import MainLayout from '../layouts/main.layout'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateLanguage, updateProfileState } from '../store/profile/actions'
import { Profile } from '../store/profile/types'
import { BodyText, Select } from '../tmp-ui-element'

type LanguageProps = {
  currEmail: string
  currProfile: Profile
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    main: {
      padding: '10%',
      paddingTop: '32px',
      paddingBottom: '10px',
      height: 'auto' /* eslint-disable */,
      ['@media (max-width:780px)']: {
        padding: '16px',
        paddingTop: '24px',
        paddingBottom: '10px',
        height: 'auto'
      }
    },
    title: {
      flexGrow: 1
    },
    line: {
      height: '1px',
      background: '#D2DEE4'
    },
    txtOverFlow: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    size: {
      width: '80px',
      height: '80px'
    }
  })
)

const LanguagePage = (props: LanguageProps): React.ReactElement => {
  const { currProfile,selectedStyles } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const [languageValue, setLanguageValue] = useState<string>(
    currProfile.language
  )
  const {
    profile: { customerId },
    isShowChangeLanguageSuccessMessage,
    loadingUpdateLanguage
  } = useSelector((state: ApplicationState) => state.profile)

  useEffect(() => {
    setLanguageValue(currProfile.language)
  }, [currProfile.language])

  const onChange = (evt: any) => {
    setLanguageValue(evt.target.value)
    dispatch(updateLanguage(customerId as number, evt.target.value))
  }

  return (
    <>
      <GlobalCss />
      <MainLayout isShowSidebarMobile={false}>
        {loadingUpdateLanguage ? (
          <MuiBox pt={10}>
            <LoadingComponent color={selectedStyles?.palette?.primary} />
          </MuiBox>
        ) : (
          <>
            <Success
              isOpen={isShowChangeLanguageSuccessMessage}
              onClose={() =>
                dispatch(
                  updateProfileState({
                    isShowChangeLanguageSuccessMessage: false
                  })
                )
              }
            >
              <BodyText style={{ color: '#FFFFFF' }}>
                {t('Language has been changed successfully')}
              </BodyText>
            </Success>
            <MuiBox className={classes.main}>
              <MuiBox
                style={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: 400
                }}
              >
                {t('Language')}
              </MuiBox>

              {/*CONTENT*/}
              <MuiBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                py={2}
                width="100%"
              >
                <Card className={classes.root} variant="outlined">
                  <MuiBox p={2} width="100%">
                    <MuiBox>
                      <BodyText>{t('Choose your language')}</BodyText>
                    </MuiBox>
                    <MuiBox pt={2}>
                      <Select
                        onChange={(evt) => onChange(evt)}
                        style={{
                          width: '200px',
                          backgroundColor: 'white',
                          zIndex: 1
                        }}
                        value={languageValue}
                      >
                        <MenuItem value={'en'}>{t('English')}</MenuItem>
                        <MenuItem value={'de'}>{t('German')}</MenuItem>
                      </Select>
                    </MuiBox>
                  </MuiBox>
                </Card>
              </MuiBox>
            </MuiBox>
          </>
        )}
      </MainLayout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state,
    currProfile: state.profile.profile,
    selectedStyles: state.app.selectedStyles,
    currEmail: state.app.currEmail}
}

export default connect(mapStateToProps)(LanguagePage)
