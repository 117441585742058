import openSansWoff from '../../assets/fonts/open-sans-latin-400.woff'
import openSansWoff2 from '../../assets/fonts/open-sans-latin-400.woff2'
import openSansWoffItalic from '../../assets/fonts/open-sans-latin-400italic.woff'
import openSansWoff2Italic from '../../assets/fonts/open-sans-latin-400italic.woff2'
import openSansWoffBold from '../../assets/fonts/open-sans-latin-700.woff'
import openSansWoff2Bold from '../../assets/fonts/open-sans-latin-700.woff2'
import openSansWoffBoldItalic from '../../assets/fonts/open-sans-latin-700italic.woff'
import openSansWoff2BoldItalic from '../../assets/fonts/open-sans-latin-700italic.woff2'

export const openSans = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('Open Sans Regular '),
      local('Open Sans-Regular'),
      url('${openSansWoff2}') format('woff2'),
      url('${openSansWoff}') format('woff')`
}

export const openSansItalic = {
  fontFamily: 'Open Sans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('Open Sans Regular italic'),
    local('Open Sans-Regularitalic'),
      url('${openSansWoff2Italic}') format('woff2'),
      url('${openSansWoffItalic}') format('woff')`
}

export const openSansBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `local('Open Sans SemiBold '),
    local('Open Sans-SemiBold'),
      url('${openSansWoff2Bold}') format('woff2'),
      url('${openSansWoffBold}') format('woff')`
}

export const openSansBoldItalic = {
  fontFamily: 'Open Sans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `local('Open Sans SemiBold italic'),
    local('Open Sans-SemiBolditalic'),
      url('${openSansWoff2BoldItalic}') format('woff2'),
      url('${openSansWoffBoldItalic}') format('woff')`
}
