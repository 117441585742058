import { Box as MuiBox } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { LoadingIcon } from '../tmp-ui-element'

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999
    }
  })
)

type LoadingPropTypes = {
  color?: string
}

const Loading = ({ color }: LoadingPropTypes): React.ReactElement => {
  const classes = useStyles()

  return (
    <>
      <MuiBox className={classes.loading}>
        <LoadingIcon color={color} height="32px" width="32px" />
      </MuiBox>
    </>
  )
}

export default Loading
