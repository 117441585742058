import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const StopwatchIcon = createSvgIcon(
  <>
    <title>stopwatch</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-color="color-2"
        fill="none"
        points="16 13 16 19 22 19"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="19"
        fill="none"
        r="12"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="12"
        x2="20"
        y1="1"
        y2="1"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="1"
        y2="4"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="26"
        x2="28"
        y1="7"
        y2="9"
      />
    </g>
  </>
)
