import { Box as MuiBox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'

import { PersonalInfo } from '../components'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { ThemeProps, ThemeProvider } from '../tmp-ui-element'

type ProfileDetailProps = {
  theme?: ThemeProps | undefined
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#F9FAFB'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid #D2DEE4 !important',
      backgroundColor: '#FFFFFF !important'
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#F9FAFB'
      }
    }
  }
})(() => null)

const ProfileDetailPage = ({
  theme
}: ProfileDetailProps): React.ReactElement => {
  return (
    <>
      <GlobalCss />
      <div className="app" data-component="app-epilot">
        <ThemeProvider theme={theme}>
          <MuiBox>
            <PersonalInfo />
          </MuiBox>
        </ThemeProvider>
      </div>
    </>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state
  }
}

export default connect(mapStateToProps)(ProfileDetailPage)
