/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import _ from 'lodash'
import React, { memo, useState, useCallback, CSSProperties } from 'react'

import SingleInput from './CodeInput'

export interface OTPInputProps {
  length: number
  onChangeOTP: (otp: string) => void

  autoFocus?: boolean
  isNumberInput?: boolean
  disabled?: boolean
  hasErrored?: boolean

  style?: CSSProperties
  className?: string
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined

  inputStyle?: CSSProperties
  inputClassName?: string
}

export function OTPInputComponent(props: OTPInputProps) {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    inputMode,
    hasErrored,
    ...rest
  } = props

  const [activeInput, setActiveInput] = useState(0)
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''))
  // const [isSetNewArr, setIsSetNewArr] = useState(false)

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('')

      onChangeOTP(otpValue)
    },
    [onChangeOTP]
  )

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str

      if (!isNumberInput) {
        return changedValue
      }

      return !changedValue || /\d/.test(changedValue) ? changedValue : ''
    },
    [isNumberInput]
  )

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      let isSetNewArr = false

      const updatedOTPValues = [...otpValues]

      updatedOTPValues[activeInput] = str[0] || ''
      for (let i = 0; i < activeInput; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (_.isEmpty(updatedOTPValues[Object.keys(updatedOTPValues)[i]])) {
          isSetNewArr = true

          break
        }
      }

      for (let i = 0; i < Array(length).length; i++) {
        if (i < activeInput && isSetNewArr) {
          updatedOTPValues[i] = '0'
        } else if (i > activeInput) {
          updatedOTPValues[i] = ''
        }
      }
      setOTPValues(updatedOTPValues)
      handleOtpChange(updatedOTPValues)
    },
    [activeInput, handleOtpChange, length, otpValues]
  )

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)

      setActiveInput(selectedIndex)
    },
    [length]
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      // console.log('pad: ', pad(10, 3, 0))
      focusInput(index)
    },
    [focusInput]
  )

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value)

      if (!val) {
        e.preventDefault()

        return
      }
      changeCodeAtFocus(val)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  )

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1)
  }, [])

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault()
          if (otpValues[activeInput]) {
            changeCodeAtFocus('')
          } else {
            focusPrevInput()
          }
          break
        }
        case 'ArrowLeft': {
          e.preventDefault()
          focusPrevInput()
          break
        }
        case 'ArrowRight': {
          e.preventDefault()
          focusNextInput()
          break
        }
        case ' ': {
          e.preventDefault()
          break
        }
        default:
          break
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  )

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('')

      if (pastedData) {
        let nextFocusIndex = 0
        const updatedOTPValues = [...otpValues]

        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val)

            if (changedValue) {
              updatedOTPValues[index] = changedValue
              nextFocusIndex = index
            }
          }
        })
        setOTPValues(updatedOTPValues)
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1))
      }
    },
    [activeInput, getRightValue, length, otpValues]
  )

  // const pad = (n: any, width: any, z: any) => {
  //   z = z || '0'
  //   n = n + ''
  //
  //   return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
  // }

  return (
    <div {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
            /* eslint-disable */
            autoFocus={autoFocus}
            hasErrored={hasErrored}
            className={`${inputClassName} ${
              hasErrored ? 'errInput' : 'activeInput'
            }`}
            disabled={disabled}
            focus={activeInput === index}
            key={`SingleInput-${index}`}
            name={`${index}`}
            onBlur={onBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus(index)}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            style={inputStyle}
            value={otpValues && otpValues[index]}
            inputMode={inputMode}
          />
        ))}
    </div>
  )
}

const OTPInput = memo(OTPInputComponent)

export default OTPInput
