import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const WebLinkIcon = createSvgIcon(
  <>
    <title>web link</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M3.998,19.583 C2.233,18.811,1,17.049,1,15V9c0-2.761,2.239-5,5-5h13c2.761,0,5,2.239,5,5v6c0,2.761-2.239,5-5,5h-7"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d=" M28.002,12.417C29.767,13.189,31,14.951,31,17v6c0,2.761-2.239,5-5,5H13c-2.761,0-5-2.239-5-5v-6c0-2.761,2.239-5,5-5h7"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
