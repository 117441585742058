import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MicrosoftIcon = createSvgIcon(
  <>
    <title>microsoft</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="12"
        stroke="#222222"
        strokeMiterlimit="10"
        width="12"
        x="2"
        y="2"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="12"
        strokeMiterlimit="10"
        width="12"
        x="18"
        y="2"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="12"
        strokeMiterlimit="10"
        width="12"
        x="2"
        y="18"
      />{' '}
      <rect
        fill="none"
        height="12"
        stroke="#222222"
        strokeMiterlimit="10"
        width="12"
        x="18"
        y="18"
      />
    </g>
  </>
)
