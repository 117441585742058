import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const LightningIcon = createSvgIcon(
  <>
    <title>lightning</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="19 3 4 19 15 19 13 29 28 13 17 13 19 3"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
