import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const JsonIcon = createSvgIcon(
  <>
    <title>json</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="20 1 20 9 28 9"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="28 13 28 9 20 1 4 1 4 13"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="4 27 4 31 28 31 28 27"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <rect
        fill="none"
        height="14"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="13"
      />{' '}
      <path
        d="M6.244,24.793a3.763,3.763,0,0,1-.8-.082v-1.2c.084.015.172.033.266.052a1.461,1.461,0,0,0,.305.03.625.625,0,0,0,.547-.231,1.415,1.415,0,0,0,.164-.789V17.289h1.55v5.152A2.645,2.645,0,0,1,7.768,24.2,1.894,1.894,0,0,1,6.244,24.793Z"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="butt"
      />{' '}
      <path
        d="M13.244,21.266a1.69,1.69,0,0,1-.269.943,1.769,1.769,0,0,1-.778.641,2.886,2.886,0,0,1-1.191.228,4.845,4.845,0,0,1-.957-.08,3.528,3.528,0,0,1-.8-.279V21.344a4.568,4.568,0,0,0,.918.353,3.4,3.4,0,0,0,.875.127.847.847,0,0,0,.5-.119.367.367,0,0,0,.16-.307.338.338,0,0,0-.064-.2.763.763,0,0,0-.207-.177c-.1-.06-.348-.183-.76-.368a3.614,3.614,0,0,1-.838-.492,1.506,1.506,0,0,1-.414-.547,1.809,1.809,0,0,1-.135-.73,1.468,1.468,0,0,1,.574-1.231,2.534,2.534,0,0,1,1.579-.441,4.435,4.435,0,0,1,1.808.41l-.473,1.192a3.418,3.418,0,0,0-1.382-.368.723.723,0,0,0-.438.106.32.32,0,0,0-.137.262.38.38,0,0,0,.174.3,6.591,6.591,0,0,0,.944.485,2.6,2.6,0,0,1,1.025.713A1.55,1.55,0,0,1,13.244,21.266Z"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="butt"
      />{' '}
      <path
        d="M19.471,20.137a3.081,3.081,0,0,1-.707,2.191,2.711,2.711,0,0,1-2.071.75,2.706,2.706,0,0,1-2.06-.754,3.065,3.065,0,0,1-.717-2.2,3.05,3.05,0,0,1,.713-2.178A2.721,2.721,0,0,1,16.7,17.2a2.708,2.708,0,0,1,2.067.746A3.088,3.088,0,0,1,19.471,20.137Zm-3.93,0q0,1.656,1.152,1.656a.989.989,0,0,0,.869-.4,2.2,2.2,0,0,0,.284-1.254,2.221,2.221,0,0,0-.287-1.264.982.982,0,0,0-.858-.408Q15.542,18.465,15.541,20.137Z"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="butt"
      />{' '}
      <path
        d="M25.939,23H23.916L21.83,18.977H21.8q.075.948.074,1.449V23H20.5V17.289h2.016L24.6,21.258h.023q-.054-.864-.055-1.387V17.289h1.375Z"
        data-cap="butt"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="butt"
      />
    </g>
  </>
)
