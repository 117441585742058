import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductSmarthomeIcon = createSvgIcon(
  <>
    <title>product smarthome</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="smart-home"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g id="Group" stroke="#222222" transform="translate(2 2)">
          <rect height="27" id="Rectangle" rx="2" width="27" x=".5" y=".5" />
          <path d="M13.067 25.667h1.866" id="Line-46" strokeLinecap="round" />
          <rect
            height="20.467"
            id="Rectangle"
            rx="1"
            width="21.4"
            x="3.3"
            y="3.3"
          />
          <path
            d="M13.219 8.057l-5.77 5.13a.466.466 0 0 0 .31.813h1.024c.11 0 .2.09.2.2v5.333a1 1 0 0 0 1 1h7.8a1 1 0 0 0 1-1V14.2c0-.11.09-.2.2-.2h1.025a.466.466 0 0 0 .31-.814l-5.77-5.129a1 1 0 0 0-1.329 0z"
            id="Shape"
            strokeLinecap="square"
          />
        </g>
      </g>
    </g>
  </>
)
