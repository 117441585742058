import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductDistrictheatIcon = createSvgIcon(
  <>
    <title>product districtheat</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="district-heat"
        stroke="none"
        strokeWidth="1"
      >
        <path d="M0 0h32v32H0z" id="Rectangle" />
        <g
          id="Group"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(1.5 2.5)"
        >
          <path
            d="M4.667 28H2a2 2 0 0 1-2-2v-8.552a2 2 0 0 1 2-2h2.667"
            id="Path"
          />
          <path d="M9.333 19.31h2.8" id="Path" />
          <path d="M9.333 23.172h2.8" id="Path" />
          <path d="M9.333 15.448h2.8" id="Path" />
          <path
            d="M14.933 10.62V2.872A2 2 0 0 1 17.626.995l9.067 3.35A2 2 0 0 1 28 6.22V26a2 2 0 0 1-2 2H4.667V12.62a2 2 0 0 1 2-2H14.8a2 2 0 0 1 2 2v15.058"
            id="Path"
          />
          <path d="M20.533 23.172V6.76" id="Path" />
          <path d="M24.267 23.172V8.69" id="Path" />
          <path
            d="M9.796.5c.802.729 1.202 1.447 1.202 2.156l.002.123c0 1.163-1.5 1.537-1.5 2.784 0 .975.37 1.621 1.111 1.937"
            id="Line-9-Copy-2"
          />
          <path
            d="M6.296.5c.802.729 1.202 1.447 1.202 2.156l.002.123C7.5 3.942 6 4.316 6 5.563c0 .975.37 1.621 1.111 1.937"
            id="Line-9-Copy-3"
          />
          <path
            d="M2.796.5c.802.729 1.202 1.447 1.202 2.156L4 2.779c0 1.163-1.5 1.537-1.5 2.784 0 .975.37 1.621 1.111 1.937"
            id="Line-9-Copy-4"
          />
        </g>
      </g>
    </g>
  </>
)
