import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const MailIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="2 5 16 16 30 5"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <rect
        fill="none"
        height="24"
        rx="3"
        ry="3"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="4"
      />
    </g>
  </>
)
