import { withStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lock from '../assets/images/lock.svg'
import sendEmail from '../assets/images/sent-email.svg'
import { Login } from '../authentication'
import { IVY_SERVER_API_URL } from '../common/constants'
import useNavigation from '../common/hooks/navigation.hook'
import { LoadingComponent } from '../components'
import { ApplicationState } from '../store'
import { SelectedStyles } from '../store/app/types'
import { updateProfile } from '../store/profile/actions'
import { BodyText, SmallText, ThemeProvider } from '../tmp-ui-element'

type ForgotPasswordProps = {
  selectedStyles?: SelectedStyles
}

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#FFFFFF'
    },
    '.MuiTypography-h2': {
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '24px !important',
      lineHeight: '32px !important',
      color: '#222222'
    },
    '.MuiTypography-h3': {
      textTransform: 'inherit',
      fontStyle: 400,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222222',
      padding: '16px 0'
    },
    '.MuiInputBase-input': {
      fontWeight: 400,
      fontStyle: 400
    } /* eslint-disable */,
    '@media only screen and (max-width: 780px)': {
      body: {
        backgroundColor: '#FFFFFF'
      },
      '.MuiBox-root': {
        top: 'auto !important',
        left: 'auto !important',
        position: 'initial !important',
        transform: 'none !important',
        paddingTop: '0px !important',
        width: '100%'
      },
      '.MuiBox-root div:nth-child(2)': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
      },
      '.MuiFilledInput-root:hover': {
        backgroundColor: '#FFFFFF !important'
      },
      '.makeStyles-logoContainer-29': {
        padding: '0px !important',
        flexBasis: '384px',
        backgroundColor: '#039BE5'
      },
      '.makeStyles-container-33': {
        display: 'block',
        maxHeight: 'inherit !important',
        padding: '0px !important'
      }
    }
  }
})(() => null)

const ForgotPasswordPage = (): React.ReactElement => {
  const { t } = useTranslation()

  const [isCheckInboxScreen, setIsCheckInboxScreen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    profile: {
      profile: { language }
    },
    app: { currEmail, selectedStyles }
  } = useSelector((state: ApplicationState) => state)
  const dispatch = useDispatch()
  const { goToLandingPage } = useNavigation()

  useEffect(() => {
    if (!currEmail) {
      goToLandingPage()
    }
  }, [currEmail])

  const onLanguageChange = (evt: any) => {
    dispatch(updateProfile({ language: evt.target.value }))
  }

  const handleSendRecoveryEmail = async () => {
    try {
      setIsLoading(true)
      await Auth.forgotPassword(currEmail)
      setIsLoading(false)
      setIsCheckInboxScreen(true)
    } catch (e) {
      setIsLoading(false)
    }
  }

  return (
    <>
      <GlobalCss />
      <div className="app app-epilot-widget" data-component="app-epilot">
        <ThemeProvider>
          <Login
            maxHeightLoginFormOnMobile="calc(var(--vh, 1vh) * 100)"
            heightLoginFormOnMobile="93%"
            logoName={selectedStyles?.logo?.name}
            logoUrl={`${IVY_SERVER_API_URL}${selectedStyles?.logo?.url}`}
            backgroundColor={selectedStyles?.palette?.primary}
            textColor={selectedStyles?.typography?.primaryColor}
            authForgotPasswordLink="Forgot password?"
            authSigninButtonLabel={t('Send recovery email')}
            headerText={
              isCheckInboxScreen
                ? t('Check your inbox')
                : t('Forgot your password?')
            }
            isShowButtonSubmitForm={!isCheckInboxScreen}
            isShowEmailField={false}
            isShowPasswordField={false}
            isShowRememberMe={false}
            isShowSymbolField={false}
            isValidForm={true}
            isSubmitting={isLoading}
            languages={[
              { value: 'en', label: 'English' },
              {
                value: 'de',
                label: 'Deutsch'
              }
            ]}
            languageValue={language}
            onLanguageChange={(event) => onLanguageChange(event)}
            loadingIndicatorColor={selectedStyles?.palette?.primary}
            onSubmitForm={handleSendRecoveryEmail}
            subHeaderText={
              <>
                {isCheckInboxScreen ? (
                  <BodyText>
                    {t('We have sent an email containing')}
                    <strong> {currEmail}</strong>
                    {t('Please click the link to set your new password')}
                  </BodyText>
                ) : (
                  <BodyText>
                    {t('No problem')} <strong>{currEmail}</strong>
                    {'.'}
                  </BodyText>
                )}
              </>
            }
            termsTextInForm={
              <div style={{ paddingTop: '16px' }}>
                {isCheckInboxScreen && (
                  <>
                    <div>
                      <SmallText style={{ color: '#849096' }}>
                        {t("Didn't receive an email?")}
                      </SmallText>
                    </div>
                    <div style={{ paddingTop: '16px' }}>
                      {isLoading ? (
                        <LoadingComponent
                          color={selectedStyles?.palette?.primary}
                        />
                      ) : (
                        <a
                          style={{
                            textDecoration: 'none',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#039BE5',
                            cursor: 'pointer'
                          }}
                          onClick={handleSendRecoveryEmail}
                        >
                          {t('Resend link')}
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            }
            titleIcon={
              <>
                {isCheckInboxScreen ? (
                  <img
                    alt="No results found"
                    height="96"
                    src={sendEmail}
                    width="96"
                  />
                ) : (
                  <img
                    alt="No results found"
                    height="96"
                    src={lock}
                    width="96"
                  />
                )}
              </>
            }
          />
        </ThemeProvider>
      </div>
    </>
  )
}

export default ForgotPasswordPage
