import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ProductBroadbandIcon = createSvgIcon(
  <>
    <title>product broadband</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="24"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M23.8,16.2 c-2-2-4.7-3.2-7.8-3.2c-3,0-5.8,1.2-7.8,3.2"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M29.6,10.6 C26.1,7.2,21.3,5,16,5C10.7,5,5.9,7.2,2.4,10.6"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
